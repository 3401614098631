import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon, PencilIcon } from "@heroicons/react/24/outline";
import { storyBoardsApiClient } from "../../../core/config/api";
import { toast } from "react-toastify";
import { TrashIcon } from "@heroicons/react/20/solid";
import { useTour } from "@reactour/tour";
import { useParams } from "react-router";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import store from "../../../core/redux/store";
import { setModal } from "../../../core/redux/actions/modalManagerActions";
import { ENUM_MODAL_TYPE } from "../../../core/types/modal.type";
import { CustomLoadingSpinner } from "../loading/CustomLoadingSpinner";
import {useBaseModalContext} from "../modals/base-modal/BaseModalContext";
import { useSelector } from "react-redux";
import UniversalTippy from "../universal-tippy/UniversalTippy";
import { useAppContext } from "../../../core/utils/AppContext";


type AccordionProps = {
  title: string;
  children?: React.ReactNode;
  onUpdate: (updatedSceneData: any) => void; // Include onUpdate
  content: string;
  description: string;
  scene: any;
  scenes: any;
  sceneIndex: number;
  setScenes: Function,
  isOpen: boolean;
  onToggle: () => void;
  dragHandleProps: any;
  setOpenAccordionId: Function;
  setSelectedShot: Function;
};

const Accordion = ({
  title,
  children,
  dragHandleProps,
  onUpdate,
  content,
  description,
  scene,
  scenes,
  setScenes,
  sceneIndex,
  isOpen,
  onToggle,
  setOpenAccordionId,
  setSelectedShot
}: AccordionProps) => {
  const { userId, availableCredits } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits
  }));
  const { setSteps, setIsOpen, currentStep, setCurrentStep } = useTour();

  const { storyId } = useParams();


  const deleteSceneRequest = async () => {

    if (scenes?.length > 1) {
      try {
        const result = await storyBoardsApiClient.scene.remove({
          id: scene?.id,
          storyId: storyId ?? ""
        });
        if (result.status === 200) {
          const _scenes = [...scenes];

          const index = _scenes?.findIndex((element) => element?.id === scene?.id);

          _scenes.splice(index, 1);

          setScenes(_scenes);

          sendEventToGoogleTagManager("delete_scene_success", {
            story_management: "Story Management",
            scene_action: "delete",
            story_id: storyId,
            outcome: "success"
          }, userId, availableCredits);
        } else {
          toast.error("Scene update failed");
          sendEventToGoogleTagManager("delete_scene_fail", {
            story_management: "Story Management",
            scene_action: "delete",
            story_id: storyId,
            outcome: "fail"
          }, userId, availableCredits);
        }
      } catch (error) {
        console.log({ error });
        toast.error("Something went wrong. ID: " + scene?.id + ", Error: " + (error as Error).message ?? "");
        sendEventToGoogleTagManager("delete_scene_fail", {
          story_management: "Story Management",
          scene_action: "delete",
          story_id: storyId,
          outcome: "error",
          error_message: (error as Error).message ?? "Unknown error"
        }, userId, availableCredits);
      }
    } else {
      toast.warning("You can't delete this scene. There must be at least one scene in a story.");
    }
  };

  const createSceneRequest = async ({ index, content, description }: {
    index: number,
    content: string,
    description: string
  }) => {
    try {

      const result = await storyBoardsApiClient.scene.create({
        storyId: storyId ?? "",
        index,
        content,
        description
      });
      if (result.status === 201) {
        const _scenes = [...scenes];
        _scenes.splice(result.data?.index, 0, result.data);
        setScenes([..._scenes]);
        sendEventToGoogleTagManager("create_scene_success", {
          story_management: "Story Management",
          scene_action: "create",
          story_id: storyId,
          outcome: "success"
        }, userId, availableCredits);
        } else {
          toast.error("Something went wrong");
          sendEventToGoogleTagManager("create_scene_fail", {
            story_management: "Story Management",
            scene_action: "create",
            story_id: storyId,
            outcome: "fail"
          }, userId, availableCredits);
        }
        } catch (error) {
          console.log({ error });
          toast.error("Something went wrong");
          sendEventToGoogleTagManager("create_scene_fail", {
            story_management: "Story Management",
            scene_action: "create",
            story_id: storyId,
            outcome: "error",
            error_message: error
          }, userId, availableCredits);
        }

  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  useEffect(() => {
    if (isPopoverOpen) {
      /* setSteps?.(sceneTitleSteps);
       setIsOpen(true);*/
    }
  }, [isPopoverOpen, setSteps, setIsOpen]);

  const {openModal} = useBaseModalContext();

  const appContext = useAppContext();

 return (
  <div className={`border:none mb-2 transition-all duration-500 ease-in-out max-h-24 overflow-hidden`}>
    <div
      className="group w-full flex relative justify-between items-center p-2 text-left text-neutral-300 font-semibold rounded-md bg-neutral-700 text-xs sm:text-sm"
      {...dragHandleProps}
    >
      <div className="drag-handle flex-grow">
        <span>{title}</span>
      </div>
      <div className={`flex items-center sm:opacity-0 sm:group-hover:opacity-100`}>
        {/* Edit Button */}
        <button
          className="w-[24px] h-[24px] mr-2 flex items-center justify-center rounded bg-neutral-700 hover:bg-neutral-800"
          onClick={() => {
            appContext.setBoardScreenViewMode("EDIT_DESCRIPTION");
            if (!isOpen) {
              setOpenAccordionId(scene.id);
              if (scene?.shots.length > 0) {
                setSelectedShot(scene.shots[0]);
              };
            }
          }}
        >
          <PencilIcon className="w-[16px] h-[16px] text-xs sm:text-sm" />
        </button>
        {/* Delete Button, visible next to the edit button */}
        <button
          className="w-[24px] h-[24px] flex items-center justify-center rounded bg-neutral-700 hover:bg-neutral-800"
          onClick={(e) => {
            e.stopPropagation();
            openModal({mode: "CONFIRM", data: {title: "Delete Scene",
              description: "Are you sure you want to delete this scene? All images within will be irrecoverably lost.",
              function: () => deleteSceneRequest()}})
              // console.log("Delete button clicked");
            }}
            >
          <TrashIcon className="w-[16px] h-[16px]" />
        </button>
        {/* Toggle Button, always visible */}
        
      </div>
      <button onClick={onToggle} className="ml-2 lg:flex">
          {isOpen ? <ChevronUpIcon className="w-5 h-5 text-neutral-300" /> : <ChevronDownIcon className="w-5 h-5 text-neutral-300" />}
        </button>
    </div>
  </div>
);

};


export default Accordion;

