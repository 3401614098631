import axios from "axios";
import { useEffect, useState } from "react";
import { CustomLoadingSpinner } from "../../components/loading/CustomLoadingSpinner";
import { useNavigate } from "react-router";

export default function ResetPasswordScreen() {

  const params = new URLSearchParams(document.location.search);
  const email = params.get("email");
  const token = params.get("token");

  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [tokenIsValid, setTokenIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    validateToken();
  }, []);

  const validateToken = () => {
    setLoading(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/core/auth/validate-reset-password-token`,
      data: {
        email: email,
        token: token
      }
    })
      .then((response) => {
        if (response.data?.valid === false) {
          setTokenIsValid(false);
        }
      })
      .catch(error => {
        console.log(error);
        setTokenIsValid(false);
      })

    setLoading(false);
  }; 

  const navigate = useNavigate();

  const handleSaveNewPassword = () => {
    if (newPassword === newPasswordConfirm) {
      if (newPassword.length > 5) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/core/auth/set-new-password`,
          data: {
            email: email,
            token: token,
            password: newPassword
          }
        })
          .then((response) => {
            if (response.status === 201) {
              navigate("/sign-in");
            }
            console.log(response);
          })
          .catch(error => {
            console.log(error);
            setErrorMessage('Something went wrong!');
          })
      } else {
        setErrorMessage("Minimum 6 characters are required for the password");
      }
    } else {
      setErrorMessage("Passwords do not match!");
    }
  }

  return (
    <div className="flex justify-center items-center min-h-screen">
      { loading ? (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-50">
          <CustomLoadingSpinner size={60} />
          <p className="mt-4 text-white text-lg">We are setting a few things up for you.<br/>Hang on tight.</p>
        </div>
      ) : (
        <div className="flex flex-col gap-y-4 items-center max-w-[300px]">
          <h2
            className="mb-6 text-center text-xl sm:text-2xl font-bold leading-9 tracking-tight text-neutral-100">
            Reset your password
          </h2>
          { tokenIsValid ? (
          <div className="flex flex-col gap-y-2">
            <div>
              <label
                htmlFor="new-password"
                className="text-sm font-medium text-neutral-100">
                  New password:
              </label>
              <input
                id="new-password"
                type="password"
                className="bg-white bg-opacity-20 w-full rounded border border-neutral-300 text-neutral-100 placeholder:text-gray-400"
                onChange={(event) => setNewPassword(event.target.value)}
                value={newPassword}
              />
            </div>
            <div>
              <label
                htmlFor="confirm-new-password"
                className="text-sm font-medium text-neutral-100">
                  Confirm new password:
              </label>
              <input
                id="confirm-new-password"
                type="password"
                className="bg-white bg-opacity-20 w-full rounded border border-neutral-300 text-neutral-100 placeholder:text-gray-400"
                onChange={(event) => setNewPasswordConfirm(event.target.value)}
                value={newPasswordConfirm}
              />
            </div>
            {errorMessage && (
              <div className="text-center">
                <p className="text-red-500">{errorMessage}</p>
              </div>
            )}
            <button
              className="mt-6 flex mb-2 w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-100 shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handleSaveNewPassword()}>
              Submit
            </button>
          </div> ) : ( 
            <div className="text-white font-semibold text-base">This link is invalid!</div>
          )}
        </div>
      )}
    </div>
  );
}