import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import logo from "../../../assets/logos/logo-white.svg";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import { CustomLoadingSpinner } from "../../components/loading/CustomLoadingSpinner";




export default function SignInScreen() {
  const { userId, availableCredits } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits
  }));

  const [activeTab, setActiveTab] = useState<"sign-in" | "sign-up" | "reset-password">("sign-in");
  const [signInForm, setSignInForm] = useState<{
    email: string,
  }>({ email: "" });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  // const [isSigningUp, setIsSigningUp] = useState(false);

  // const [showSignInPassword, setShowSignInPassword] = useState(false);
  // const [showSignUpPassword, setShowSignUpPassword] = useState(false);

  // const [consentState, setConsentState] = useState<boolean>(false);

  // const toggleSignInPasswordVisibility = () => {
  //   setShowSignInPassword(!showSignInPassword);
  // };

  // const toggleSignUpPasswordVisibility = () => {
  //   setShowSignUpPassword(!showSignUpPassword);
  // };

  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>("");

  // const [resetPasswordSuccessState, setResetPasswordSuccessState] = useState<string>("");
  // const [resetPasswordErrorState, setResetPasswordErrorState] = useState<string>("");

  const maintenanceMode = false;

  const signInWithGoogle = () => {
    sendEventToGoogleTagManager("signup_google_success", {
          event_category: "Account",
          event_action: "Signup",
          event_label: "success",
          value: false
        }, userId, availableCredits);
    window.open(`${process.env.REACT_APP_API}/core/auth/google`, "_self");
  };

  // const signInWithMicrosoft = () => {
  //   sendEventToGoogleTagManager("signup_microsoft_success", {
  //     event_category: "Account",
  //     event_action: "Signup",
  //     event_label: "success",
  //     value: false
  //   });
  //   window.open(`${process.env.REACT_APP_API}/core/auth/microsoft`, "_self");
  // };

  const handleSignInInputChange = (event: any) => {
    const { name, value } = event.target;
    setSignInForm((prevForm): any => ({
      ...prevForm,
      [name]: value
    }));
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const sendMagicLink = async () => {
    setErrorMessage("");
    
    if (!validateEmail(signInForm.email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/core/auth/magic-links/email`,
        data: signInForm,
        withCredentials: true
      });

      if (response.status === 201) {
        // Send event to Google Analytics
        sendEventToGoogleTagManager("login_email_success", {
          event_category: "Account",
          event_action: "Login",
          event_label: "success",
          value: true
        }, userId, availableCredits);

        // Redirect on successful login
        // window.open(response.data.redirectUrl, "_self");
        setSuccessMessage(`Magic link sent to ${signInForm.email}`);
      }
    } catch (error) {
      let errorMessage = "Invalid login credentials. Please try again.";
      // Use a type guard to check if the error is an instance of AxiosError
      if (axios.isAxiosError(error)) {
        // Check for a more specific error message if available
        if (error.response && error.response.data) {
          const message = error.response.data.message;
          if (message) {
            errorMessage = message; // Use server-provided message if available
          }
        }
        toast.error(errorMessage);
      } else {
        // Handle non-AxiosError errors
        toast.error("An unexpected error occurred. Please try again.");
      }

      // Send a single event to Google Analytics for a failed login attempt
      sendEventToGoogleTagManager("login_email_fail", {
        event_category: "Account",
        event_action: "Login",
        event_label: "failure",
        value: false
      }, userId, availableCredits);

      // Optionally log the error for debugging purposes
      console.warn({ error: error });
    }
  };

  const handleResetPassword = async () => {
    setIsResettingPassword(true);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/core/auth/reset-password`,
        data: { email: resetPasswordEmail }
      });

      if (response.status === 201) {
        sendEventToGoogleTagManager("password_reset_success", {
          event_category: "Account",
          event_action: "reset",
          event_label: "success",
          value: false
        }, userId, availableCredits);
        // Display success message using toast
        toast.success("Check your email for reset instructions.");
        // Redirect to the sign-in screen after a short delay for a better user experience
        setTimeout(() => setActiveTab("sign-in"), 5000); // Adjust the delay as needed
      }
    } catch (error) {
      sendEventToGoogleTagManager("password_reset_fail", {
          event_category: "Account",
          event_action: "reset",
          event_label: "fail",
          error_message: error
        }, userId, availableCredits);
      // Simplify error handling to provide a clear and concise message
      let errorMessage = "Unable to send reset email. Please try again later.";
      // Detailed error logging for debugging purposes
      console.error("Reset password error:", error);
      // Display error message using toast
      toast.error(errorMessage);
    } finally {
      setIsResettingPassword(false); 
      }
  };

  const [isResettingPassword, setIsResettingPassword] = useState(false);

  return (
    <>
    <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="bg-[#181818] min-h-screen">
      {maintenanceMode ? (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-md sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-[100px] sm:h-[10px] w-auto"
              src={logo}
              alt="logo story-boards.ai"
            />
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="px-6 shadow sm:rounded-lg sm:px-12">
              <div className="text-center text-xl font-bold leading-9 tracking-tight text-neutral-100">
                Down for Maintenance. <br />We'll be right back.
              </div>
              {/* Discord Button */}
              <div className="mt-4 flex justify-center">
                <a
                  href={"https://discord.gg/KbUPUjjXn4"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex mt-10 items-center justify-center rounded-md border border-transparent bg-[#5c6af3] px-4 py-2 text-base font-medium text-neutral-100 shadow-sm hover:bg-blue-700"
                >
                  <img
                    src={require("../../../assets/logos/discord-white-icon.webp")}
                    alt={"discord logo"}
                    className="mr-2 h-6 w-6" // Adjust size as needed
                  />
                  Join our Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (

          <div className="h-screen flex flex-1 flex-col overflow-auto py-6 px-4 sm:px-6 lg:px-8 mx-auto max-w-screen-xl">
            <div className="mx-auto w-full max-w-md sm:w-full sm:max-w-md">
              <img
                  className="mx-auto h-[120px] sm:h-[200px] w-auto"
                  src={logo}
                  alt="logo story-boards.ai"
              />
            </div>

            <div className="flex-1 mt-10 mx-auto w-full max-w-xs sm:max-w-[600px]">
              <div className="px-4 shadow sm:rounded-lg sm:px-20">
                {activeTab === "sign-in" && (
                    <div className="space-y-6">
                      <div>
                        <h2
                            className="mb-6 text-center text-2xl sm:text-5xl leading-9 tracking-tight text-neutral-100">
                          Sign in to <br/>story-boards.ai
                        </h2>
                        <p className="text-neutral-400 text-center mb-8">Login or register to start building your story
                          today.</p>
                        <label htmlFor="email" className="text-sm font-medium text-neutral-100">
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              required
                              className="bg-white bg-opacity-20 w-full rounded border border-neutral-300 text-neutral-100 placeholder:text-gray-400"
                              onChange={handleSignInInputChange}
                          />
                        </div>
                      </div>

                      {/*<div>*/}
                      {/*  <label htmlFor="password" className="text-sm font-medium text-neutral-100">*/}
                      {/*    Password*/}
                      {/*  </label>*/}
                      {/*  <div className="mt-2 relative">*/}
                      {/*    <input*/}
                      {/*      id="password"*/}
                      {/*      name="password"*/}
                      {/*      type={showSignInPassword ? "text" : "password"}*/}
                      {/*      autoComplete="current-password"*/}
                      {/*      required*/}
                      {/*      className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                      {/*      onChange={handleSignInInputChange}*/}
                      {/*    />*/}
                      {/*    <button*/}
                      {/*      type="button"*/}
                      {/*      className="absolute inset-y-0 right-0 pr-3 flex items-center"*/}
                      {/*      onClick={toggleSignInPasswordVisibility}*/}
                      {/*    >*/}
                      {/*      {showSignInPassword ? (*/}
                      {/*        <EyeSlashIcon*/}
                      {/*          className="h-6 w-6 text-gray-400"*/}
                      {/*          aria-hidden="true"*/}
                      {/*        />*/}
                      {/*      ) : (*/}
                      {/*        <EyeIcon*/}
                      {/*          className="h-6 w-6 text-gray-400"*/}
                      {/*          aria-hidden="true"*/}
                      {/*        />*/}
                      {/*      )}*/}
                      {/*    </button>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      {successMessage && (
                          <div className="text-center">
                            <p className="text-neutral-300">{successMessage}</p>
                          </div>
                      )}
                      {errorMessage && (
                          <div className="text-center">
                            <p className="text-red-500">{errorMessage}</p>
                          </div>
                      )}

                      <div>
                        <button
                            className="flex mb-2 w-full bg-gradient-to-r from-blue-600 to-purple-700 hover:to-purple-600 justify-center rounded-md px-3 py-3 leading-6 text-neutral-100 shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => sendMagicLink()}
                        >
                          Send magic link
                        </button>
                        {/*<div*/}
                        {/*  className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-100">*/}
                        {/*  Don’t have an account yet?{" "}*/}
                        {/*  <span*/}
                        {/*    onClick={() => setActiveTab("sign-up")}*/}
                        {/*    className="text-sky-500 hover:underline cursor-pointer signUpLink"*/}
                        {/*  >*/}
                        {/*    Sign Up*/}
                        {/*  </span>*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*  className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-100">*/}
                        {/*  <p*/}
                        {/*    onClick={() => setActiveTab("reset-password")}*/}
                        {/*    className="text-sky-500 hover:underline cursor-pointer signUpLink"*/}
                        {/*  >*/}
                        {/*    Forgot your password?*/}
                        {/*  </p>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                )}

                {
                    activeTab === "reset-password" && (
                        <div className="flex flex-col gap-y-4">
                          <h2
                              className="mb-6 text-center text-xl sm:text-2xl font-bold leading-9 tracking-tight text-neutral-100">
                            Reset your password
                          </h2>
                          <div>
                            <label
                                htmlFor="reset-password-email"
                                className="text-sm font-medium text-neutral-100">
                              Your email:
                            </label>
                            <input
                                id="reset-password-email"
                                type="email"
                                className="bg-white bg-opacity-20 w-full rounded border border-neutral-300 text-neutral-100 placeholder:text-gray-400"
                                onChange={(event) => setResetPasswordEmail(event.target.value)}
                                value={resetPasswordEmail}
                            />
                          </div>
                          <button
                              className="flex mb-2 w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-100 shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => handleResetPassword()}
                              disabled={isResettingPassword}
                          >
                            {isResettingPassword ? (
                                <CustomLoadingSpinner size={5}/>
                            ) : (
                                "Send Password Reset Link"
                            )}
                          </button>

                          <div className="mt-4 text-center">
                      <span className="text-neutral-100 hover:text-sky-500 cursor-pointer"
                            onClick={() => setActiveTab("sign-in")}>
                        Back to Sign In
                      </span>
                          </div>
                          {/* {resetPasswordSuccessState !== "" &&
                              <div className="text-neutral-100 text-center">{resetPasswordSuccessState}</div>}
                          {resetPasswordErrorState !== "" &&
                              <div className="text-red-500 text-center">{resetPasswordErrorState}</div>} */}
                        </div>
                    )
                }

                <div>
                  <div className="relative mt-8">
                    <div className="w-full h-[1px] bg-neutral-700"/>
                    {/*<div className="relative flex justify-center text-sm font-medium leading-6">*/}
                    {/*  <span className="bg-neutral-900 px-6 text-neutral-300">Or continue with</span>*/}
                    {/*</div>*/}
                  </div>

                  <div className="mt-6 grid grid-cols-1 gap-4">
                    <button
                        onClick={() => signInWithGoogle()}
                        className="cursor-pointer flex w-full items-center justify-center gap-3 rounded bg-white bg-opacity-10 ring-1 ring-neutral-600 text-neutral-400 hover:text-white transition duration-300 grayscale hover:grayscale-0 px-3 h-[40px] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 186.69 190.5">
                        <g transform="translate(1184.583 765.171)">
                          <path
                              clipPath="none"
                              mask="none"
                              d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
                              fill="#4285f4"
                          />
                          <path
                              clipPath="none"
                              mask="none"
                              d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
                              fill="#34a853"
                          />
                          <path
                              clipPath="none"
                              mask="none"
                              d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
                              fill="#fbbc05"
                          />
                          <path
                              d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
                              fill="#ea4335"
                              clipPath="none"
                              mask="none"
                          />
                        </g>
                      </svg>
                      <span className="text-sm font-semibold leading-6">Continue with Google</span>
                    </button>
                    {/*<button*/}
                    {/*    onClick={() => signInWithMicrosoft()}*/}
                    {/*    className="cursor-pointer flex w-full items-center justify-center gap-3 rounded bg-white bg-opacity-10 ring-1 ring-neutral-600 text-neutral-400 hover:text-white transition duration-300 grayscale hover:grayscale-0 px-3 h-[40px] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"*/}
                    {/*>*/}
                    {/*  <img className='h-[16px]'*/}
                    {/*       src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Microsoft_icon.svg/2048px-Microsoft_icon.svg.png"*/}
                    {/*       alt='microsoft-logo'/>*/}
                    {/*  <span className="text-sm font-semibold leading-6">Continue with Microsoft</span>*/}
                    {/*</button>*/}
                  </div>
                </div>
              </div>

            </div>
            <div className='flex items-center justify-center mb-10'>
              <div className='w-[320px] text-neutral-400 text-xs text-center'>By continuing, you agree to our
                <br/><a className='underline' href="https://www.story-boards.ai/terms-and-conditions">Terms of Service</a> & <a className='underline' href="https://www.story-boards.ai/privacy-policy">Privacy Policy</a>
              </div>
            </div>
          </div>
      )}
      </div>
    </>
  );
}
