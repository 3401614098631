import { HttpClient } from "../client";

import { ProfileRto } from "../../models/user/userRto.model";

export class User extends HttpClient {
  public constructor(url: string) {
    super(url);
  }

  private readonly prefix: string = "users";

  public getProfile = () => {
    return this.instance.get<any>(`/${this.prefix}/profile`);
  };

  public setTeam = ({ teamId }: { teamId: string }) => {
    return this.instance.post<any>(`/${this.prefix}/team/${teamId}`);
  };

  public subscribe = ({ userEmail, userId }: { userEmail: string, userId: string }) => {
    return this.instance.post<any>(`/${this.prefix}/${userId}/subscribe`, { userEmail });
  }

  public setFirstLogin = ({ userId, firstLogin }: { userId: string, firstLogin: boolean }) => {
    return this.instance.patch<any>(`/${this.prefix}/${userId}/first-login`, { userId, firstLogin });
  }

  public deleteProfile = () => {
    return this.instance.delete<any>(`/${this.prefix}/profile`,);
  }

  public updateClientId = ({ userId, clientId }: { userId: string, clientId: string }) => {
    return this.instance.patch<any>(`/${this.prefix}/${userId}/client-id`, { clientId });
  }
}
