import React from 'react';
import { sendEventToGoogleTagManager } from '../../../../core/utils/googleTagManager';
import { useSelector } from 'react-redux';
import { useBaseModalContext } from '../base-modal/BaseModalContext';

const VideoModal: React.FC = () => {
  const { youtubeId, videoUrl } = useBaseModalContext();

  const { userId, availableCredits } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits,
  }));

  sendEventToGoogleTagManager('intro_video_viewed', {}, userId, availableCredits);

  return (
    <div className="flex justify-center items-center w-full h-full p-2">
      <div className="w-full h-full">
        {youtubeId ? (
          <iframe
            className="w-full h-full"
            src={`https://www.youtube.com/embed/${youtubeId}?rel=0&modestbranding=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video Modal"
          ></iframe>
        ) : (
          <video
            className="w-full h-full"
            src={videoUrl as string}
            controls
            autoPlay
          />
        )}
      </div>
    </div>
  );
};

export default VideoModal;
