import { SET_ACCOUNT } from "../constants/accountConstants";
import { pricing } from "../../config/pricing";

export const accountReducer = (state = {}, action: any) => {
  console.log({ action });
  switch (action.type) {
    case SET_ACCOUNT:
      return {
        ...state,
        ...action.data,
        subscription: {
          ...action.data.subscription,
          tier: pricing.tiers.find(tier =>
            tier?.id === action.data.subscription?.id ||
            tier?.id === action.data.subscription?.id
          ) ?? { id: "tier-free", active: false }
        }
      };
    default:
      return state;
  }
};
