import { useEffect, useState } from "react";
import Style from "../style";

import { Switch } from "@headlessui/react";
import { useBaseModalContext } from "../modals/base-modal/BaseModalContext";
import useAuth from "../../../core/hooks/auth";
import { toast } from "react-toastify";
import { FREE_STYLE_INDICES, stylesData } from "./styles-data";
export const StyleSelector = ({
  updateStyleRequest,
  selectedStyle,
  setSelectedStyle
}: {
  updateStyleRequest: Function,
  selectedStyle: string,
  setSelectedStyle: Function
}) => {
  const [data]: any = useAuth();
  const [onlyCurrentSubscriptionSwitch, setOnlyCurrentSubscriptionSwitch] = useState(false);

  const [updateStyleInfo, setUpdateStyleInfo] = useState({ value: "", title: "", clientIndex: 0 });
  const baseModalContext = useBaseModalContext();
  const isAdmin = process.env.REACT_APP_ADMIN === 'true';
  const isPaidUser = !["tier-free", undefined].includes(data?.data?.subscription?.id);

  useEffect(() => {
    baseModalContext.setOnSave(() => () => {
      const selectedStyle = stylesData.find(style => style.index === updateStyleInfo.value);
      if (!selectedStyle) {
        toast.error("Invalid style selected");
        return;
      }

      const isStyleAvailable = isAdmin ||
        FREE_STYLE_INDICES.includes(selectedStyle.index) ||
        !["tier-free", undefined].includes(data?.data?.subscription?.id);

      if (isStyleAvailable) {
        updateStyleRequest(updateStyleInfo);
        // toast.success(`Style "${selectedStyle.title}" selected`);
      } else {
        toast.error("Please upgrade to use this style");
      }
    });
  }, [updateStyleInfo, data?.data?.subscription?.id]);

  return (
    <div className="flex flex-col items-center justify-center px-10 pt-4 h-[50vh]">
      <div className="flex justify-end gap-x-2 items-center w-full mb-4">
        {!isPaidUser && (
          <div className="flex gap-x-4 items-center">
            <div className="flex gap-x-2">
              <Switch
                checked={onlyCurrentSubscriptionSwitch}
                onChange={setOnlyCurrentSubscriptionSwitch}
                className={`${onlyCurrentSubscriptionSwitch ? "bg-blue-600" : "bg-gray-400"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${onlyCurrentSubscriptionSwitch ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <p>In your subscription</p>
            </div>
          </div>
        )}
      </div>
      <div className="flex-1 w-full overflow-y-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 p-1 border-none pb-24">
          {stylesData.map((style) => {
            const isStyleAvailable = isAdmin ||
              FREE_STYLE_INDICES.includes(style.index) ||
              isPaidUser;

            if (isPaidUser || !onlyCurrentSubscriptionSwitch || isStyleAvailable) {
              return (
                <Style
                  key={style.index}
                  index={style.index}
                  img={style.img}
                  title={style.title}
                  description={style.description}
                  onClick={(value: any) => {
                    setUpdateStyleInfo({ value, title: style.title, clientIndex: style.clientIndex });
                    setSelectedStyle(value);

                    if (!isStyleAvailable) {
                      toast.warning("This style requires a subscription upgrade");
                    }
                  }}
                  isActive={selectedStyle === style.index}
                  showStar={
                    !isAdmin &&
                    !FREE_STYLE_INDICES.includes(style.index) &&
                    !isPaidUser
                  }
                  clientIndex={style.clientIndex}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};
