import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { Project, Shot } from "../types/types";
import { toast } from "react-toastify";
import { storyBoardsApiClient } from "../config/api";
import { v4 as uuidv4 } from 'uuid';
import store from "../../core/redux/store";
import { getTeams } from "../../core/redux/actions/teamsActions";

type AppContextType = {
  aiCreditsSpentSinceReload: number;
  setAiCreditsSpentSinceReload: React.Dispatch<React.SetStateAction<number>>;
  inpaintCreditsSpentSinceReload: number;
  setInpaintCreditsSpentSinceReload: React.Dispatch<
    React.SetStateAction<number>
  >;
  projects: Project[];
  setProjects: (projects: Project[]) => void;
  addProject: ({ newProjectStory, newProjectDescription, newProjectLabel, newProjectType }: { newProjectLabel: string, newProjectStory: string, newProjectDescription: string, newProjectType: "manual" | "ai" }) => any;
  removeProject: (projectId: string) => void;
  fetchProjects: () => void;
  renameProject: (projectId: string, newLabel: string) => void;
  deleteProject: (projectId: string) => void;
  addShotHandler: (
    storyId: string,
    sceneId: string,
    newShot: Shot,
    index: number
  ) => Promise<void>;
  boardScreenViewMode: "BUILDER" | "INPAINT" | "EDIT_DESCRIPTION" | "IMG2IMG";
  setBoardScreenViewMode: (newViewMode: "BUILDER" | "INPAINT" | "EDIT_DESCRIPTION" | "IMG2IMG") => void;
  inpaintResponseImage: any | undefined;
  setInpaintResponseImage: (newImage: any | undefined) => void;
  isLoadingProjectsRequestInProgress: boolean;
  droppedFileImg2Img: File | undefined;
  setDroppedFileImg2Img: React.Dispatch<React.SetStateAction<File | undefined>>;
  img2ImgCreditsSpentSinceReload: number;
  setImg2ImgCreditsSpentSinceReload: React.Dispatch<React.SetStateAction<number>>;
  isTemplateLoading: boolean;
  setIsTemplateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  user: any | null;
  setUser: (user: any) => void;
  currentStory: any | null;
  setCurrentStory: (story: any) => void;
  charactersList: any[];
  setCharactersList: React.Dispatch<React.SetStateAction<any[]>>;
  pdfDataMap: { [storyId: string]: string };
  setPdfDataMap: React.Dispatch<React.SetStateAction<{ [storyId: string]: string }>>;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

type AppContextProviderProps = {
  children: ReactNode;
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context as AppContextType;
};

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [aiCreditsSpentSinceReload, setAiCreditsSpentSinceReload] =
    useState<number>(0);

  const [inpaintCreditsSpentSinceReload, setInpaintCreditsSpentSinceReload] =
    useState<number>(0);

  const [img2ImgCreditsSpentSinceReload, setImg2ImgCreditsSpentSinceReload] =
    useState<number>(0);

  const [boardScreenViewMode, setBoardScreenViewMode] = useState<
    "BUILDER" | "INPAINT" | "EDIT_DESCRIPTION" | "IMG2IMG"
  >("BUILDER");

  const [inpaintResponseImage, setInpaintResponseImage] = useState<
    any | undefined
  >(undefined);

  const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoadingProjectsRequestInProgress, setIsLoadingProjectsRequestInProgress] = useState<boolean>(false);
  const [user, setUser] = useState<any | null>(null);
  const [charactersList, setCharactersList] = useState<any[]>([]);
  const [currentStory, setCurrentStory] = useState<any | null>(null);
  const [pdfDataMap, setPdfDataMap] = useState<{ [storyId: string]: string }>({});

  const fetchProjects = async () => {
    console.log("Fetching projects from API...");
    setIsLoadingProjectsRequestInProgress(true);
    try {
      const result = await storyBoardsApiClient.story.getStories();
      console.log("Fetch stories API result:", result);
      if (result.status === 200) {
        const apiProjects: Project[] = result?.data?.stories || [];

        // Normalize timestamps to string format
        const normalizeTimestamps = (obj: any) => {
          if (obj) {
            Object.keys(obj).forEach((key) => {
              if (obj[key] instanceof Date) {
                obj[key] = obj[key].toString();
              }
            });
          }
        };

        const updatedProjects = apiProjects.map((project) => {
          normalizeTimestamps(project);
          project.scenes.forEach((scene) => {
            normalizeTimestamps(scene);
            scene.shots.forEach((shot) => normalizeTimestamps(shot));
          });
          return project;
        });

        setProjects(updatedProjects);
        console.log("Projects state updated from API:", updatedProjects);
      } else {
        throw new Error(`Failed to fetch projects: ${result.status}`);
      }
    } catch (error) {
      console.error("Error fetching projects from API:", error);
      toast.error("Something went wrong while fetching projects from API.");
    }
    setIsLoadingProjectsRequestInProgress(false);
  };

  const addProject = async ({ newProjectStory, newProjectType, newProjectLabel, newProjectDescription }: {
    newProjectLabel: string,
    newProjectStory: string,
    newProjectDescription: string,
    newProjectType:
    "manual"
    |
    "ai"
  }) => {
    const newProjectId = uuidv4();
    const newProject: Project = {
      id: newProjectId,
      label: newProjectLabel,
      story: newProjectStory,
      description: newProjectDescription,
      scenes: [],
      type: newProjectType,
      style: "1",
      isTemplate: false,
      isAnalyzed: false,
      inAnalyze: false,
      isGenerated: false,
      inGeneration: false,
      analyzeStep: 0,
      analyzeContent: {},
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      firstGeneratedImage: "",

    };

    console.log("Adding project (context update):", newProject);
    const updatedProjects = [...projects, newProject];

    setProjects(updatedProjects);

    try {
      console.log("Sending add project request to API...");
      const result = await storyBoardsApiClient.story.createStory({
        label: newProjectLabel,
        story: "",
        description: newProjectDescription,
        id: newProjectId,
        type: newProjectType,
      });
      console.log("Add project API result:", result);

      if (result.status !== 201) {
        throw new Error(`Failed to add project: ${result.status}`);
      }
      return result;
    } catch (error) {
      console.error("Error adding project via API:", error);
      toast.error("Something went wrong while adding the project");
      const prevProjects = [...projects];
      const filteredProjects = prevProjects.filter((project) => project.id !== newProjectId);
      setProjects(filteredProjects);
      console.log("Projects state reverted:", projects);
    }
  };

  const removeProject = async (projectId: string) => {
    console.log("Removing project (context update):", projectId);
    const updatedProjects = projects.filter(
      (project) => project.id !== projectId
    );
    const previousProjects = [...projects];
    setProjects(updatedProjects);
    console.log("Projects state updated (optimistic):", updatedProjects);

    try {
      console.log("Sending remove project request to API with payload:", {
        id: projectId,
      });
      const result = await storyBoardsApiClient.story.deleteStory({
        id: projectId,
      });
      console.log("Remove project API result:", result);
      if (result.status !== 200) {
        throw new Error(`Failed to remove project: ${result.status}`);
      }
    } catch (error) {
      console.error("Error removing project via API:", error);
      toast.error("Something went wrong while removing the project");
      setProjects(previousProjects);
      console.log("Projects state reverted:", previousProjects);
    }
  };

  const renameProject = async (projectId: string, newLabel: string) => {
    console.log("Renaming project (context update):", projectId, newLabel);
    const previousProjects = [...projects];
    const updatedProjects = projects.map((project) =>
      project.id === projectId ? { ...project, label: newLabel } : project
    );
    setProjects(updatedProjects);
    console.log("Projects state updated (optimistic):", updatedProjects);

    try {
      console.log("Sending rename project request to API with payload:", {
        id: projectId,
        label: newLabel,
      });
      const result = await storyBoardsApiClient.story.updateStory({
        id: projectId,
        label: newLabel,
      });
      console.log("Rename project API result:", result);
      if (result.status !== 200) {
        throw new Error(`Failed to rename project: ${result.status}`);
      }
    } catch (error) {
      console.error("Error renaming project via API:", error);
      toast.error("Something went wrong while renaming the project");
      setProjects(previousProjects);
      console.log("Projects state reverted:", previousProjects);
    }
  };

  const addShotHandler = async (
    storyId: string,
    sceneId: string,
    newShot: Shot,
    index: number
  ) => {
    console.log("add shot handler started");
    const tempShotId = uuidv4();
    const tempShot = {
      ...newShot,
      id: tempShotId,
      updatedAt: new Date().toISOString(),
    };

    const previousProjects = [...projects];

    const updatedProjects = projects.map((project) => {
      if (project.id === storyId) {
        project.scenes.forEach((scene) => {
          if (scene.id === sceneId) {
            scene.shots.splice(index, 0, tempShot);
          }
        });
      }
      return project;
    });

    setProjects(updatedProjects);

    try {
      const result = await storyBoardsApiClient.shot.create({
        storyId,
        sceneId,
        shotDescription: newShot.shotDescription,
        promptDescription: newShot.promptDescription,
        angle: newShot.angle,
        index,
      });

      if (result.status === 201) {
        const actualShot = result.data;
        const finalProjects = projects.map((project) => {
          if (project.id === storyId) {
            project.scenes.forEach((scene) => {
              if (scene.id === sceneId) {
                const shotIndex = scene.shots.findIndex(
                  (shot) => shot.id === tempShotId
                );
                if (shotIndex !== -1) {
                  scene.shots[shotIndex] = actualShot;
                }
              }
            });
          }
          return project;
        });

        setProjects(finalProjects);
      } else {
        throw new Error(`Failed to add shot: ${result.status}`);
      }
    } catch (error) {
      console.error("Error adding shot via API:", error);
      toast.error("Something went wrong while adding the shot");
      setProjects(previousProjects);
    }
  };

  useEffect(() => {
    // only call the fetchProjects function if the current page is the ProjectsScreen
    if (window.location.pathname === "/") {
      fetchProjects();
      console.log("Fetching teams from API...");
      getTeams();
    }
  }, []);

  // log to trace characterlist
  useEffect(() => {
    console.log('characterList:', charactersList);
  }, [charactersList]);

  const [droppedFileImg2Img, setDroppedFileImg2Img] = useState<File | undefined>(undefined);

  return (
    <AppContext.Provider
      value={{
        aiCreditsSpentSinceReload,
        setAiCreditsSpentSinceReload,
        inpaintCreditsSpentSinceReload,
        setInpaintCreditsSpentSinceReload,
        boardScreenViewMode,
        setBoardScreenViewMode,
        inpaintResponseImage,
        setInpaintResponseImage,
        projects,
        addProject,
        setProjects,
        fetchProjects,
        removeProject,
        renameProject,
        deleteProject: removeProject,
        addShotHandler,
        isLoadingProjectsRequestInProgress,
        droppedFileImg2Img,
        setDroppedFileImg2Img,
        img2ImgCreditsSpentSinceReload,
        setImg2ImgCreditsSpentSinceReload,
        isTemplateLoading,
        setIsTemplateLoading,
        user,
        setUser,
        currentStory,
        setCurrentStory,
        charactersList,
        setCharactersList,
        pdfDataMap,
        setPdfDataMap,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
