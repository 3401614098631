import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { storyBoardsApiClient } from "../../../../core/config/api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { characters } from "../../../../assets/characters/characters";
import { sendEventToGoogleTagManager } from "../../../../core/utils/googleTagManager";
import {useBaseModalContext} from "../base-modal/BaseModalContext";

interface IRequiredProps {
}

interface IOptionalProps {
}

interface IProps extends IRequiredProps, IOptionalProps {
}

export default function EditCharacter({}: IProps) {
  const { userId, availableCredits, modalManager, updateCharacterInState, addCharacterToState, modalType } = useSelector(
    (state: any) => ({
      userId: state.app.account?.id,
      availableCredits: state.app.account?.stats?.availableCredits,
      modalManager: state.app.modalManager,
      updateCharacterInState: state.app.modalManager.data.updateCharacterInState,
      addCharacterToState: state.app.modalManager.data.addCharacterToState,
      modalType: state.app.modalManager.data.mode
    })
  );
  const [filteredCharacters, setFilteredCharacters] = useState<any>([]);
  const {setIsOpen} = useBaseModalContext();
  const {modalData} = useBaseModalContext();

  const [character, setCharacter] = useState<Character | null>(null);

  useEffect(() => {
    if (modalData.mode === "UPDATE" && modalData.character) {
      setCharacter(modalData.character);
    } else {
      setCharacter(null); 
    }
  }, [modalData]);
  
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [selectedAgeRange, setSelectedAgeRange] = useState<string>("20's");

  const images = document.querySelectorAll('.zoomable-image');

  images.forEach((image) => {
    image.addEventListener('click', () => {
      // Toggle the zoomed-image class on the clicked image
      image.classList.toggle('');

      // Ensure that the zoomed image appears above others
      if (image.classList.contains('')) {
        images.forEach((otherImage) => {
          if (otherImage !== image) {
            (otherImage as HTMLElement).style.zIndex = '1'; // Send other images to the background
          }
        });
        (image as HTMLElement).style.zIndex = '2'; // Bring the clicked image to the foreground
      } else {
        images.forEach((otherImage) => {
          (otherImage as HTMLElement).style.zIndex = '1'; // Reset other images to the background
        });
      }
    });
  });

  const { storyId } = useParams();

  const [form, setForm] = useState<{
    image?: string;
    name?: string;
  }>({
    image: modalManager?.data?.character ? modalManager?.data?.character?.image : undefined,
    name: modalManager?.data?.character ? modalManager?.data?.character?.name : undefined
  });

  const updateCharacterRequest = async () => {
    setErrorMessage("");

    if (!form?.name || form?.name && (form?.name?.length < 2)) {
      setErrorMessage("Minimum 2 characters required as character name!");
      return;
    }

    if (!form?.image) {
      setErrorMessage("An image required for the character!");
      return;
    }

    try {
      const result = await storyBoardsApiClient.character.updateCharacter({
        id: modalManager?.data?.character?.id ?? "",
        storyId: storyId ?? "",
        name: form.name,
        image: form.image
      });
      if (result.status === 200) {
        updateCharacterInState && updateCharacterInState({ character: result.data });
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }

    setIsOpen(false);
  };

  const createCharacterRequest = async () => {
    setErrorMessage("");

    if (!form?.name || form?.name && (form?.name?.length < 2)) {
      setErrorMessage("Minimum 2 letters required for the character name!");
      return;
    }

    if (!form?.image) {
      setErrorMessage("An image required for the character!");
      return;
    }

    try {
      const result = await storyBoardsApiClient.character.createCharacter({
        storyId: storyId ?? "",
        name: form.name ?? "",
        description: "",
        image: form.image ?? ""
      });
      if (result.status === 201) {
        sendEventToGoogleTagManager('create_character_success', {
          event_category: 'Character Management',
          event_action: 'Create',
          event_label: 'Success',
          character_image: result.data.image
        }, userId, availableCredits);

        addCharacterToState({ character: result.data });
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      sendEventToGoogleTagManager('create_character_fail', {
        event_category: 'Character Management',
        event_action: 'Create',
        event_label: 'Failure'
      }, userId, availableCredits);

      console.log({ error });
      toast.error("Something went wrong");
      sendEventToGoogleTagManager('create_character_fail', {
        event_category: 'Character Management',
        event_action: 'Create',
        event_label: 'Failure'
      }, userId, availableCredits);
    }

    setIsOpen(false);
  };

  const handleInputChange = ({
                               name,
                               value
                             }: {
    name: string;
    value: string;
  }) => {
    if (form?.name && (form?.name?.length >= 2)) {
      setErrorMessage("");
    }

    setForm((prevForm): any => ({
      ...prevForm,
      [name]: value
    }));
  };

  interface Character {
    img: string;
    image: string;
    age: string;
    sex: string;
    ethnicity: string;
    face_prompt: string;
    id: string;
    name: string;
  }

  function filterCharactersByAge({ minAge, maxAge }: { minAge: number, maxAge: number }) {
    const array = characters.filter((character) => {
      const age = parseInt(character.age, 10);
      return age >= minAge && age <= maxAge;
    });

    setFilteredCharacters([...array]);
  }

  useEffect(() => {
    filterCharactersByAge({ minAge: 40, maxAge: 50 });
  }, []);

  return (
    <>
      <div className={"modal-container w-[1050px] h-[600px]"} style={{ zIndex: 10 }}>
        <div className="mt-3 sm:mt-5">
          <div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-1 flex-row gap-4">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block h-[48px] uppercase text-center bg-sky-500 rounded border-0 p-0 text-neutral-100 focus:ring-0 sm:text-sm sm:leading-6"
                  defaultValue={modalManager?.data?.character?.name}
                  onChange={(event) =>
                    handleInputChange({
                      name: "name",
                      value: event.target.value
                    })
                  }
                />
                <div className={"age-slider flex gap-x-4 items-center justify-center"}>
                <input
                    type="range"
                    min={10}
                    max={80}
                    onChange={(event) => {
                      const selectedValue = parseInt(event.target.value);
                      const lowerBound = Math.floor(selectedValue / 10) * 10;
                      const upperBound = lowerBound + 9;
                      setSelectedAgeRange(`${lowerBound}'s`);
                      filterCharactersByAge({
                        minAge: lowerBound,
                        maxAge: upperBound,
                      });
                    }}
                  />
                  <p className={"text-neutral-300"}>Age: {selectedAgeRange}</p>
                </div>
              </div>
              <div className="virtual-actors overflow-y-scroll h-[500px] overflow-hidden">
              <div className="flex flex-wrap gap-2 mb-4">
                {filteredCharacters.map((character: any) => (
                  <div
                    key={character?.id}
                    onClick={() =>
                      handleInputChange({
                        name: "image",
                        value: character.img
                      })
                    }
                    className={"relative w-[200px] h-[200px] flex items-center justify-center"}
                  >
                    <img
                      className={`cursor-pointer rounded zoomable-image ${character.img === form["image"] ? `w-[200px] h-[200px] border-4 border-blue-500` : `w-[200px] h-[200px]`} hover:w-[200px] hover:h-[200px]`}
                      src={`${character.image}`}
                    />
                  </div>
                ))}
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {errorMessage && <div className={"text-sm text-red-500 flex justify-end"}>
        {errorMessage}
      </div>}
      <div className="flex flex-row gap-4 justify-end mt-5 sm:mt-6">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
              setIsOpen(false)
          }
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-neutral-100 shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => modalType === "UPDATE" ? updateCharacterRequest() : createCharacterRequest()}
        >
          {modalType === "UPDATE" ? "Save" : "Create"}
        </button>
      </div>
    </>
  );
}
