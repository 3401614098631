import { HttpClient } from "../client";

export class Character extends HttpClient {
  private readonly prefix: string = "characters";

  public constructor(url: string) {
    super(url);
  }

  public createCharacter = ({
                              name,
                              description,
                              image,
                              storyId
                            }: {
    name: string;
    description: string;
    image: string,
    storyId: string
  }) => {
    return this.instance.post<{ status: any; data: any }>(`stories/${storyId}/${this.prefix}`, {
      name,
      description,
      image
    });
  };

  public getCharacter = ({ id }: { id: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `/${this.prefix}/${id}`
    );
  };

  public getCharacters = ({ storyId }: { storyId: string }) => {
    return this.instance.get<{ status: any; data: any }>(`stories/${storyId}/${this.prefix}`);
  };

  public updateCharacter = ({
                              id,
                              storyId,
                              name,
                              description,
                              image
                            }: {
    id: string;
    storyId: string;
    name?: string;
    description?: string;
    image?: string;
  }) => {
    return this.instance.patch<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`,
      {
        name,
        description,
        image
      }
    );
  };

  public deleteCharacter = ({ id, storyId }: { id: string, storyId: string }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`
    );
  };
}
