export interface Price {
  monthly: string;
  annually: string;

  [key: string]: string;
}

export interface FrequencyOption {
  value: FrequencyType;
  label: string;
  priceSuffix: string;
}

export type FrequencyType = "monthly" | "yearly";

export const pricing = {
  frequencies: [
    { value: "monthly", label: "Monthly", priceSuffix: "/month" } as {
      value: FrequencyType;
      label: string;
      priceSuffix: string;
    },
    { value: "yearly", label: "Annually", priceSuffix: "/month (billed annually)" } as {
      value: FrequencyType;
      label: string;
      priceSuffix: string;
    }
  ] as FrequencyOption[],
  tiers: [
    {
      name: "Free",
      id: "tier-free",
      href: "#",
      price: {
        monthly: { amount: "$0" },
        yearly: { amount: "$0" }
      },
      description: "Our forever free plan, offering the essentials to bring your vision to life at no cost.",
      features: [
        "1 Story",
        "2 Characters",
        "1 Style",
        "Manual Story building",
        "100 images",
        "PDF export"
      ],
      mostPopular: false
    },
    {
      name: "Indie",
      id: "tier-indie",
      href: "#",
      price: {
        monthly: { amount: "$15" },
        yearly: { amount: "$13" }
      },
      description: "Designed for creators seeking to enhance their narrative depth and reach.",
      features: [
        "2 Stories",
        "10 Characters",
        "4 styles",
        "Manual Story building",
        "1x Story import Wizard",
        "200 images",
        "PDF & JPG export",
        "Support"
      ],
      mostPopular: true
    }
    // {
    //   name: 'Pro',
    //   id: 'tier-pro',
    //   href: '#',
    //   price: { monthly: '$35', annually: '$336' },
    //   description: 'Unleash full potential with the Pro tier, crafted for professionals and teams demanding dedicated support and advanced tools',
    //   features: [
    //     'Unlimited products',
    //     'Unlimited subscribers',
    //     'Advanced analytics',
    //     '1-hour, dedicated support response time',
    //     'Marketing automations',
    //     'Custom reporting tools',
    //   ],
    //   mostPopular: false,
    // },
    /*{
      name: 'Studio',
      id: 'tier-studio',
      href: '#',
      price: { monthly: '$48', annually: '$576' },
      description: 'Dedicated support and infrastructure for your company.',
      features: [
        'Unlimited products',
        'Unlimited subscribers',
        'Advanced analytics',
        '1-hour, dedicated support response time',
        'Marketing automations',
        'Custom reporting tools',
      ],
      mostPopular: false,
    },*/
  ]
};