import { useMemo } from 'react';

interface UserAccount {
    hasHadTrial: boolean;
    isEligibleForTrial?: boolean; // Make this optional
    // Add other relevant fields from your account object
}

export const useTrialEligibility = (account: UserAccount | null) => {
    const isEligibleForTrial = useMemo(() => {
        if (!account) return false;

        // If the user has already had a trial, they are not eligible
        if (account.hasHadTrial) return false;

        // If isEligibleForTrial is not set (undefined), we consider the user eligible
        // since they haven't had a trial
        if (account.isEligibleForTrial === undefined) {
            return true;
        }

        // User is eligible if they are selected for the A/B test
        return account.isEligibleForTrial;
    }, [account]);

    return isEligibleForTrial;
};