import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TReduxStore } from '../redux/store';
import { setAccount } from '../redux/actions/accountActions';
import { storyBoardsApiClient } from '../config/api';

const useAnalyticsClientId = (): string | null => {
    const [clientId, setClientId] = useState<string | null>(null);
    const dispatch = useDispatch();
    const storedClientId = useSelector((state: TReduxStore) => state.app.account.clientId);
    const userId = useSelector((state: TReduxStore) => state.app.account.id);

    useEffect(() => {
        console.log("Getting clientID");

        const getGACookie = (): string | null => {
            const cookies = document.cookie.split(';');
            for (let cookie of cookies) {
                cookie = cookie.trim();
                if (cookie.startsWith('_ga=')) {
                    return cookie.substring(4);
                }
            }
            return null;
        };

        const extractClientId = (gaCookie: string): string => {
            const parts = gaCookie.split('.');
            return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
        };

        const updateClientIdInBackend = async (newClientId: string) => {
            try {
                const response = await storyBoardsApiClient.user.updateClientId({ userId, clientId: newClientId });
                if (response.status === 200) {
                    setAccount({ data: { clientId: newClientId } })(dispatch);
                }
            } catch (error) {
                console.error('Failed to update client ID in backend:', error);
            }
        };

        const getClientId = () => {
            const gaCookie = getGACookie();
            if (gaCookie) {
                // console.log("ga_ cookie found")
                const id = extractClientId(gaCookie);
                setClientId(id);
                // console.log('Analytics Client ID:', id);

                if (id !== storedClientId) {
                    // console.log('Client ID changed, updating backend...');
                    updateClientIdInBackend(id);
                }
            } else {
                console.log('GA cookie not found');
                setClientId(null);
            }
        };

        getClientId();
    }, [dispatch, storedClientId, userId]);

    return clientId;
};

export default useAnalyticsClientId;