import React from "react";
import { Outlet } from "react-router-dom";

type Props = {};

export default function DeveloperSettingsLayout({}: Props) {
  return (
    <div className="dashboard-container">
      <div className="app-container">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
