import { HttpClient } from '../client';

export class Notifications extends HttpClient {
	private readonly prefix: string = "notifications";

	public constructor(url: string) {
		super(url);
	}

	public getNotifications = ({limit, offset}:{limit: number, offset: number}) => {
		return this.instance.get<{ status: any; data: any }>(
			`${this.prefix}?limit=${limit}&offset=${offset}`
		);
	};

	public readNotification = ({id}:{id: string}) => {
		return this.instance.put<{ status: any; data: any }>(
			`${this.prefix}/${id}`
		);
	};

	public removeNotification = ({id}:{id: string}) => {
		return this.instance.delete<{ status: any; data: any }>(
			`${this.prefix}/${id}`
		);
	};
}