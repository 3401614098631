import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { storyBoardsApiClient } from "../../../core/config/api";

const stripePromise = loadStripe("YOUR_STRIPE_PUBLISHABLE_KEY");

const SubscriptionForm = () => {
  const [priceId, setPriceId] = useState<string>();
  const [customer, setCustomer] = useState();
  const [cardElement, setCardElement] = useState();
  const [planId, setPlanId] = useState<string>();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const result = await storyBoardsApiClient.payment.subscribe({
      tier: -1,
      frequency: "monthly"
    });

    console.log({ result });

    window.open(result.data.url);

    // Handle the subscription data here
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Plan ID"
        value={"planId"}
        onChange={(e) => setPlanId(e.target.value)}
      />
      <button type="submit">Subscribe</button>
    </form>
  );
};

export default SubscriptionForm;
