import React, { useEffect, useState } from "react";
import ConfigUploader from "../../components/developer/config-uploader";

type Props = {};

export default function ConfigScreen({}: Props) {
  return (
    <>
      <div className="flex flex-row pb-8">
        <div className="flex-1">
          <p className="text-xl font-bold">Developer configs</p>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex-1">
          <p className="text-xl font-bold">Image style config manager</p>
        </div>
        <ConfigUploader />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
        </div>
      </div>
    </>
  );
}
