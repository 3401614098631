import { SET_TEAMS } from "../constants/teamsConstants";

export const teamsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case SET_TEAMS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};
