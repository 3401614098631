import { HttpClient } from "../client";

export class Shot extends HttpClient {
  private readonly prefix: string = "shots";

  public constructor(url: string) {
    super(url);
  }

  public create = ({
                     storyId,
                     shotDescription,
                     promptDescription,
                     sceneId,
                     angle,
                     index
                   }: {
    storyId: string;
    shotDescription: string;
    promptDescription: string;
    sceneId: string;
    angle: string;
    index: number;
  }) => {
    return this.instance.post<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}`,
      {
        shotDescription,
        promptDescription,
        sceneId,
        angle,
        index
      }
    );
  };

  public retrieve = ({ id, storyId }: { id: string; storyId: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`
    );
  };

  public retrieveAll = ({ storyId }: { storyId: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}`
    );
  };

  public update = ({
                     id,
                     storyId,
                     shotDescription,
                     promptDescription,
                     imageDescription,
                     angle
                   }: {
    id: string;
    storyId: string;
    shotDescription?: string;
    promptDescription?: string;
    imageDescription?: string;
    angle?: string;
  }) => {
    return this.instance.patch<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`,
      {
        shotDescription,
        promptDescription,
        imageDescription,
        angle
      }
    );
  };

  public remove = ({ id, storyId }: { id: string; storyId: string }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`
    );
  };

  public newImage = ({
                       id,
                       storyId,
                       type,
                       metadata
                     }: {
    id: string;
    storyId: string;
    type: "NEW" | "VERSION" | "TUNE" | "INPAINT" | "IMG2IMG";
    metadata: any;
  }) => {
    return this.instance.post<{
      statusText: string; status: any; data: any 
}>(
      `stories/${storyId}/${this.prefix}/${id}/new-image`,
      { type, metadata }
    );
  };

  public setImage = ({
                       id,
                       storyId,
                       imageId
                     }: {
    id: string;
    storyId: string;
    imageId: string;
  }) => {
    return this.instance.post<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}/set-image`,
      { imageId }
    );
  };

  public delete = ({
                     id,
                     storyId
                   }: {
    id: string;
    storyId: string;
  }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`
    );
  };
}
