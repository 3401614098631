import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { storyBoardsApiClient } from "../../../core/config/api";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import { useSelector } from "react-redux";
import { CustomLoadingSpinner } from "../loading/CustomLoadingSpinner";
import UniversalTippy from "../universal-tippy/UniversalTippy";
import { useAppContext } from "../../../core/utils/AppContext";

type Shot = {
  angle: string;
  createdAt: Date;
  id: string;
  index: number;
  promptDescription: string;
  shotDescription: string;
  updatedAt: Date;
  selectedImage: string;
};

type Scene = {
  shots: Shot[];
  content: string;
  id: string;
  description: string;
  index: number;
};

type EditDescriptionProps = {
  shot: Shot;
  scene: Scene;
  story: any;
  scenes: Scene[];
  setScenes: (scenes: any[]) => void;
};

const EditDescription = ({shot, scene, story, scenes, setScenes}: EditDescriptionProps) => {

  const appContext = useAppContext();

  const [shotDescriptionInput, setShotDescriptionInput] = useState<string>("");
  const [sceneContentInput, setSceneContentInput] = useState<string>("");
  const [sceneDescriptionInput, setSceneDescriptionInput] = useState<string>("");

  const [isUpdateSceneRequestInProgress, setIsUpdateSceneRequestInProgress] = useState(false);
  const [isUpdateShotDescriptionRequestInProgress, setIsUpdateShotDescriptionRequestInProgress] = useState(false);

  const availableCredits = useSelector((state: any) => state.app.account.stats.availableCredits);
  const userId = useSelector((state: any) => state.app.account.id);

  const updateSceneRequest = async () => {
     if (story?.isTemplate) {
      toast.info("Demo projects cannot be changed");
      appContext.setBoardScreenViewMode("BUILDER");
      return;
    }
    setIsUpdateSceneRequestInProgress(true);
    try {
      const result = await storyBoardsApiClient.scene.update({
        id: scene.id,
        storyId: story?.id ?? "",
        content: sceneContentInput,
        description: sceneDescriptionInput
      });
      if (result.status === 200) {
        const _scenes = [...scenes];

        const index = _scenes?.findIndex((element) => element.id === scene.id);

        _scenes[index] = result.data;

        setScenes(_scenes);
        sendEventToGoogleTagManager("edit_scene_success", {
          story_management: "Story Management",
          scene_action: "edit",
          story_id: story?.id,
          outcome: "success"
        }, userId, availableCredits);

        } else {
          toast.error("Scene update failed");
          sendEventToGoogleTagManager("edit_scene_fail", {
            story_management: "Story Management",
            scene_action: "edit",
            story_id: story?.id,
            outcome: "fail"
          }, userId, availableCredits);
        }
        } catch (error) {
          console.log({ error });
          toast.error("Something went wrong. ID: " + scene.id + ", Error: " + (error as Error).message ?? "");
          sendEventToGoogleTagManager("edit_scene_fail", {
            story_management: "Story Management",
            scene_action: "edit",
            story_id: story?.id,
            outcome: "fail",
            error_message: (error as Error).message ?? "Unknown error"
          }, userId, availableCredits);
        }
    setIsUpdateSceneRequestInProgress(false);
  };

  useEffect(() => {
    setShotDescriptionInput(shot?.shotDescription ?? "");
    setSceneContentInput(scene?.content ?? "");
    setSceneDescriptionInput(scene?.description ?? "");
  }, [shot, scene, scenes])

  const updateShotDescriptionRequest = async (shotId: string, value: string) => {
    setIsUpdateShotDescriptionRequestInProgress(true);
    try {
      const result = await storyBoardsApiClient.shot.update({
        id: shotId,
        storyId: story?.id,
        shotDescription: value
      });
      if (result.status === 200) {
        // Update local state to reflect the change
        const updatedScenes = scenes.map(scene => {
          return {
            ...scene,
            shots: scene.shots.map((shot: { id: any; }) => {
              if (shot.id === shotId) {
                return { ...shot, shotDescription: value };
              }
              return shot;
            })
          };
        });
        setScenes(updatedScenes);
      } else {
        toast.error("Something went wrong with updating the prompt");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
    setIsUpdateShotDescriptionRequestInProgress(false);
  };

  const saveDescription = async () => {
    if (story?.isTemplate) {
      toast.info("Demo projects cannot be changed");
      appContext.setBoardScreenViewMode("BUILDER");
      return;
    }

    await updateShotDescriptionRequest(shot?.id, shotDescriptionInput);
  };

  const shotIndex = scenes.find((scene: any) => scene?.shots?.some((newShot: any) => newShot?.id === shot?.id))?.shots.findIndex((newShot: any) => newShot?.id === shot?.id) ?? 0;

  const sceneIndex = scenes.findIndex((newScene: any) => newScene?.id === scene?.id) ?? 0;

  return (
    <div className="text-neutral-100 w-full bg-neutral-900 flex flex-col gap-y-8 px-4 max-w-[700px]">
      <div className="w-full flex justify-end">
        <button
          className="font-bold text-lg border-gray-700 border bg-white bg-opacity-5 w-8 h-8 rounded-full"
          onClick={() => {
            appContext.setBoardScreenViewMode("BUILDER");
          }}
        >
          <XMarkIcon></XMarkIcon>
        </button>
      </div>
      <div className="p-4 border border-neutral-500 rounded-lg bg-neutral-800 flex flex-col gap-y-4">
        <div className="text-lg font-bold">Scene {sceneIndex + 1}:</div>
          <div>
            <label htmlFor="scene-content-input">Scene Title:</label>
            <UniversalTippy content="This title is just to keep your story tidy">
              <input
                id="scene-content-input"
                className="w-full bg-neutral-900 border-0 text-white"
                value={sceneContentInput}
                onChange={(e) => {
                  setSceneContentInput(e.target.value);
                }}
              ></input>
            </UniversalTippy>
        </div>
        <div>
          <label htmlFor="scene-description-input">Scene Description:</label>
          <UniversalTippy content="This description will be used to render your scene consistently. All shots within this scene will be set at this location">
            <textarea
              id="scene-content-input"
              rows={3}
              className="w-full bg-neutral-900 border-0 text-white"
              value={sceneDescriptionInput}
              onChange={(e) => {
                setSceneDescriptionInput(e.target.value);
              }}
            ></textarea>
          </UniversalTippy>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => appContext.setBoardScreenViewMode("BUILDER")}
            className="mr-4"
          >
            Cancel
          </button>
          <div className="relative">
            <button
              className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 w-[80px]"
              onClick={() => {
                updateSceneRequest();
              }}
              
            >
              Save
            </button>
            {
              isUpdateSceneRequestInProgress && (
                <div className="absolute top-0 left-0 w-full h-full bg-neutral-900 bg-opacity-90 flex items-center justify-center rounded">
                  <CustomLoadingSpinner size={16} />
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="p-4 border border-neutral-500 rounded-lg bg-neutral-800 flex flex-col gap-y-4">
        <div className="text-lg font-bold">Scene: {sceneIndex + 1} / Shot: {shotIndex + 1}</div>
        <div>
          <label htmlFor="shot-description">
            Shot Description:
          </label>
          <UniversalTippy content="This text will appear under your storyboard image">
            <textarea
              id="shot-description"
              rows={3}
              className="w-full bg-neutral-900 border-0 text-white"
              value={shotDescriptionInput}
              onChange={(e) => {
                setShotDescriptionInput(e.target.value);
              }}
            ></textarea>
          </UniversalTippy>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => appContext.setBoardScreenViewMode("BUILDER")}
            className="mr-4"
          >
            Cancel
          </button>
          <div className="relative">
            <button
              className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 w-[80px]"
              onClick={() => saveDescription()}
            >
              Save
            </button>
            {
              isUpdateShotDescriptionRequestInProgress && (
                <div className="absolute top-0 left-0 w-full h-full bg-neutral-900 bg-opacity-90 flex items-center justify-center rounded">
                  <CustomLoadingSpinner size={16} />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDescription;
