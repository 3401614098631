import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";

export default function CheckoutScreen() {
  const stripeApiKey = loadStripe("pk_test_51OwjL6Rq0TSpJNEVddtYf04y36H7Wt25qViZy5MDKIpG3PkIBISwPZbzHrezEFj7CqRDAb3tkMk5fB7g8dWRJQDP00GIk8OZHg");

  return (
    <Elements stripe={stripeApiKey}>
      <Checkout />
    </Elements>
  );
}