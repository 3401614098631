import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

//ACTIONS
import { TModalStore } from "./actions/modalManagerActions";

//REDUCERS
import { accountReducer } from "./reducers/accountReducer";
import { modalManagerReducer } from "./reducers/modalManagerReducer";
import { ENUM_MODAL_TYPE } from "../types/modal.type";
import { TAccountStore } from "./actions/accountActions";
import { storiesReducer, storyReducer } from "./reducers/storiesReducer";
import { teamsReducer } from "./reducers/teamsReducer";

const appReducers = combineReducers({
  account: accountReducer,
  modalManager: modalManagerReducer,
  stories: storiesReducer,
  story: storyReducer,
  teams: teamsReducer
});

const coreReducers = combineReducers({
  app: appReducers
});

const initialState: TReduxStore = {
  app: {
    account: {
      recentTeam: "",
      isAdmin: false,
      subscription: undefined,
      firstLogin: undefined,
      stats: undefined,
      id: undefined
    },

    modalManager: {
      modal: ENUM_MODAL_TYPE.EMPTY,
      data: null
    },
    stories: null,
    story: null,
    teams: null
  }
};

export type TReduxStore = {
  app: {
    account: TAccountStore;
    modalManager: TModalStore;
    stories: any;
    story: any;
    teams: any;
  };
};

const middleware = [thunk];

const store = createStore(
  coreReducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
