import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/20/solid';
import { storyBoardsApiClient } from '../../../core/config/api';
import { sendEventToGoogleTagManager } from '../../../core/utils/googleTagManager';
import { characters } from '../../../assets/characters/characters';
import { useBaseModalContext } from '../modals/base-modal/BaseModalContext';
import { TCharacter } from '../character/Character';
import { TModalMode } from '../modals/base-modal/BaseModal';
import { useAppContext } from '../../../core/utils/AppContext';
import { useTrialEligibility } from '../../../core/utils/useTrialEligibility';

const freeTierCharacterIds = ['0072', '0173', '0024', '0160'];

interface CharacterSelectorProps {
  onCharacterCreated: (character: TCharacter) => void;
  onCharacterUpdated: (character: TCharacter) => void;
  onEditCharacter: (isEditing: boolean) => void;
  onClose?: () => void;
  onModeChange: (newMode: TModalMode) => void;
  subscription: string;
  account: any;
}

interface Ethnicity {
  label: EthnicityLabel;
  value: EthnicityValue[];
}

interface ActiveFilters {
  age: string[];
  gender: string[];
  ethnicity: string[];
}

interface FilterButtonProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

type AgeRange = "10's" | "20's" | "30's" | "40's" | "50's" | "60's" | "70's";
type Gender = "male" | "female";
type EthnicityLabel = "Caucasian" | "Asian" | "Black" | "South American" | "Middle Eastern" | "South Asian";
type EthnicityValue = "european" | "nordic" | "white" | "asian" | "black" | "south_american" | "middle_eastern" | "indian";

const ageRanges: AgeRange[] = ["10's", "20's", "30's", "40's", "50's", "60's", "70's"];
const genders: Gender[] = ["male", "female"];
const ethnicities: Ethnicity[] = [
  { label: "Caucasian", value: ["european", "nordic", "white"] },
  { label: "Asian", value: ["asian"] },
  { label: "Black", value: ["black"] },
  { label: "South American", value: ["south_american"] },
  { label: "Middle Eastern", value: ["middle_eastern"] },
  { label: "South Asian", value: ["indian"] },
];

const FilterButton: React.FC<FilterButtonProps> = ({ label, active, onClick }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1 rounded-md text-xs font-small transition-colors duration-200 ease-in-out ${active
      ? "bg-neutral-600 border border-neutral-600 text-white"
      : "bg-none text-neutral-600 border border-neutral-600"
      }`}
  >
    {label}
  </button>
);

const CharacterSelector: React.FC<CharacterSelectorProps> = ({
  onCharacterCreated,
  onCharacterUpdated,
  onEditCharacter,
  onModeChange,
  subscription,
  account
}) => {
  const baseModalContext = useBaseModalContext();
  const { charactersList, setCharactersList } = useAppContext();
  const [filteredCharacters, setFilteredCharacters] = useState<any[]>([]);
  const { setOnSave, mode, closeModal, modalData } = useBaseModalContext();

  const [form, setForm] = useState<{ image?: string; name?: string }>({
    image: baseModalContext.selectedCharacter?.image ?? '',
    name: baseModalContext.selectedCharacter?.name ?? '',
  });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedAgeRange, setSelectedAgeRange] = useState<string>("20's");
  const [sliderValue, setSliderValue] = useState(30);
  const [showOnlyCurrentSubscriptionSwitch, setShowOnlyCurrentSubscriptionSwitch] = useState(false);

  const { storyId } = useParams<{ storyId: string }>();

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    age: [],
    gender: [],
    ethnicity: [],
  });

  const toggleFilter = useCallback((category: keyof ActiveFilters, value: string) => {
    setActiveFilters((prev) => {
      const newFilters = { ...prev };
      if (newFilters[category].includes(value)) {
        newFilters[category] = newFilters[category].filter((v) => v !== value);
      } else {
        newFilters[category] = [...newFilters[category], value];
      }
      return newFilters;
    });
  }, []);

  const filterCharacters = useCallback(() => {
    const filteredChars = characters.filter((character) => {
      const age = parseInt(character.age, 10);
      const ageRange = `${Math.floor(age / 10) * 10}'s`;

      const matchesAge = activeFilters.age.length === 0 || activeFilters.age.includes(ageRange);
      const matchesGender = activeFilters.gender.length === 0 || activeFilters.gender.includes(character.sex);
      const matchesEthnicity = activeFilters.ethnicity.length === 0 ||
        activeFilters.ethnicity.some(ethnicity =>
          ethnicities.find(e => e.label === ethnicity)?.value.includes(character.ethnicity as EthnicityValue)
        );
      return matchesAge && matchesGender && matchesEthnicity;
    });

    setFilteredCharacters(filteredChars);
  }, [activeFilters, characters]);

  useEffect(() => {
    filterCharacters();
  }, [filterCharacters]);

  const updateCharacterRequest = async () => {
    console.log("updateCharacterRequest started");

    const currentSelectedCharacter = baseModalContext.selectedCharacter;
    const characterToUpdate = mode === 'UPDATE' ? currentSelectedCharacter : modalData.character;

    console.log("Current selected character:", currentSelectedCharacter);
    console.log("Original character:", characterToUpdate);

    const updatedFields: Partial<TCharacter> = {
      id: characterToUpdate?.id, // Include the ID
      name: characterToUpdate?.name, // Keep the original name
      image: currentSelectedCharacter?.image, // Update the image
    };

    console.log("Updating character with fields:", updatedFields);

    try {
      const result = await storyBoardsApiClient.character.updateCharacter({
        id: characterToUpdate?.id ?? '',
        storyId: storyId ?? '',
        ...updatedFields
      });

      console.log("API response:", result);

      if (result.status === 200) {
        console.log("Character updated successfully:", result.data);
        // toast.success(`Character ${characterToUpdate?.name} updated`);
        onCharacterUpdated(result.data);
        setCharactersList(prevList =>
          prevList.map(char =>
            char.id === result.data.id
              ? { ...char, ...result.data }
              : char
          )
        );
        setErrorMessage('');
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.error('Error in API call:', error);
      toast.error('Something went wrong');
    }
    console.log("updateCharacterRequest finished");
  };

  const isEligibleForTrial = useTrialEligibility(account.data);

  const createCharacterRequest = async () => {
    console.log('Creating character...', form);

    if (!form.image) {
      toast.error('You must select an actor first');
      return;
    }

    if (!freeTierCharacterIds.includes(form.image.slice(0, 4) ?? '0000') && !subscription) {
      if (isEligibleForTrial) {
        toast.info('Start your free trial to use this actor');
      } else {
        toast.info('Please subscribe to use this actor');
      }
      return;
    }

    if (!form.name || form.name.length < 2) {
      toast.error('Minimum 2 letters required for the character name!');
      return;
    }

    try {
      const result = await storyBoardsApiClient.character.createCharacter({
        storyId: storyId ?? '',
        name: form.name,
        description: '',
        image: form.image,
      });
      if (result.status === 201) {
        // toast.success('Character created successfully');
        sendEventToGoogleTagManager('create_character_success', {
          category: 'Character Management',
          action: 'Create',
          label: 'Success',
          character_image: result.data.image,
        });
        onCharacterCreated(result.data);
        setCharactersList(prevList => [...prevList, result.data]);
        setErrorMessage('');
        closeModal();
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
      sendEventToGoogleTagManager('create_character_fail', {
        category: 'Character Management',
        action: 'Create',
        label: 'Failure',
        error_message: error || 'Unknown error',
      });
    }
  };

  const handleSave = useCallback(async () => {
    // console.log('handleSave called. Current mode:', mode);
    // console.log('Current form state:', form);
    // console.log('Current selectedCharacter:', baseModalContext.selectedCharacter);
    // console.log('Current modalData:', modalData);
    // console.log("account: ", account)

    if (mode === 'UPDATE' || mode === 'AI_FOUND') {
      await updateCharacterRequest();
      console.log('updateCharacterRequest completed');
      if (mode === 'UPDATE') {
        onModeChange('CAST');
      } else {
        closeModal();
      }
    } else if (mode === 'NEW') {
      await createCharacterRequest();
      console.log('createCharacterRequest completed');
    } else {
      console.log('Unhandled mode in handleSave:', mode);
    }
  }, [mode, form, baseModalContext.selectedCharacter, modalData]);

  useEffect(() => {
    setOnSave(() => handleSave);
  }, [handleSave, setOnSave]);

  // hook to update the form when the modalData changes
  useEffect(() => {
    if (mode === 'UPDATE' || mode === 'AI_FOUND') {
      setForm({
        image: baseModalContext.selectedCharacter?.image ?? '',
        name: baseModalContext.selectedCharacter?.name ?? '',
      });
    }
  }, [baseModalContext.selectedCharacter, mode]);

  const handleInputChange = ({ name, value }: { name: string; value: string }) => {
    if (baseModalContext.mode === 'NEW') {
      setForm(prevForm => ({
        ...prevForm,
        [name]: value,
      }));
    } else {
      baseModalContext.setSelectedCharacter(prevChar => ({
        ...prevChar,
        [name]: value,
      }) as TCharacter);
    }
  };

  return (
    <>
      <div className='modal-container h-[70vh] w-full flex flex-col'>
        {/* Header Section */}
        <div className='sticky top-0 z-40 p-4'>
          <div className='flex flex-col space-y-4'>
            {/* Name Input / Display and Subscription Switch */}
            <div className="w-full grid grid-cols-3 items-center gap-4">
              {/* First column - switch for AI_FOUND mode, otherwise empty */}
              <div className="flex items-center">
                {baseModalContext.mode === "AI_FOUND" && !subscription && (
                  <div className='flex items-center'>
                    <Switch
                      checked={showOnlyCurrentSubscriptionSwitch}
                      onChange={setShowOnlyCurrentSubscriptionSwitch}
                      className={`${showOnlyCurrentSubscriptionSwitch ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full mr-2`}
                    >
                      <span className={`${showOnlyCurrentSubscriptionSwitch ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                    </Switch>
                    {showOnlyCurrentSubscriptionSwitch ? (
                      <StarIcon className='w-5 h-5 text-yellow-500' />
                    ) : (
                      <StarIcon className='w-5 h-5 text-black' stroke="currentColor" fill="none" />
                    )}
                    <span className='ml-2 text-sm text-gray-600 whitespace-nowrap'>
                      {showOnlyCurrentSubscriptionSwitch ? 'All' : 'In your plan'}
                    </span>
                  </div>
                )}
              </div>

              {/* Second column - input field for NEW and UPDATE modes, otherwise empty */}
              <div className="flex justify-center">
                {(baseModalContext.mode === "NEW" || baseModalContext.mode === "UPDATE") && (
                  <input
                    type='text'
                    name='name'
                    id='name'
                    placeholder='Enter name'
                    className='w-60 h-10 uppercase text-center bg-neutral-300 rounded font-semibold text-neutral-900 placeholder-neutral-500 focus:ring-0'
                    value={baseModalContext.mode === 'NEW' ? form.name : baseModalContext.selectedCharacter?.name}
                    onChange={(event) => handleInputChange({ name: 'name', value: event.target.value })}
                  />
                )}
              </div>

              {/* Third column - switch for NEW and UPDATE modes, otherwise empty */}
              <div className="flex items-center justify-start">
                {(baseModalContext.mode === "NEW" || baseModalContext.mode === "UPDATE") && !subscription && (
                  <div className='flex items-center'>
                    <Switch
                      checked={showOnlyCurrentSubscriptionSwitch}
                      onChange={setShowOnlyCurrentSubscriptionSwitch}
                      className={`${showOnlyCurrentSubscriptionSwitch ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full mr-2`}
                    >
                      <span className={`${showOnlyCurrentSubscriptionSwitch ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                    </Switch>
                    {showOnlyCurrentSubscriptionSwitch ? (
                      <StarIcon className='w-5 h-5 text-yellow-500' />
                    ) : (
                      <StarIcon className='w-5 h-5 text-black' stroke="currentColor" fill="none" />
                    )}
                    <span className='ml-2 text-sm text-gray-600 whitespace-nowrap'>
                      {showOnlyCurrentSubscriptionSwitch ? 'All' : 'In your plan'}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {/* Filter Buttons */}
            <div className="flex flex-wrap gap-2">
              {genders.map((gender) => (
                <FilterButton
                  key={gender}
                  label={gender}
                  active={activeFilters.gender.includes(gender)}
                  onClick={() => toggleFilter("gender", gender)}
                />
              ))}
              {ageRanges.map((age) => (
                <FilterButton
                  key={age}
                  label={age}
                  active={activeFilters.age.includes(age)}
                  onClick={() => toggleFilter("age", age)}
                />
              ))}
              {ethnicities.map((ethnicity) => (
                <FilterButton
                  key={ethnicity.label}
                  label={ethnicity.label}
                  active={activeFilters.ethnicity.includes(ethnicity.label)}
                  onClick={() => toggleFilter("ethnicity", ethnicity.label)}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Main Content Section */}
        <div className='flex-1 overflow-y-auto p-4'>
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4'>
            {(subscription || showOnlyCurrentSubscriptionSwitch
              ? filteredCharacters
              : filteredCharacters.filter((character) => freeTierCharacterIds.includes(character?.id))
            )
              .filter((character) => baseModalContext.mode === 'NEW' ? !baseModalContext?.alreadyUsedCharacterImages.includes(character.img) : true)
              .map((character) => (
                <div
                  key={character?.id}
                  onClick={() => {
                    const newImage = character.img || '';
                    console.log('New actor selected:', newImage);
                    if (baseModalContext.mode === 'NEW') {
                      setForm(prevForm => ({
                        ...prevForm,
                        image: newImage,
                      }));
                    } else {
                      baseModalContext.setSelectedCharacter(prevChar => ({
                        ...prevChar,
                        image: newImage,
                      } as TCharacter));
                    }
                  }}
                  className='relative w-full max-w-[200px] aspect-square flex items-center justify-center'
                >
                  <img
                    className={`cursor-pointer rounded object-cover w-full h-full ${(baseModalContext.mode === 'NEW' && character.img === form.image) ||
                      (baseModalContext.mode !== 'NEW' && character.img === baseModalContext.selectedCharacter?.image)
                      ? 'border-4 border-blue-500'
                      : ''
                      }`}
                    src={`${character.image}`}
                    alt=''
                  />
                  {!freeTierCharacterIds.includes(character?.id) && !subscription && (
                    <div className='absolute w-full h-full bg-neutral-600 bg-opacity-50 rounded'>
                      <div className='absolute bottom-0 right-0 text-white px-2 py-1 rounded rounded-tr-md'>
                        <StarIcon className='w-[27px] text-yellow-500' />
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>

      {errorMessage && (
        <div className='text-sm text-red-500 flex justify-end'>{errorMessage}</div>
      )}
    </>
  );
};

export default CharacterSelector;
