import { HttpClient } from "../client";

import { ApiActionParams } from "../../types/api";
import { TeamRto } from "../../models/team/teamRto.model";

export class Team extends HttpClient {
  public constructor(url: string) {
    super(url);
  }

  private readonly prefix: string = "teams";

  public createTeam = ({
                         label,
                         domain,
                         description
                       }: {
    label: string;
    domain: string;
    description: string;
  }) => {
    return this.instance.post<{ status: any; data: any }>(`/${this.prefix}`, {
      label,
      domain,
      description
    });
  };

  public getTeam = ({ id }: { id: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `/${this.prefix}/${id}`
    );
  };

  public getTeams = () => {
    return this.instance.get<{ status: any; data: any }>(`/${this.prefix}`);
  };

  public deleteTeam = ({ id }: { id: string }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `/${this.prefix}/${id}`
    );
  };

  public inviteTeamMember = ({
                               teamId,
                               email
                             }: {
    teamId: string;
    email: string;
  }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/${teamId}/invite`,
      { email }
    );
  };
}
