import { useState } from "react";
import { toast } from "react-toastify";
import { sendEventToGoogleTagManager } from "../../../../../core/utils/googleTagManager";
import wizard from "../../../../../assets/images/wizard.webp";
import manual from "../../../../../assets/images/manual.webp";
import { CustomLoadingSpinner } from "../../../loading/CustomLoadingSpinner";
import { useBaseModalContext } from "../../base-modal/BaseModalContext";
import { useSelector } from "react-redux";
import { useAppContext } from "../../../../../core/utils/AppContext";
import { useNavigate } from "react-router";
import useUserPlan from "../../../../../core/utils/creditCost";

interface IRequiredProps { }

interface IOptionalProps {
  addStory?: Function;
}

interface IProps extends IRequiredProps, IOptionalProps { }

export default function CreateProject({ addStory }: IProps) {
  const { userId, availableCredits, account } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits,
    account: state.app?.account,
  }));
  const navigate = useNavigate();

  const [projectCreationType, setProjectCreationType] = useState<"ai" | "manual" | undefined>();
  const { setIsOpen, openModal } = useBaseModalContext();
  const { addProject } = useAppContext();
  const [isCreateProjectRequestInProgress, setIsCreateProjectRequestInProgress] = useState<boolean>(false);

  const isAdmin = process.env.REACT_APP_ADMIN === 'true';
  const { isFree, isPlan, costs } = useUserPlan();
  const isFreeTier = !isAdmin && isFree;

  const [form, setForm] = useState<{
    label: string;
    story: string;
    description: string;
  }>({ label: "", story: "", description: "" });

  const createStoryRequest = async ({ type }: { type: "ai" | "manual" }) => {
    setIsCreateProjectRequestInProgress(true);

    console.log({ type })


    if (type === "ai" && isFreeTier) {
      const availableWizardCredits = account?.stats?.availableWizardCredits ?? 1;
      if (availableWizardCredits === 0 && !process.env.ADMIN) {
        openModal({ mode: "SUBSCRIBE", data: {}, trigger: "AI_WIZARD" });
        return;
      }
    };

    try {
      setIsOpen(false);

      const result: any = await addProject({ newProjectLabel: form.label === "" ? "New story" : form.label, newProjectStory: form.story, newProjectDescription: form.description, newProjectType: type });

      sendEventToGoogleTagManager('create_project_success', {
        event_category: 'Project Management',
        event_action: 'Create',
        event_label: 'Success',
        value: true
      }, userId, availableCredits);
      navigate(`/stories/${result.data.id}/board`);
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
      sendEventToGoogleTagManager('create_project_fail', {
        event_category: 'Project Management',
        event_action: 'Create',
        event_label: 'Success',
        value: false
      }, userId, availableCredits);
    }
    setIsCreateProjectRequestInProgress(false);
  };


  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value
    }));
  };

  return (
    <div className="mx-4 sm:w-[500px] ">
      <div className="w-full text-neutral-900 justify-center">
        <div className="text-xl font-semibold">
          New Story
        </div>
        {!projectCreationType && <div className="flex flex-col mt-4 justify-around gap-y-2 sm:flex-row sm:gap-x-2 sm:py-4">
          <button className={"sm:w-[250px] sm:h-[300px] relative rounded flex flex-col items-center overflow-hidden transition-transform duration-300 lg:hover:scale-105"} onClick={() => createStoryRequest({ type: "ai" })}>
            <img src={wizard} className={"h-[190px] w-[190px] sm:h-[250px] sm:w-[250px]"} alt="wizard image" />
          </button>
          <button className={"sm:w-[250px] sm:h-[250px] hover:bg-opacity-30 rounded border border-neutral-500 flex flex-col items-center overflow-hidden transition-transform duration-300 transform-gpu lg:hover:scale-105"} onClick={() => setProjectCreationType("manual")}>
            <img src={manual} className={"h-[190px] w-[190px] sm:h-[250px] sm:w-[250px] "} alt="manual image" />
            <h1 className={"font-semibold mt-4"}>Manual</h1>
          </button>
        </div>}
        {projectCreationType === "manual" && (
          <>
            <div className="flex flex-1 gap-4 my-4">
              <div
                className="w-full rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-neutral-500 focus-within:ring-2 focus-within:ring-neutral-600">
                <label
                  htmlFor="name"
                  className="block text-xs font-medium"
                >
                  Title
                </label>
                <input
                  type="text"
                  name="label"
                  id="label"
                  className="block w-full border-0 p-0 bg-transparent placeholder:text-neutral-500 focus:ring-0 sm:text-sm sm:leading-6"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div
              className="w-full rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-neutral-500 focus-within:ring-2 focus-within:ring-neutral-600">
              <label
                htmlFor="description"
                className="block text-xs font-medium"
              >
                Description
              </label>
              <input
                type="text"
                name="description"
                id="description"
                className="block w-full border-0 p-0 bg-transparent placeholder:text-neutral-700 focus:ring-0 sm:text-sm sm:leading-6"
                onChange={handleInputChange}
              />
            </div>
          </>
        )}
      </div>
      <div className="flex flex-row gap-4 justify-between items-center mt-5 sm:mt-6">
        {projectCreationType && <button
          type="button"
          className="text-neutral-700 hover:bg-neutral-300 border border-neutral-700 rounded py-2 px-4"
          onClick={() =>
            setProjectCreationType(undefined)
          }
        >
          Back
        </button>}
        <div className="flex gap-x-4 items-center">
          <button
            type="button"
            className="text-neutral-700 hover:bg-neutral-300 border border-neutral-700 rounded py-2 px-4"
            onClick={() =>
              setIsOpen(false)
            }
          >
            Cancel
          </button>
          {projectCreationType &&
            <div className="relative">
              <button
                type="button"
                className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 ml-2 h-full"
                onClick={() => createStoryRequest({ type: "manual" })}
              >
                Create
              </button>
              {isCreateProjectRequestInProgress && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur rounded-md">
                  <CustomLoadingSpinner size={24} />
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
}
