import { useState } from "react";
import { Dialog } from "@headlessui/react";
import store, { TReduxStore } from "../../../../../core/redux/store";
import { setModal } from "../../../../../core/redux/actions/modalManagerActions";
import { ENUM_MODAL_TYPE } from "../../../../../core/types/modal.type";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../core/hooks/auth/useAuth";
import { useBaseModalContext } from "../../base-modal/BaseModalContext";
const storyBoardsApiClient = require("../../../../../core/config/api").storyBoardsApiClient;

interface IRequiredProps {
}

interface IOptionalProps {
}

interface IProps extends IRequiredProps, IOptionalProps {
}

export default function FirstLoginEmailConfirm({ }: IProps) {

  const [isConfirmChecked, setIsConfirmChecked] = useState(true)

  const auth: any = useAuth();

  const handleMailchimpRequest = async () => {
    const userEmail = auth[0]?.data?.email;

    try {
      if (isConfirmChecked) {
        const result = await storyBoardsApiClient.user.subscribe({ userEmail, userId: auth[0]?.data?.id });
        console.log({ result });
      } else {
        const result = await storyBoardsApiClient.user.setFirstLogin({ userId: auth[0]?.data?.id, firstLogin: false });
        console.log({ result })
      };
    } catch (error) {
      console.log({ error });
    }

  }

  const { setMode } = useBaseModalContext();

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const result = await storyBoardsApiClient.user.setFirstLogin({ userId: auth[0]?.data?.id, firstLogin: false });
    console.log({ result })

    setMode("GET_STARTED")

    // handleMailchimpRequest().catch((error) => {
    //   console.error("Mailchimp request failed", error);
    // });
  };

  return (
    <>
      <div className="sm:flex justify-center py-20">
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            className="text-lg font-semibold leading-6 text-neutral-900 text-center "
          >
            Welcome to story-boards.ai!
          </h3>
          <div className="mt-8 flex flex-col items-center gap-2">
            <p className="text-sm text-neutral-900">Stay informed with the latest features and updates.<br />Would you like to receive occasional emails from us?</p>
            <form onSubmit={e => e.preventDefault()}>
              <div className="flex items-center gap-x-2 my-4">
                <input type="checkbox" id="confirm" checked={isConfirmChecked} onChange={() => setIsConfirmChecked(!isConfirmChecked)} />
                <label className="text-sm text-neutral-900" htmlFor="confirm">Yes, keep me updated!</label>
              </div>
            </form>
            <p className="text-sm text-neutral-900">You can change your mind at any time in your account settings.</p>
            <p className="text-sm text-neutral-900">Thanks for joining us!</p>
            <button className="mt-4 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-neutral-100 shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
              onClick={handleFormSubmit}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
}