import { SET_ACCOUNT } from "../constants/accountConstants";

export type TAccountStore = {
  subscription: any;
  firstLogin: any;
  stats: any;
  id: any;
  recentTeam: string;
  isAdmin: boolean;
  clientId?: string;
};

export const setAccount = ({ data }: any) => {
  return async (dispatch: any) => {
    dispatch({ type: SET_ACCOUNT, data });
  };
};
