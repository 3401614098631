import { Fragment, useEffect, useState } from "react";
import { storyBoardsApiClient } from "../../../core/config/api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { ArrowDownTrayIcon, ArrowPathIcon, ChevronDownIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { changeUrl } from "../../../core/utils/changeUrl";
import { useSelector } from "react-redux";
import { TReduxStore } from "../../../core/redux/store";
import { CustomLoadingSpinner } from "../../components/loading/CustomLoadingSpinner";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { useTour } from "@reactour/tour";
import { useTourContext } from "../../../core/utils/TourContext";
import { useBaseModalContext } from "../../components/modals/base-modal/BaseModalContext";
import VideoScreen from "../video-screen";
import { useAppContext } from "../../../core/utils/AppContext";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";

type Shot = {
  angle: string;
  createdAt: Date;
  id: string;
  index: number;
  promptDescription: string;
  shotDescription: string;
  updatedAt: Date;
  selectedImage: string;
};

type Scene = {
  shots: Shot[];
};

export default function ExportScreen() {
  const { userId, availableCredits } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits
  }));
  const [scenes, setScenes] = useState<Scene[]>([]);
  const navigate = useNavigate();
  const { storyId } = useParams();

  const story = useSelector((state: TReduxStore) => state.app.story);
  const appContext = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);

  const [numPages, setNumPages] = useState<number>();

  const [pdfLoaded, setPdfLoaded] = useState(false);

  const [isVideoView, setIsVideoView] = useState(false);

  const [pdfNeedsRefresh, setPdfNeedsRefresh] = useState<boolean>(false);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPdfLoaded(true);
  }

  const retrieveAllScenesRequest = async () => {
    setIsLoading(true);
    try {
      const result = await storyBoardsApiClient.scene.retrieveAll({
        storyId: storyId ?? ""
      });

      if (result.status === 200) {
        setScenes(result.data.scenes);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
      console.log("Story received");
    }
  };

  useEffect(() => {
    retrieveAllScenesRequest();
    sendEventToGoogleTagManager("storyboard_preview_loaded", {
      story_id: story?.id,
      outcome: "success",
    }, userId, availableCredits);
  }, []);

  const copyPreviewUrl = async () => {
    const url = changeUrl(window.location.href, "preview");
    await navigator.clipboard.writeText(url);
    toast.info("Copied preview url to the clipboard");
  };

  const [orientationState, setOrientationState] = useState<"landscape" | "portrait">("landscape");
  const [pageSizeState, setPageSizeState] = useState<"A4" | "LETTER">("A4");

  const [pdfUrlState, setPdfUrlState] = useState<string>("");

  const handlePdfDownload = async () => {
    setIsLoading(true);
    try {
      const result = await storyBoardsApiClient.story.createExportPdf({
        storyId: storyId ?? "",
        orientation: orientationState,
        pageSize: pageSizeState,
      });

      if (result.status === 201) {
        const blob = await fetch(result.data.Location).then((res) => res.blob());
        const blobUrl = window.URL.createObjectURL(blob);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result as string;
          // Save to context instead of localStorage
          appContext.setPdfDataMap(prevMap => ({
            ...prevMap,
            [storyId ?? '']: base64data
          }));
          setPdfBlobUrl(blobUrl);
        };
        setPdfNeedsRefresh(false);
        setPdfUrlState(result.data.Location ?? "");
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (!storyId) {
      console.error('No storyId provided');
      return;
    }
    const checkAndLoadPdf = async () => {
      setIsLoading(true);
      try {
        // First, check if PDF is in context
        if (appContext.pdfDataMap[storyId ?? '']) {
          const pdfData = appContext.pdfDataMap[storyId ?? '']
          const response = await fetch(pdfData);
          const blob = await response.blob();
          const blobUrl = window.URL.createObjectURL(blob);
          setPdfBlobUrl(blobUrl);
          setPdfUrlState(pdfData);
        } else {
          // Construct the AWS S3 URL
          const awsUrl = `https://storyboard-user-output-files.s3.us-west-1.amazonaws.com/pdf/${storyId}.pdf`;

          // Check if the PDF exists in AWS
          try {
            const response = await fetch(awsUrl, { method: 'HEAD' });
            if (response.ok) {
              // PDF exists in AWS, load it
              setPdfUrlState(awsUrl);
              const blob = await (await fetch(awsUrl)).blob();
              const blobUrl = window.URL.createObjectURL(blob);
              setPdfBlobUrl(blobUrl);

              // Save to context
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                const base64data = reader.result as string;
                appContext.setPdfDataMap(prevMap => ({
                  ...prevMap,
                  [storyId]: base64data
                }));
              }
            } else {
              // PDF doesn't exist in AWS, create a new one
              if (scenes.length !== 0) {
                await handlePdfDownload();
              }
            }
          } catch (error) {
            console.error("Error checking PDF in AWS:", error);
            // If there's an error checking, we'll try to create a new PDF
            if (scenes.length !== 0) {
              await handlePdfDownload();
            }
          }
        }
      } catch (error) {
        console.error("Error in checkAndLoadPdf:", error);
        toast.error("Error loading PDF. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    checkAndLoadPdf();
  }, [appContext.pdfDataMap, scenes, orientationState, pageSizeState, storyId]);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [pdfBlobUrl, setPdfBlobUrl] = useState<null | string>(null);

  useEffect(() => {
    if (pdfUrlState !== "") {
      fetch(pdfUrlState)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          setPdfBlobUrl(blobUrl);
        })
        .catch(error => console.error('Error fetching PDF:', error));

      return () => {
        if (pdfBlobUrl) {
          window.URL.revokeObjectURL(pdfBlobUrl);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfUrlState]);

  const { setSteps, setIsOpen, currentStep, setCurrentStep } = useTour();
  const { setTourRunning, tourRunning, setTourStartClicked, tourStartClicked } = useTourContext();

  useEffect(() => {
    if (pdfLoaded && setSteps && localStorage.getItem("edit-button-step") !== "viewed") {
      const startTour = () => {
        setSteps([
          {
            selector: '#edit-button',
            content: (
              <div>
                <p>Click here to edit the storyboard. <br />Give it a try now.</p>
              </div>
            ),
            position: 'left',
            stepInteraction: true,
            styles: {
              close: (base) => ({
                ...base,
                display: 'none',
              }),
              arrow: (base) => ({
                ...base,
                display: 'none',
              }),
            },
          },
        ]);
        setCurrentStep(0);
        setTourRunning(true);
        setIsOpen(true);
        localStorage.setItem("edit-button-step", "viewed");
      };

      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setTimeout(() => {
            startTour();
            observer.disconnect();
          }, 500); // 0.5 second delay after the element is fully in view
        }
      }, { threshold: 1.0 });

      const timer = setTimeout(() => {
        const editButton = document.querySelector('#edit-button');
        if (editButton) {
          editButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
          observer.observe(editButton);
        }
      }, 5000); // 5 second delay

      // Cleanup timeout and observer on component unmount or when dependencies change
      return () => {
        clearTimeout(timer);
        observer.disconnect();
      };
    }
  }, [pdfLoaded, setSteps, scenes, currentStep]);

  const { setIsOpen: setModalIsOpen, setMode } = useBaseModalContext();

  const onDownloadVideo = () => {
    setMode("VIDEO_DOWNLOAD");
    setModalIsOpen(true);
  }

  return (
    <div>
      {/* Overlay and Spinner */}
      {(isLoading) && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-10">
          {/* Overlay */}
          <div className="w-full h-full absolute top-0 left-0 bg-neutral-800 opacity-80 backdrop-blur"></div>

          {/* Loading message and spinner container */}
          <div className="text-center z-20">
            <CustomLoadingSpinner size={80} />

            {/* Ensure the text is visible on all backgrounds */}
            <p className="text-neutral-100 mt-4 text-lg" style={{ zIndex: 51 }}>
              {
                isGeneratingVideo ? "Generating your video..." : "Getting your storyboard ready..."
              }
            </p>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center" style={{ paddingTop: "2%", paddingBottom: "2%", height: "100vh" }}>
        <div className="flex justify-between w-screen px-4 lg:px-16 w-full gap-y-4 mb-8">
          {/* Left Column: Options Menus */}
          <div className="flex lg:gap-x-8 gap-x-2 flex-1">
            {(!isVideoView) && (
              <>
                <Menu as="div" className="relative">
                  <Menu.Button id="page-orientation" className="flex items-center py-2">
                    <span className="text-white text-sm">{orientationState}</span>
                    <ChevronDownIcon className="ml-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute left-0 mt-2.5 w-auto min-w-[200px] rounded-md bg-neutral-100 bg-opacity-80 backdrop-blur py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none z-50"
                    >
                      {["landscape", "portrait"]?.map((orientation) => (
                        <Menu.Item key={orientation}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-neutral-200 text-gray-600" : "",
                                "w-full flex justify-between items-center block px-3 py-2 text-sm leading-6 text-neutral-600"
                              )}
                              onClick={() => setOrientationState(orientation === "portrait" ? "portrait" : "landscape")}
                            >
                              <p>{orientation}</p>
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>

                <Menu as="div" className="relative">
                  <Menu.Button id="page-size" className="flex items-center py-2">
                    <span className="text-white text-sm">{pageSizeState === "A4" ? "A4" : "letter"}</span>
                    <ChevronDownIcon className="ml-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute left-0 mt-2.5 w-auto min-w-[200px] rounded-md bg-neutral-100 bg-opacity-80 backdrop-blur py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none z-50"
                    >
                      {["A4", "LETTER"]?.map((pageSize) => (
                        <Menu.Item key={pageSize}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-neutral-200 text-gray-600" : "",
                                "w-full flex justify-between items-center block px-3 py-2 text-sm leading-6 text-neutral-600"
                              )}
                              onClick={() => setPageSizeState(pageSize === "A4" ? "A4" : "LETTER")}
                            >
                              <p>{pageSize === "A4" ? "A4" : "letter"}</p>
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </>
            )}
          </div>

          {/* Middle Column: Centered Button Group */}
          <div className="flex justify-center flex-1">
            <div className="inline-flex rounded-md shadow-sm">
              <button
                className={`text-neutral-200 hover:text-neutral-200 bg-none hover:bg-neutral-700 py-2 px-4 text-sm font-semibold rounded-l-md border border-1 border-neutral-300 ${isVideoView ? "" : "bg-neutral-300 text-neutral-800"}`}
                onClick={() => setIsVideoView(false)}
              >
                PDF
              </button>
              <button
                className={`text-neutral-200 hover:text-neutral-200 bg-none hover:bg-neutral-700 py-2 px-4 text-sm font-semibold rounded-r-md border border-1 border-neutral-300 ${isVideoView ? "bg-neutral-300 text-neutral-800" : ""}`}
                onClick={() => setIsVideoView(true)}
              >
                Video
              </button>
            </div>
          </div>

          {/* Right Column: Action Buttons */}
          <div className="flex justify-end flex-1">
            <div className="flex space-x-2">
              {!isVideoView && (
                <button
                  className="text-white hover:text-neutral-200 bg-sky-500 hover:bg-sky-600 py-2 rounded font-semibold px-2 text-sm flex items-center"
                  onClick={() => {
                    setPdfNeedsRefresh(true);
                    handlePdfDownload();
                  }}
                >
                  <ArrowPathIcon className="w-5 h-5 mx-1" />
                </button>
              )}
              <button
                onClick={() => navigate(`/stories/${storyId}/board`, { state: { viewMode: "preview" } })}
                className="text-white hover:text-neutral-200 bg-sky-500 hover:bg-sky-600 py-2 rounded font-semibold px-4 text-sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>

        <div className="relative overflow-auto text-neutral-200 mb-32 relative flex justify-center">
          {isVideoView ? (
            <VideoScreen onDownloadVideo={onDownloadVideo} />
          ) : (
            <>
              {pdfBlobUrl && (
                <a
                  href={pdfBlobUrl}
                  download={`${story?.label ?? "story-name"}.pdf`}
                  className="absolute top-4 right-4 z-10 bg-sky-500 hover:bg-sky-600 text-white rounded p-2 transition-all duration-200"
                  onClick={() =>
                    sendEventToGoogleTagManager(
                      "pdf_download",
                      {
                        story_management: "Story Management",
                        story_id: story?.id,
                        outcome: "success",
                      },
                      userId,
                      availableCredits
                    )
                  }
                >
                  <ArrowDownTrayIcon className="w-6 h-6" />
                </a>
              )}
              <Document
                file={pdfUrlState}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error) => {
                  console.error("Error loading PDF:", error);
                  console.error("Error details:", error.message);
                  toast.error(`Error loading PDF: ${error.message}`);
                }}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page
                      key={page}
                      pageNumber={page}
                      className={"mb-4"}
                      width={viewportWidth > 1000 ? 1000 : viewportWidth * 0.8}
                    />
                  ))}
              </Document>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
