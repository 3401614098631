import React, { useEffect } from "react";
import { TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import { characters } from "../../../assets/characters/characters";
import { storyBoardsApiClient } from "../../../core/config/api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import UniversalTippy from "../universal-tippy/UniversalTippy";

export type TCharacter = {
  id: string;
  name: string;
  description: string;
  image: string;
  scene?: number[];
};

interface IRequiredProps {
  character: TCharacter;
}

interface IOptionalProps {
  onClick?: Function;
  removeCharacterFromState?: Function;
  mode?: 'dark' | 'light';
  updateCharacterInState?: (character: TCharacter) => void;
}

interface IProps extends IRequiredProps, IOptionalProps { }

export default function Character({ character, onClick, removeCharacterFromState, mode = 'dark', updateCharacterInState }: IProps) {
  const { storyId } = useParams();

  const deleteCharacterRequest = async ({ id, event }: { id: string, event: any; }) => {
    event.stopPropagation();
    try {
      const result = await storyBoardsApiClient.character.deleteCharacter({
        id,
        storyId: storyId ?? ""
      });
      if (result.status === 200) {
        removeCharacterFromState && removeCharacterFromState({ id });
      } else {
        toast.error("Something went wrong with deleting a character");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (updateCharacterInState) {
      updateCharacterInState(character);
    }
  }, [character, updateCharacterInState]);

  const bgColor = mode === 'dark' ? 'bg-none' : 'bg-none';
  const textColor = mode === 'dark' ? 'text-gray-200' : 'text-gray-800';
  const descriptionColor = mode === 'dark' ? 'text-gray-400' : 'text-gray-600';
  const placeholderGradient = mode === 'dark'
    ? 'from-gray-700 to-gray-600'
    : 'from-gray-200 to-gray-300';
  const placeholderIconBg = mode === 'dark' ? 'bg-gray-800' : 'bg-white';
  const placeholderIconColor = mode === 'dark' ? 'text-gray-500' : 'text-gray-400';
  const deleteButtonBg = mode === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300';
  const deleteIconColor = mode === 'dark' ? 'text-gray-300' : 'text-gray-600';

  return (
    <div className={`relative ${bgColor} w-full flex flex-col`}>
      <div className="relative group aspect-square w-full">
        {character?.image ? (
          <img
            className="object-cover rounded-t-md h-full w-full"
            src={characters.find((_character) => _character.img === character?.image)?.image}
            alt=""
          />
        ) : (
          <div className={`flex items-center justify-center h-full w-full bg-gradient-to-br ${placeholderGradient} rounded-t-md`}>
            <div className={`${placeholderIconBg} p-3 rounded-full`}>
              <UserIcon className={`h-12 w-12 ${placeholderIconColor}`} aria-hidden="true" />
            </div>
          </div>
        )}
        {mode === 'light' && (
          <button
            className={`absolute top-2 right-2 ${deleteButtonBg} rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200`}
            onClick={(event) => deleteCharacterRequest({ event, id: character?.id })}
          >
            <TrashIcon className={`${deleteIconColor} w-4 h-4`} />
          </button>)}
      </div>
      <div className={`text-center p-2 ${character?.description ? 'min-h-[4rem]' : ''}`}>
        <p className={`text-sm ${textColor} font-semibold m-0 uppercase`}>{character?.name}</p>
        {character?.description && (
          <UniversalTippy content={character.description}>
            <p className={`text-xs ${descriptionColor} m-0 mt-1 line-clamp-2`}>{character.description}</p>
          </UniversalTippy>
        )}
      </div>
    </div>
  );
}
