import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Tabs from "../../components/tabs";
import { useNavigate, useParams } from "react-router";
import store from "../../../core/redux/store";
import { getTeams } from "../../../core/redux/actions/teamsActions";
import { getStory } from "../../../core/redux/actions/storiesActions";
import Navbar from "../../../app/components/navbar/Navbar";

type Props = {};

export default function StoryLayout({ }: Props) {
  const navigate = useNavigate();

  const { storyId } = useParams();

  useEffect(() => {
    //@ts-ignore
    store.dispatch(getStory({ id: storyId }));
  }, [storyId]);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Navbar />
      <div id="app-layout" className="flex-grow overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
}
