import { HttpClient } from "../client";

export class Scene extends HttpClient {
  private readonly prefix: string = "scenes";

  public constructor(url: string) {
    super(url);
  }

  public create = ({
                     storyId,
                     index,
                     content,
                     description
                   }: {
    storyId: string;
    index: number;
    content?: string;
    description?: string;
  }) => {
    console.log({
      storyId,
      index,
      content,
      description
    });
    return this.instance.post<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}`,
      {
        index,
        content,
        description
      }
    );
  };

  public retrieve = ({ id, storyId }: { id: string; storyId: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`
    );
  };

  public retrieveAll = ({ storyId }: { storyId: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}`
    );
  };

  public update = ({
                     id,
                     storyId,
                     description,
                     content
                   }: {
    id: string;
    storyId: string;
    description?: string;
    content?: string;
  }) => {
    return this.instance.patch<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`,
      {
        description,
        content
      }
    );
  };

  public remove = ({ id, storyId }: { id: string; storyId: string }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`
    );
  };

  public reorder = ({ storyId, newIndex, shotId }: {
    storyId: string,
    newIndex: number,
    shotId: string
  }) => {
    return this.instance.post<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/reorder-shots`, {
        newIndex, shotId
      }
    );
  };
}
