import React from 'react';
import { StepType, Position } from '@reactour/tour'; // Import the necessary types
import * as ReactourTypes from "@reactour/tour";
const addShotImage = process.env.PUBLIC_URL + '/tour/add-shot-2.jpg';
const galleryImage = process.env.PUBLIC_URL + '/tour/gallery.jpg';
const charactersImage = process.env.PUBLIC_URL + '/tour/characters.jpg';
const stylesImage = process.env.PUBLIC_URL + '/tour/styles.jpg';
const addSceneImage = process.env.PUBLIC_URL + '/tour/addscene.jpg';

export const appSteps: StepType[] = [
  {
    selector: '#timeline-wrapper',
    content: (
      <div>
        <p>Here's your <strong>Story Timeline</strong>, the canvas where your story unfolds.</p>
        <br /><br />
        <p>
          Add new Scenes and Shots to build you story.
        </p>
        <br /><br />
        <p style={{ fontSize: '0.8em' }}>
          (You can use the arrow key on your keyboard to navigate through this tour)
        </p>
      </div>
    ),
    position: 'center',
    styles: {
      close: (base) => ({
        ...base,
        display: 'none',
      })
    },
  },
  {
    selector: '#timeline-wrapper',
    content: () => (
      <div>
        <p>Expand <strong>scenes</strong> to see your <strong>shots</strong>.
          <br />
          Add descriptions to your shots, that will appear below the image in your storyboard.</p>

      </div>
    ),
    position: 'center',
    styles: {
      close: (base) => ({
        ...base,
        display: 'none',
      })
    },
  },
  {
    selector: '#style-button',
    content: () => (
      <div>
        <p>Give your storyboard a unique flair. Tap <strong>styles</strong> to select a visual style that complements your story's mood.</p>
        <img src={stylesImage} alt="Styles Section" style={{ marginTop: '10px', maxWidth: '100%', height: 'auto' }} />
      </div>
    ),
    position: 'bottom',
    padding: 20,
    styles: {
      close: (base) => ({
        ...base,
        display: 'none',
      })
    },
  },
  {
    selector: '#character-button',
    content: () => (
      <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
        <p>Within the <strong>CAST</strong> section, you can create new characters or edit existing ones.<br /><br /> When creating images, remember to use your characters' names in <strong>CAPITAL LETTERS</strong> in the prompt to include them in the image. </p>
        <img src={charactersImage} alt="Cast Section Example" style={{ marginTop: '10px', maxWidth: '100%', height: 'auto' }} />
        <p>This simple yet effective method helps the AI accurately depict the cast members in your story.</p>
      </div>
    ),
    position: 'bottom',
    padding: 20,
    styles: {
      close: (base) => ({
        ...base,
        display: 'none',
      })
    },
  }, {
    selector: '#edit-button',
    content: () => (
      <div>
        <p>In EDIT mode you can edit the current image with tools like <strong>InPaint</strong>.</p>
      </div>
    ),
    position: 'bottom',
    padding: 20,
    styles: {
      close: (base) => ({
        ...base,
        display: 'none',
      })
    },
  },
  {
    selector: '#image-creator',
    content: () => (
      <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
        <h2 style={{ color: "#4A90E2" }}>Image Creation</h2>
        <p>This is the heart of our storyboarding app. Here, you'll create images for each selected shot, bringing your narrative to vivid life.</p>
        <br /><p>To start, select a <strong>shot</strong> in your timeline.</p>

      </div>
    ),
    position: 'center',
    padding: 10
  },
  {
    selector: '#camera-angle',
    content: () => (
      <div>
        <p>Select a camera angle that best suits your image.</p>
      </div>
    ),
    position: 'left'
  },
  {
    selector: "#image-prompt",
    content: (
      <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
        <h2 style={{ color: "#4A90E2" }}>Crafting Your Image Prompt</h2>
        <p>Let's make your first AI-generated storyboard image.</p>
        <p>Just type in a description of what you'd like to see, like telling a friend a scene from your story. Use this simple formula for best results:</p>
        <blockquote style={{ margin: "20px 0", padding: "15px", background: "#f9f9f9", borderLeft: "5px solid #4A90E2" }}>
          <strong>WHO</strong> (in CAPITALS), wearing <strong>WHAT</strong>, is doing <strong>WHAT</strong>, <strong>WHERE</strong>.
        </blockquote>
      </div>
    ), position: 'center'
  },
  {
    selector: "#image-prompt",
    content: (
      <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
        <p>Here's an example to spark your imagination:</p>
        <blockquote style={{ margin: "20px 0", padding: "15px", background: "#f9f9f9", borderLeft: "5px solid #4A90E2" }}>
          <em>"<strong>JANE</strong>, wearing a spacesuit, is planting a flag on the moon, overlooking Earth."</em></blockquote>
        <p>Feel free to use the names of your characters, and don't worry about getting it perfect on the first try. Give it a shot!</p>
      </div>
    ), position: 'center'
  },
  {
    selector: ".generate-image-button",
    content: (
      <p>Hit <strong>Generate</strong> to bring your scene to life. It may take a moment, but you can keep working on other parts of your story while you wait.</p>
    )
  },
  {
    selector: "#gallery-button",
    content: (
      <div>
        <p>As your story progresses, you'll accumulate many generated images per shot. <br /><br />To revisit these visuals, look to the top left corner of the Main Image.</p>
        <p><br /><br />Click the <strong>Gallery</strong> button to access your previously generated images. </p>
      </div>
    ),
    position: 'center'
  },
  {
    selector: "#switch-mode-toggle",
    content: (
      <p>Get a sneak peek of your storyboard in its current glory on the <strong>Preview Screen</strong>. From here, you can view, print, or share it with anyone.</p>
    )
  },
  {
    selector: "#help-button",
    content: ({ setCurrentStep, setIsOpen }: { setCurrentStep: Function, setIsOpen: Function }) => (
      <div>
        <p>If you ever need to revisit this tour, you can restart it right here. Don't hesitate to refresh your memory!</p>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
          <button
            onClick={() => {
              setCurrentStep(0);
              setIsOpen(false);
            }}
            style={{
              backgroundColor: "#5c6af3",
              color: "white",
              padding: "10px 15px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center"
            }}
          >
            Done
          </button>
        </div>
      </div>
    )
  }
];

export const castSteps = [
  {
    selector: "#base-modal",
    content: (<p>Here you can manage and create your <strong>CAST</strong> by choosing a virtual actor from our list. <br /><br />
      Use the names of your cast in <strong>CAPITALS</strong> in your image prompt. <br /><br />
      Example: <br /><strong><em>PAUL, wearing jeans and a t-shirt, is mowing the lawn, at the pool in the garden of a grand old
        mansion</em></strong></p>)
  }
];

export const styleSteps = [
  {
    selector: "#base-modal",
    content: (<p>Choose the prefered <strong>Styles</strong> for your storyboard.<br />
      <br />We will be adding new styles in the future.</p>)
  }
];

export const foundSteps = [
  {
    selector: "#base-modal",
    content: (
      <div style={{ color: '#333', fontSize: '16px' }}>
        <p>
          We've found these characters in your script.
        </p>
        <p>
          Choose the cast from our list of virtual actors.
        </p>
      </div>
    )
  }
];


export const updateSteps = [
  {
    selector: "#base-modal",
    content: (<p>Here you can manage and create your <strong>Virtual Actors.</strong> <br /><br />
      Use the names of your cast in <strong>CAPITALS</strong> in your image prompt. <br /><br />
      Example: <br /><em>PAUL, wearing jeans and a t-shirt, is mowing the lawn, at the pool in the garden of a grand old
        mansion</em></p>)
  }
];

export const promptSteps = [
  {
    selector: "#image-prompt",
    content: (
      <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
        <h2 style={{ color: "#4A90E2" }}>Craft Your Image Prompt</h2>
        <p>Let's make your first AI-generated storyboard image.</p>
        <p>Just type in a description of what you'd like to see, like telling a friend a scene from your story. Use this simple formula for success:</p>
        <blockquote style={{ margin: "20px 0", padding: "15px", background: "#f9f9f9", borderLeft: "5px solid #4A90E2" }}>
          <strong>WHO</strong> (in CAPITALS), wearing <strong>WHAT</strong>, is doing <strong>WHAT</strong>, <strong>WHERE</strong>.
        </blockquote>
        <p>Here's an example to spark your imagination:</p>
        <p style={{ marginLeft: "20px" }}><em>"<strong>JANE</strong>, wearing a spacesuit, is planting a flag on the moon, overlooking Earth."</em></p>
        <p>Feel free to use the names of your characters, and don't worry about getting it perfect on the first try. The beauty of AI is in collaboration and exploration. Give it a shot!</p>
      </div>
    )
  }
];

export const shotDescriptionSteps = [
  {
    selector: "#base-modal",
    content: (<p>Here you can manage and create your <strong>Virtual Actors.</strong> <br /><br />
      Use the names of your cast in <strong>CAPITALS</strong> in your image prompt. <br /><br />
      Example: <br /><em>PAUL, wearing jeans and a t-shirt, is mowing the lawn, at the pool in the garden of a grand old
        mansion</em></p>)
  }
];

export const sceneTitleSteps = [
  {
    selector: "#scene-edit-menu",
    content: (<p>Here you dsadadscan manage and create your <strong>Virtual Actors.</strong> <br /><br />
      Use the names of your cast in <strong>CAPITALS</strong> in your image prompt. <br /><br />
      Example: <br /><em>PAUL, wearing jeans and a t-shirt, is mowing the lawn, at the pool in the garden of a grand old
        mansion</em></p>)
  }
];

export const sceneDescriptionSteps = [
  {
    selector: "#base-modal",
    content: (<p>Here you can manage and create your <strong>Virtual Actors.</strong> <br /><br />
      Use the names of your cast in <strong>CAPITALS</strong> in your image prompt. <br /><br />
      Example: <br /><em>PAUL, wearing jeans and a t-shirt, is mowing the lawn, at the pool in the garden of a grand old
        mansion</em></p>)
  }
];

export const projectsSteps = [
  {
    selector: ".team-selector",
    content: "This is your private team section. Any stories created here are exclusively visible to you.",
  },
  {
    selector: ".projects-container",
    content: "All your Stories are listed here"
  },
  {
    selector: ".create-project-button",
    content: "To create a new story, simply click the 'Create Story' button.",
  },
  {
    selector: ".project-view-change",
    content: ({ setCurrentStep, setIsOpen }: { setCurrentStep: Function, setIsOpen: Function }) => (
      <div>
        <p>Switch between different story view modes here. You can toggle between grid and list view.</p>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
          <button
            style={{
              backgroundColor: "#5c6af3",
              color: "white",
              padding: "10px 15px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "10px",
              textAlign: "center"
            }}
          >
            Done
          </button>
        </div>
      </div>
    )
  }
];


export const inpaintSteps = [
  {
    selector: "#inpaint-canvas",
    content: (
      <div>
        <p>With InPaint you can edit an existing image, so it fits better your vision</p>
      </div>
    )
  },
  {
    selector: "#inpaint-tools",
    content: (
      <div>
        <p>First, paint in the area that you would like to replace</p>
      </div>
    )
  },
  {
    selector: "#promptbox",
    content: (
      <div>
        <p>As image prompt, describe only what you would like to see in the painted area.<br />It is important, to <strong>NOT</strong> to describe anything else.</p>
      </div>
    )
  },
  {
    selector: "#inpaint-response",
    content: (
      <div>
        <p>Your new image will appear here. Don't forget to save it if you like the results.</p>
      </div>
    )
  },
];

export const viewerSteps = [

];