import React, { Fragment, SetStateAction, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import Navbar from "../../components/navbar";
import { storyBoardsApiClient } from "../../../core/config/api";
import { pricing } from "../../../core/config/pricing";
import { toast } from "react-toastify";
import { CheckCircleIcon, DocumentArrowDownIcon, MinusIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useBaseModalContext } from "../../components/modals/base-modal/BaseModalContext";
import { useSearchParams } from "react-router-dom";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";


type TierName = "Free" | "Indie";

type FeatureTiers = {
  [key in TierName]?: boolean | string;
};

const tiers = [
  {
    name: "Free",
    id: "tier-free",
    href: "#",
    priceMonthly: 0,
    priceMYearly: 0,
    description:
      "Our forever free plan, offering the Indies to bring your vision to life at no cost.",
    mostPopular: false
  },
  {
    name: "Indie",
    id: "tier-indie",
    href: "#",
    priceMonthly: 15,
    priceYearly: 12,
    description:
      "Designed for creators seeking to enhance their narrative depth and reach.",
    mostPopular: true
  }
  /*{
    name: "Pro",
    id: "tier-pro",
    href: "#",
    priceMonthly: 35,
    priceYearly: 30,
    description: "Coming Soon",
    mostPopular: false,
  },*/
];
const sections = [
  {
    name: "Story Creation & Management",
    features: [
      {
        name: "New Stories",
        tiers: {
          Free: "1",
          Indie: "unlimited *",
          Pro: "unlimited *",
          Studio: "unlimited *"
        } as FeatureTiers
      },
      {
        name: "Stories Storage",
        tiers: {
          Free: "1",
          Indie: "unlimited *",
          Pro: "unlimited *",
          Studio: "unlimited *"
        } as FeatureTiers
      }
    ]
  },
  {
    name: "Export Options",
    features: [
      {
        name: "Export Options",
        tiers: {
          Free: "PDF",
          Indie: "PDF and JPG",
          Pro: "PDF and JPG, Google Drive",
          Studio: "PDF and JPG, Google Drive"
        } as FeatureTiers
      }
    ]
  },
  {
    name: "Content Creation Tools",
    features: [
      {
        name: "AI Story Import Wizard",
        tiers: {
          Free: "1",
          Indie: "unlimited *",
          Pro: "unlimited *",
          Studio: "unlimited *"
        } as FeatureTiers
      },
      {
        name: "InPaint",
        tiers: {
          Free: "3",
          Indie: "unlimited *",
          Pro: "unlimited *",
          Studio: "unlimited *"
        } as FeatureTiers
      },
      {
        name: "IMG2IMG",
        tiers: {
          Free: "3",
          Indie: "unlimited *",
          Pro: "unlimited *",
          Studio: "unlimited *"
        } as FeatureTiers
      },
      {
        name: "Story Characters",
        tiers: {
          Free: "4",
          Indie: "unlimited *",
          Pro: "unlimited *",
          Studio: "unlimited *"
        } as FeatureTiers
      },
      {
        name: "Virtual Actors",
        tiers: {
          Free: "4",
          Indie: "all *",
          Pro: "all *",
          Studio: "All Actors"
        } as FeatureTiers
      },
      {
        name: "Storyboard Styles",
        tiers: {
          Free: "3",
          Indie: "all *",
          Pro: "all *",
          Studio: "all *"
        } as FeatureTiers
      },
      {
        name: "AI Credits",
        tiers: {
          Free: "200",
          Indie: "unlimited *",
          Pro: "unlimited *",
          Studio: "1000"
        } as FeatureTiers
      },
      {
        name: "Image Generation Speed",
        tiers: {
          Free: "100%",
          Indie: "150%",
          Pro: "200%",
          Studio: "Instant"
        } as FeatureTiers
      }
    ]
  },
  {
    name: "Credit Cost",
    features: [
      {
        name: "Image Generation",
        tiers: {
          Free: "2",
          Indie: "1",
          Pro: "unlimited",
          Studio: "unlimited"
        } as FeatureTiers
      },
      {
        name: "AI Story Import Wizard",
        tiers: {
          Free: "20",
          Indie: "15",
          Pro: "4",
          Studio: "unlimited"
        } as FeatureTiers
      },
      {
        name: "InPaint",
        tiers: {
          Free: "8",
          Indie: "5",
          Pro: "4",
          Studio: "unlimited"
        } as FeatureTiers
      },
      {
        name: "IMG2IMG",
        tiers: {
          Free: "15",
          Indie: "10",
          Pro: "4",
          Studio: "unlimited"
        } as FeatureTiers
      },
    ]
  },
  {
    name: "Additional Services",
    features: [
      {
        name: "Removed Branding",
        tiers: { Indie: true, Pro: true, Studio: true } as FeatureTiers
      },
      {
        name: "Discord Access",
        tiers: { Indie: true, Pro: true, Studio: true } as FeatureTiers
      },
      {
        name: "Support",
        tiers: { Indie: true, Pro: true, Studio: true } as FeatureTiers
      }
      /*{
        name: "InPaint",
        tiers: { Indie: false, Pro: true } as FeatureTiers,
      },
      {
        name: "Img2Img",
        tiers: { Pro: true, Studio: true } as FeatureTiers,
      },
      {
        name: "Character Positioning",
        tiers: { Studio: "Body Positions, Camera Positions" } as FeatureTiers,
      },
      {
        name: "Collaboration Tools",
        tiers: { Studio: "Teams, Collaboration / Feedback" } as FeatureTiers,
      },*/
    ]
  }
];

const AccountPage = () => {
  const { userId, availableCredits, hasHadTrial, account } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits,
    hasHadTrial: state.app.account?.hasHadTrial,
    account: state.app?.account
  }));

  const [avatar, setAvatar] = useState<File | null>(null);
  const [fullName, setFullName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [productUpdates, setProductUpdates] = useState(false);
  const [subscription, setSubscription] = useState("Free");
  const [changePlan, setChangePlan] = useState<boolean>(false);

  const [transactionId, setTransactionId] = useState<string | null>(null);
  const [transactionPrice, setTransactionPrice] = useState<number | null>(null);
  const [transactionPlan, setTransactionPlan] = useState<string | null>(null);
  const [transactionFrequency, setTransactionFrequency] = useState<string | null>(null);

  const toggleProductUpdates = () => setProductUpdates(!productUpdates);
  const changeSubscription = (e: { target: { value: SetStateAction<string>; }; }) => setSubscription(e.target.value);

  const handleAvatarChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setAvatar(file);
    }
  };

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const [frequency, setFrequency] = useState<"monthly" | "yearly">("monthly");

  const [firstNameInput, setFirstNameInput] = useState<string>(account.firstname);
  const [lastNameInput, setLastNameInput] = useState<string>(account.lastname);

  const [newAvatarFileInput, setNewAvatarFileInput] = useState<File | undefined>(undefined);

  const [websiteInput, setWebsiteInput] = useState<string>("");
  const [aboutInput, setAboutInput] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>(account.email);

  const [isAccountInformationChecked, setIsAccountInformationChecked] = useState<boolean>(false);
  const [isProductUpdatesChecked, setIsProductUpdatesChecked] = useState<boolean>(false);
  const [isOffersChecked, setIsOffersChecked] = useState<boolean>(false);

  const [pushNotificationsState, setPushNotificationsState] = useState<"everything" | "same-as-email" | "no-push">("everything");
  const { setMode, setIsOpen, setModalData, openModal } = useBaseModalContext();

  const downgrade = async () => {
    const result = await storyBoardsApiClient.payment.cancelSubscription();

    if (result.status === 201) {
      // Cancel subscription event
      sendEventToGoogleTagManager("cancel_subscription", {
        user_id: userId,
        subscription: {
          subscription_id: account?.subscription?.subscriptionId,
          cancellation_reason: "User Requested",
        },
      });

      window.location.reload();
    }
  };

  const deleteProfileRequest = async () => {
    try {
      const result = await storyBoardsApiClient.user.deleteProfile();
      sendEventToGoogleTagManager("delete_account", {
        user_id: userId,
        outcome: "success",
      });
      window.location.reload();
    } catch (error) {
      toast.error("Can't delete account");
    }
  };

  const handleSelectPlanClick = async ({ clickedTier }: { clickedTier: number }) => {
    console.log("clicked Tier: ", clickedTier)
    const userTier = account?.subscription?.id ?? "tier-free";
    const selectedTier = tiers[clickedTier];

    if (!clickedTier && !frequency) {
      return;
    }

    const price = frequency === 'monthly' ? selectedTier.priceMonthly : selectedTier.priceYearly;
    const planType = frequency === 'monthly' ? 'Monthly' : 'Yearly';

    console.log("planType:, ", planType)
    console.log("price:, ", price)

    // Begin checkout event
    sendEventToGoogleTagManager(
      "begin_checkout",
      {
        ecommerce: {
          currency: "USD",
          value: price,
          items: [
            {
              item_name: `${selectedTier.name} ${planType} Subscription`,
              item_id: `${selectedTier.id}-${frequency}`,
              price: price,
              quantity: 1,
            },
          ],
        },
      }, userId, availableCredits);

    if (clickedTier === 0 && userTier === "tier-indie") {
      setMode("CONFIRM_DOWNGRADE");
      setIsOpen(true);
      setModalData({
        data: null,
        downgrade: downgrade,
        mode: "CONFIRM_DOWNGRADE",
      });
    }

    if (clickedTier === 1 && userTier === "tier-free") {
      try {
        const result = await storyBoardsApiClient.payment.subscribe({
          tier: clickedTier,
          frequency
        });

        if (result.status === 201) {
          // Store transaction details in state
          setTransactionId(result.data.id);
          setTransactionPrice(price || 0);
          setTransactionPlan(`${selectedTier.name} ${planType} Subscription`);
          setTransactionFrequency(frequency);

          // Store transaction details in localStorage
          localStorage.setItem('pendingTransaction', JSON.stringify({
            id: result.data.id,
            price: price || 0,
            plan: `${selectedTier.name} ${planType} Subscription`,
            frequency: frequency
          }));

          window.open(result.data.url, "_self");
        }
      } catch (error) {
        console.error("Server error during subscription request:", error);
        toast.error("There was an error processing your request. Please try again later.");
      }
    }
  };

  function findUsedTier() {
    const subscriptionPlanId = account?.subscription?.id; // Assuming subscriptionId corresponds to planId
    let usedTier = pricing.tiers.find(tier =>
      tier?.id === subscriptionPlanId ||
      tier?.id === subscriptionPlanId
    );

    if (!subscriptionPlanId) {
      usedTier = pricing.tiers[0]
    }

    if (usedTier) {
      const { monthly, yearly } = usedTier.price;
      return {
        ...usedTier
      };
    }

    return usedTier;
  }

  const [billingHistory, setBillingHistory] = useState<any[]>([]);

  const getBillingHistory = async () => {
    try {
      const result = await storyBoardsApiClient.payment.billingHistory();

      if (result.status === 200) {
        console.log({ res: result.data.data });
        setBillingHistory(result.data.data);
      }
    } catch (e) {
      toast.error("Can't retrieve billing history");
    }
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    getBillingHistory();

    const subscriptionStatus = searchParams.get("subscription_status");

    if (subscriptionStatus === 'success') {
      openModal({ mode: "SUBSCRIPTION_RESULT", data: { status: 'success' } });
    } else if (subscriptionStatus === 'failed') {
      openModal({ mode: "SUBSCRIPTION_RESULT", data: { status: 'failed' } });
    }
  }, [searchParams]);

  const handleSubscribe = () => {
    console.log("Button CLicked");

    window.open("https://app.story-boards.ai", "_blank");
  };

  const getPrice = (tier: any) => {
    let price = tier.priceMonthly;
    if (frequency === "yearly" && tier.priceYearly) {
      price = tier.priceYearly;
    }

    return `$${price}`;
  };


  const currentSubscription = findUsedTier();

  const handleFrequencyChange = (value: "monthly" | "yearly") => {
    setFrequency(value);
    sendEventToGoogleTagManager("frequency toggle", {
      outcome: "success",
    }, userId, availableCredits);
  };

  const isEligibleForTrial = (account: any) => {
    return !account.hasHadTrial && account.isEligibleForTrial;
  };

  return (
    <main className="w-full h-screen flex flex-col">
      <Navbar />
      <div className="min-h-screen bg-[#181818] p-8">

        <div className="container mx-auto max-w-10xl bg-[#181818] p-8 rounded shadow">
          <div className="space-y-10 divide-y divide-gray-700">
            {/*<div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">*/}
            {/*  <div className="px-4 sm:px-0">*/}
            {/*    <h2 className="text-base font-semibold leading-7 text-white text-md">Profile</h2>*/}
            {/*    <p className="mt-1 text-sm leading-6 text-gray-400">*/}
            {/*      This information will be displayed publicly so be careful what you share.*/}
            {/*    </p>*/}
            {/*  </div>*/}

            {/*  <form className="bg-white/5 shadow-sm ring-1 ring-white/10 sm:rounded-xl md:col-span-2">*/}
            {/*    <div className="px-4 py-6 sm:p-8">*/}
            {/*      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
            {/*        <div className="sm:col-span-4">*/}
            {/*          <label htmlFor="website" className="block text-sm font-medium leading-6 text-white">*/}
            {/*            Website*/}
            {/*          </label>*/}
            {/*          <div className="mt-2">*/}
            {/*            <div*/}
            {/*              className="flex rounded-md shadow-sm ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:max-w-md">*/}
            {/*            <span*/}
            {/*              className="flex select-none bg-white/5 items-center pl-3 text-gray-400 sm:text-sm">http://</span>*/}
            {/*              <input*/}
            {/*                type="text"*/}
            {/*                name="website"*/}
            {/*                id="website"*/}
            {/*                value={websiteInput}*/}
            {/*                onChange={(e) => setWebsiteInput(e.target.value)}*/}
            {/*                className="block flex-1 border-0 bg-white/5 py-1.5 pl-1 text-white placeholder:text-gray-500 focus:ring-0 sm:text-sm sm:leading-6"*/}
            {/*                placeholder="www.example.com"*/}
            {/*              />*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}

            {/*        <div className="col-span-full">*/}
            {/*          <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">*/}
            {/*            About*/}
            {/*          </label>*/}
            {/*          <div className="mt-2">*/}
            {/*          <textarea*/}
            {/*            id="about"*/}
            {/*            name="about"*/}
            {/*            rows={3}*/}
            {/*            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"*/}
            {/*            value={aboutInput}*/}
            {/*            onChange={e => setAboutInput(e.target.value)}*/}
            {/*          />*/}
            {/*          </div>*/}
            {/*          <p className="mt-3 text-sm leading-6 text-gray-400">Write a few sentences about yourself.</p>*/}
            {/*        </div>*/}

            {/*        <div className="col-span-full">*/}
            {/*          <label htmlFor="photo" className="block text-sm font-medium leading-6 text-white">*/}
            {/*            Photo*/}
            {/*          </label>*/}
            {/*          <div className="mt-2 flex items-center gap-x-3">*/}
            {/*            {account?.profileUrl ? <img*/}
            {/*              className="h-[42px] w-[42px] rounded-full bg-gray-50"*/}
            {/*              src={account?.profileUrl}*/}
            {/*              alt="" /> : <div*/}
            {/*              className={"h-[42px] w-[42px] rounded-full bg-sky-500 text-sm text-gray-600 font-semibold flex items-center justify-center"}>{account?.firstname?.substring(0, 1)}</div>}*/}
            {/*            <button*/}
            {/*              type="button"*/}
            {/*              className="rounded-md bg-white/5 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-white/10 hover:bg-white/20"*/}
            {/*            >*/}
            {/*              Change*/}
            {/*            </button>*/}
            {/*            <input type="file" name="avatar" id="avatar" onChange={(e) => {*/}
            {/*              e.target.files && setNewAvatarFileInput(e.target.files[0]);*/}
            {/*            }} />*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">*/}
            {/*      <button type="button" className="text-sm font-semibold leading-6 text-gray-200">*/}
            {/*        Cancel*/}
            {/*      </button>*/}
            {/*      <button*/}
            {/*        type="submit"*/}
            {/*        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
            {/*      >*/}
            {/*        Save*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  </form>*/}
            {/*</div>*/}

            {/*<div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">*/}
            {/*  <div className="px-4 sm:px-0">*/}
            {/*    <h2 className="text-base font-semibold leading-7 text-white text-md">Personal Information</h2>*/}
            {/*    <p className="mt-1 text-sm leading-6 text-gray-400">Use a permanent address where you can receive*/}
            {/*      mail.</p>*/}
            {/*  </div>*/}

            {/*  <form className="bg-white/5 shadow-sm ring-1 ring-white/10 sm:rounded-xl md:col-span-2">*/}
            {/*    <div className="px-4 py-6 sm:p-8">*/}
            {/*      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
            {/*        <div className="sm:col-span-3">*/}
            {/*          <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*            First name*/}
            {/*          </label>*/}
            {/*          <div className="mt-2">*/}
            {/*            <input*/}
            {/*              value={firstNameInput}*/}
            {/*              onChange={(e) => setFirstNameInput(e.target.value)}*/}
            {/*              type="text"*/}
            {/*              name="first-name"*/}
            {/*              id="first-name"*/}
            {/*              autoComplete="given-name"*/}
            {/*              className="block w-full bg-white/5 rounded-md border-0 py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*        </div>*/}

            {/*        <div className="sm:col-span-3">*/}
            {/*          <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*            Last name*/}
            {/*          </label>*/}
            {/*          <div className="mt-2">*/}
            {/*            <input*/}
            {/*              value={lastNameInput}*/}
            {/*              onChange={(e) => setLastNameInput(e.target.value)}*/}
            {/*              type="text"*/}
            {/*              name="last-name"*/}
            {/*              id="last-name"*/}
            {/*              autoComplete="family-name"*/}
            {/*              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*        </div>*/}

            {/*        <div className="sm:col-span-4">*/}
            {/*          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*            Email address*/}
            {/*          </label>*/}
            {/*          <div className="mt-2">*/}
            {/*            <input*/}
            {/*              value={emailInput}*/}
            {/*              onChange={(e) => setEmailInput(e.target.value)}*/}
            {/*              id="email"*/}
            {/*              name="email"*/}
            {/*              type="email"*/}
            {/*              autoComplete="email"*/}
            {/*              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*        </div>*/}

            {/*        <div className="sm:col-span-4">*/}
            {/*          <label htmlFor="occupation" className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*            Occupation*/}
            {/*          </label>*/}
            {/*          <div className="mt-2">*/}
            {/*            <select*/}
            {/*              id="occupation"*/}
            {/*              name="occupation"*/}
            {/*              autoComplete="occupation-name"*/}
            {/*              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"*/}
            {/*            >*/}
            {/*              <option>Freelancer</option>*/}
            {/*              <option>Student</option>*/}
            {/*            </select>*/}
            {/*          </div>*/}
            {/*        </div>*/}

            {/*        <div className="sm:col-span-4">*/}
            {/*          <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*            Country*/}
            {/*          </label>*/}
            {/*          <div className="mt-2">*/}
            {/*            <select*/}
            {/*              id="country"*/}
            {/*              name="country"*/}
            {/*              autoComplete="country-name"*/}
            {/*              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"*/}
            {/*            >*/}
            {/*              <option>United States</option>*/}
            {/*              <option>Canada</option>*/}
            {/*              <option>Mexico</option>*/}
            {/*            </select>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">*/}
            {/*      <button type="button" className="text-sm font-semibold leading-6 text-gray-200">*/}
            {/*        Cancel*/}
            {/*      </button>*/}
            {/*      <button*/}
            {/*        type="submit"*/}
            {/*        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
            {/*      >*/}
            {/*        Save*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  </form>*/}
            {/*</div>*/}

            {/*<div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">*/}
            {/*  <div className="px-4 sm:px-0">*/}
            {/*    <h2 className="text-base font-semibold leading-7 text-white text-md">Notifications</h2>*/}
            {/*    <p className="mt-1 text-sm leading-6 text-gray-400">*/}
            {/*      We'll always let you know about important changes, but you pick what else you want to hear about.*/}
            {/*    </p>*/}
            {/*  </div>*/}

            {/*  <form className="bg-white/5 shadow-sm ring-1 ring-white/10 sm:rounded-xl md:col-span-2">*/}
            {/*    <div className="px-4 py-6 sm:p-8">*/}
            {/*      <div className="max-w-2xl space-y-10">*/}
            {/*        <fieldset>*/}
            {/*          <legend className="text-sm font-semibold leading-6 text-gray-200">By Email</legend>*/}
            {/*          <div className="mt-6 space-y-6">*/}
            {/*            <div className="relative flex gap-x-3">*/}
            {/*              <div className="flex h-6 items-center">*/}
            {/*                <input*/}
            {/*                  id="comments"*/}
            {/*                  name="comments"*/}
            {/*                  type="checkbox"*/}
            {/*                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
            {/*                  checked={isAccountInformationChecked}*/}
            {/*                  onChange={() => setIsAccountInformationChecked(!isAccountInformationChecked)}*/}
            {/*                />*/}
            {/*              </div>*/}
            {/*              <div className="text-sm leading-6">*/}
            {/*                <label htmlFor="comments" className="font-medium text-gray-200">*/}
            {/*                  Account Information*/}
            {/*                </label>*/}
            {/*                <p className="text-gray-400">Important account related notifictions. This cannot be turned*/}
            {/*                  off.</p>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="relative flex gap-x-3">*/}
            {/*              <div className="flex h-6 items-center">*/}
            {/*                <input*/}
            {/*                  id="candidates"*/}
            {/*                  name="candidates"*/}
            {/*                  type="checkbox"*/}
            {/*                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
            {/*                  checked={isProductUpdatesChecked}*/}
            {/*                  onChange={() => setIsProductUpdatesChecked(!isProductUpdatesChecked)}*/}
            {/*                />*/}
            {/*              </div>*/}
            {/*              <div className="text-sm leading-6">*/}
            {/*                <label htmlFor="candidates" className="font-medium text-gray-200">*/}
            {/*                  Product Updates*/}
            {/*                </label>*/}
            {/*                <p className="text-gray-400">Get notified when a candidate applies for a job.</p>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="relative flex gap-x-3">*/}
            {/*              <div className="flex h-6 items-center">*/}
            {/*                <input*/}
            {/*                  id="offers"*/}
            {/*                  name="offers"*/}
            {/*                  type="checkbox"*/}
            {/*                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
            {/*                  checked={isOffersChecked}*/}
            {/*                  onChange={() => setIsOffersChecked(!isOffersChecked)}*/}
            {/*                />*/}
            {/*              </div>*/}
            {/*              <div className="text-sm leading-6">*/}
            {/*                <label htmlFor="offers" className="font-medium text-gray-200">*/}
            {/*                  Offers*/}
            {/*                </label>*/}
            {/*                <p className="text-gray-400">Get notified when a candidate accepts or rejects an offer.</p>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </fieldset>*/}
            {/*        <fieldset>*/}
            {/*          <legend className="text-sm font-semibold leading-6 text-gray-200">Push Notifications</legend>*/}
            {/*          <p className="mt-1 text-sm leading-6 text-gray-400">*/}
            {/*            These will be delivered in app.*/}
            {/*          </p>*/}
            {/*          <div className="mt-6 space-y-6">*/}
            {/*            <div className="flex items-center gap-x-3">*/}
            {/*              <input*/}
            {/*                id="push-everything"*/}
            {/*                name="push-notifications"*/}
            {/*                type="radio"*/}
            {/*                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
            {/*                checked={pushNotificationsState === "everything"}*/}
            {/*                onChange={() => setPushNotificationsState("everything")}*/}
            {/*              />*/}
            {/*              <label htmlFor="push-everything"*/}
            {/*                     className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*                Everything*/}
            {/*              </label>*/}
            {/*            </div>*/}
            {/*            <div className="flex items-center gap-x-3">*/}
            {/*              <input*/}
            {/*                id="push-email"*/}
            {/*                name="push-notifications"*/}
            {/*                type="radio"*/}
            {/*                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
            {/*                checked={pushNotificationsState === "same-as-email"}*/}
            {/*                onChange={() => setPushNotificationsState("same-as-email")}*/}
            {/*              />*/}
            {/*              <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*                Same as email*/}
            {/*              </label>*/}
            {/*            </div>*/}
            {/*            <div className="flex items-center gap-x-3">*/}
            {/*              <input*/}
            {/*                id="push-nothing"*/}
            {/*                name="push-notifications"*/}
            {/*                type="radio"*/}
            {/*                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
            {/*                checked={pushNotificationsState === "no-push"}*/}
            {/*                onChange={() => setPushNotificationsState("no-push")}*/}
            {/*              />*/}
            {/*              <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-gray-200">*/}
            {/*                No push notifications*/}
            {/*              </label>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </fieldset>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">*/}
            {/*      <button type="button" className="text-sm font-semibold leading-6 text-gray-200">*/}
            {/*        Cancel*/}
            {/*      </button>*/}
            {/*      <button*/}
            {/*        type="submit"*/}
            {/*        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
            {/*      >*/}
            {/*        Save*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  </form>*/}
            {/*</div>*/}
          </div>

          {/*<div className="relative pt-14 pb-8">*/}
          {/*  <div className="absolute inset-0 flex items-center" aria-hidden="true">*/}
          {/*    <div className="w-full border-t border-gray-600" />*/}
          {/*  </div>*/}
          {/*  <div className="relative flex justify-center">*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/* Subscription Management Section */}
          <section className="mb-6" id="subscriptions">
            <h2 className="text-xl font-semibold mb-4 text-neutral-100">Subscription Management</h2>
            {/* Pricing section */}
            <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
              <div className="mx-auto max-w-4xl text-center">
                <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                  Craft Your Story, Whatever The Scale
                </p>
              </div>
              <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-white">
                Our pricing plans support every storyteller, from small projects to
                grand visions. Start with our free plan, ideal for initial ideas. As
                your story grows, choose a plan that suits your creative journey. Your
                canvas awaits.
              </p>
              <div className="relative mt-16 flex justify-center">
                <RadioGroup
                  value={frequency}
                  onChange={handleFrequencyChange}
                  className="grid grid-cols-2 gap-x-1 rounded-full bg-white/10 p-1 text-center text-xs font-semibold leading-5 text-gray-800 relative"
                >
                  <RadioGroup.Label className="sr-only">
                    Payment frequency
                  </RadioGroup.Label>
                  {["monthly", "yearly"].map((option) => (
                    <RadioGroup.Option
                      key={option}
                      value={option}
                      className={({ checked }) =>
                        classNames(
                          checked
                            ? "bg-sky-500 text-white"
                            : "text-white hover:bg-gray-600",
                          "cursor-pointer rounded-full px-2.5 py-1 relative"
                        )
                      }
                    >
                      <span className="flex items-center">
                        {option === "monthly" ? "Monthly" : "Yearly"}
                        {option === "yearly" && (
                          <span
                            className="absolute -top-3 -right-5 transform rotate-12 text-green-500 text-xs font-semibold bg-neutral-600 px-1 py-0.5 rounded"
                          >
                            20% Off
                          </span>
                        )}
                      </span>
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </div>
              <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
                {tiers.map((tier) => (
                  <section
                    key={tier?.id}
                    className={classNames(
                      tier.mostPopular
                        ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                        : "",
                      "p-8"
                    )}
                  >
                    <h3
                      id={tier?.id}
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                      <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                      <span className="text-sm font-semibold">/month</span>
                    </p>
                    <a
                      onClick={handleSubscribe}
                      aria-describedby={tier?.id}
                      className={classNames(
                        tier.mostPopular
                          ? "bg-indigo-600 text-white hover:bg-indigo-500"
                          : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                        "mt-8 block cursor-pointer rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                    >
                      {currentSubscription?.id === tier?.id ? "Current subscription" : "Subscribe"}
                    </a>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
                    >
                      {sections.map((section) => (
                        <li key={section.name}>
                          <ul role="list" className="space-y-4">
                            {section.features.map((feature) =>
                              feature.tiers[tier.name as TierName] ? (
                                <li key={feature.name} className="flex gap-x-3">
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-indigo-600"
                                    aria-hidden="true"
                                  />
                                  <span>
                                    {feature.name}{" "}
                                    {typeof feature.tiers[tier.name as TierName] ===
                                      "string" ? (
                                      tier.name === "Indie" ? (
                                        feature.name === "Export Options" ? (
                                          "PDF & JPG"
                                        ) : feature.name === "Virtual Actors" ||
                                          feature.name === "Storyboard Styles" ? (
                                          "all*"
                                        ) : (
                                          "unlimited*"
                                        )
                                      ) : (
                                        <span className="text-sm leading-6 text-gray-500">
                                          ({feature.tiers[tier.name as TierName]})
                                        </span>
                                      )
                                    ) : null}
                                  </span>
                                </li>
                              ) : null
                            )}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </section>
                ))}
              </div>

              {/* lg+ */}
              <div className="isolate mt-20 hidden lg:block">
                <div className="relative -mx-8">
                  {tiers.some((tier) => tier.mostPopular) ? (
                    <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                      <div
                        className="flex w-1/4 px-4"
                        aria-hidden="true"
                        style={{
                          marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                            }%`
                        }}
                      >
                        <div className="w-full rounded-t-xl border-x border-t border-white/10 bg-gray-400/5" />
                      </div>
                    </div>
                  ) : null}
                  <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                    <caption className="sr-only">Pricing plan comparison</caption>
                    <colgroup>
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                    </colgroup>
                    <thead>
                      <tr>
                        <td />
                        {tiers.map((tier) => (
                          <th
                            key={tier?.id}
                            scope="col"
                            className="px-6 pt-6 xl:px-8 xl:pt-8"
                          >
                            <div className="text-sm font-semibold leading-7 text-white">
                              {tier.name}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <span className="sr-only">Price</span>
                        </th>
                        {tiers.map((tier, index) => (
                          <td key={tier?.id} className="px-6 pt-2 xl:px-8">
                            <div className="flex items-baseline gap-x-1 text-white">
                              <span className="text-4xl font-bold">
                                {getPrice(tier)}
                              </span>
                              <span className="text-sm font-semibold leading-6">
                                /month
                              </span>
                              {frequency === "yearly" &&
                                tier.name !== "Free" && ( // Add condition for excluding "billed yearly" from Free tier
                                  <span className="text-xs font-normal text-white ml-2">
                                    (billed yearly)
                                  </span>
                                )}
                            </div>
                            <button
                              onClick={() => handleSelectPlanClick({ clickedTier: index })}
                              className={classNames(
                                tier.mostPopular
                                  ? "bg-sky-500 text-white hover:bg-sky-600"
                                  : "text-white ring-1 ring-inset ring-neutral-500 hover:ring-indigo-300",
                                "mt-8 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-100"
                              )}
                              disabled={currentSubscription?.id === tier?.id}
                            >
                              {currentSubscription?.id === tier?.id
                                ? "Current Subscription"
                                : tier.name === "Free"
                                  ? currentSubscription?.name === "Indie"
                                    ? "Switch to Free"
                                    : "Switch to Free"
                                  : tier.name === "Indie"
                                    ? currentSubscription?.name === "Free"
                                      ? isEligibleForTrial(account)
                                        ? "Free Trial"
                                        : "Subscribe"
                                      : "Subscribe"
                                    : "Subscribe"}
                            </button>
                          </td>
                        ))}
                      </tr>
                      {sections.map((section, sectionIdx) => (
                        <Fragment key={section.name}>
                          <tr>
                            <th
                              scope="colgroup"
                              colSpan={4}
                              className={classNames(
                                sectionIdx === 0 ? "pt-8" : "pt-16",
                                "pb-4 text-sm font-semibold leading-6 text-white"
                              )}
                            >
                              {section.name}
                              <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                            </th>
                          </tr>
                          {section.features.map((feature) => (
                            <tr key={feature.name}>
                              <th
                                scope="row"
                                className="py-4 text-sm font-normal leading-6 text-neutral-400"
                              >
                                {feature.name}
                                <div className="absolute inset-x-8 mt-4 h-px bg-white/5" />
                              </th>
                              {tiers.map((tier) => (
                                <td key={tier?.id} className="px-6 py-4 xl:px-8">
                                  {typeof feature.tiers[tier.name as TierName] === "string" ? (
                                    <div className="text-center text-sm leading-6 text-neutral-400">
                                      {feature.tiers[tier.name as TierName]}
                                    </div>
                                  ) : feature.tiers[tier.name as TierName] === true ? (
                                    <CheckIcon
                                      className="mx-auto h-5 w-5 text-sky-600"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <MinusIcon
                                      className="mx-auto h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <section className="mt-12 text-sm text-neutral-400">
                *Limited Time Offer: Indie plan currently offers unlimited features.
                Subject to change.
              </section>
            </div>
          </section>

          <div className="relative pt-14 pb-8">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-600" />
            </div>
            <div className="relative flex justify-center">
            </div>
          </div>

          <div className="text-white">
            <h2 className="text-base font-semibold leading-7 text-white text-xl mb-2">Billing History</h2>

            {billingHistory ? <div className="flex flex-col gap-y-2">{
              billingHistory?.map((history) => <div key={history?.id}
                className="flex justify-between items-center border border-neutral-700 rounded p-4">
                <div className="flex gap-x-2">
                  <div>
                    {history?.status === "paid" ? <CheckCircleIcon className="h-6 w-6 text-lime-500" /> :
                      <XCircleIcon className="h-6 w-6 text-red-500" />}
                  </div>
                  <div>{(new Date(history.status_transitions.paid_at * 1000)).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                  })}</div>
                </div>
                <div>{history.lines?.data[0]?.description}</div>
                <div className="flex gap-x-1">
                  <div>{history.lines?.data[0]?.amount / 100}</div>
                  <div>{history.lines?.data[0]?.currency}</div>
                </div>
                {history?.status === "paid" ?
                  <button className="text-white" onClick={() => window.open(history.invoice_pdf)}>
                    <DocumentArrowDownIcon className="h-6 w-6 text-white" />
                  </button> : <div>/</div>}
              </div>)
            }</div> : <span className="text-neutral-400">You haven't got a billing history</span>}
          </div>

          <div className="relative pt-14 pb-8">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-600" />
            </div>
            <div className="relative flex justify-center">
            </div>
          </div>

          {/* Delete Account Section */}
          <div className="mt-10">
            <h2 className="text-base font-semibold leading-7 text-white text-xl">Danger Zone</h2>
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-200">
                Deleting your account will remove all your data from our systems.
              </p>
              <button
                onClick={() => {
                  openModal({
                    mode: "CONFIRM",
                    data: {
                      title: "Are you sure you want to delete your account?",
                      description: "This action cannot be undone.",
                      function: () => {
                        setTimeout(() => {
                          openModal({
                            mode: "CONFIRM",
                            data: {
                              title: "Warning!",
                              description: "Please note: deleting your account will permanently remove all your projects and associated images from our systems.\n This action cannot be undone. Have you considered downgrading to free tier instead?",
                              function: () => deleteProfileRequest()
                            }
                          }
                          );
                        }, 500);
                      }
                    }
                  }
                  );
                }}
                className="bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white"
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div >
    </main >
  );
};

export default AccountPage;
