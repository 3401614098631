import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";

interface InpaintCanvasProps {
  className?: string;
  preferredStrokeStyle: string;
  preferredLineWidth: number;
  isErasonOn: boolean;
}

interface Curve {
  path: Path2D;
  strokeStyle: string;
  lineWidth: number;
  compositionOperation: GlobalCompositeOperation;
}

export const InpaintCanvas = forwardRef(({ className, preferredStrokeStyle, preferredLineWidth, isErasonOn } : InpaintCanvasProps, ref) => {

  const [curves, setCurves] = useState<Curve[]>([]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (context && canvas) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      curves.forEach((curve) => {
        context.lineWidth = curve.lineWidth;
        context.strokeStyle = preferredStrokeStyle;
        context.globalCompositeOperation = curve.compositionOperation;
        context.stroke(curve.path);
      });
    }
  }, [preferredStrokeStyle, curves]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        if (isErasonOn) {
          context.globalCompositeOperation = "destination-out";
          context.fillStyle = 'rgba(0,0,0,1)';
          context.lineCap = "round";
        } else {
          context.globalCompositeOperation = "source-over";
          context.fillStyle = preferredStrokeStyle;
          context.lineCap = "round";
        };
      }
    }
  }, [isErasonOn, preferredStrokeStyle]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (context) {
      context.lineWidth = preferredLineWidth;
    }
  }, [preferredLineWidth])

  const handleMouseDown = (event: React.MouseEvent) => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (!canvas || !context) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const newPath = new Path2D();
    newPath.moveTo(x, y);

    const moveListener = (moveEvent: MouseEvent) => {
      const x = moveEvent.clientX - rect.left;
      const y = moveEvent.clientY - rect.top;
      newPath.lineTo(x, y);
      context.strokeStyle = preferredStrokeStyle;
      context.stroke(newPath);
    };

    const upListener = () => {
      canvas.removeEventListener("mousemove", moveListener);
      canvas.removeEventListener("mouseup", upListener);
      canvas.removeEventListener("mouseleave", upListener)
      setCurves([
        ...curves,
        { path: newPath, strokeStyle: preferredStrokeStyle, lineWidth: preferredLineWidth, compositionOperation: context.globalCompositeOperation },
      ]);;
    };

    canvas.addEventListener("mousemove", moveListener);
    canvas.addEventListener("mouseup", upListener);
    canvas.addEventListener("mouseleave", upListener);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (context && canvas) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.globalCompositeOperation = "source-over";
      setCurves([]);
    }
  }

  useImperativeHandle(ref, () => ({
    clearCanvas,
  }))

  const selectedImageElement = document.querySelector("img#selected") as HTMLImageElement;

  const handleResize = () => {
    const canvas = canvasRef.current;
    if (canvas && selectedImageElement) {
      canvas.height = selectedImageElement?.clientHeight;
      canvas.width = selectedImageElement?.clientWidth;
    }
    setCurves([]);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <canvas
      id="inpaint-canvas"
      className={className}
      height={selectedImageElement?.clientHeight}
      width={selectedImageElement?.clientWidth}
      ref={canvasRef}
      onMouseDown={handleMouseDown}
    />
  )
});
