import React, { useState, useEffect } from "react";
import { useBaseModalContext } from "../BaseModalContext";
import { useSelector } from "react-redux";
import { sendEventToGoogleTagManager } from "../../../../../core/utils/googleTagManager";
import { TReduxStore } from "../../../../../core/redux/store";
import { storyBoardsApiClient } from "../../../../../core/config/api";
import useUserPlan from "../../../../../core/utils/creditCost";
import { useNavigate } from "react-router-dom"; // Import useNavigate

export default function VideoDownloadModal() {
  const [step, setStep] = useState(1);
  const { setIsOpen } = useBaseModalContext();
  const { userId, availableCredits } = useSelector((state: TReduxStore) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits
  }));
  const story = useSelector((state: TReduxStore) => state.app.story);
  const { isFree } = useUserPlan();
  const navigate = useNavigate(); // Initialize navigate

  // State for email notification checkbox
  const [emailNotification, setEmailNotification] = useState(true);

  // Set initial checkbox state based on story.video.emailNotification
  useEffect(() => {
    if (story?.video?.emailNotification !== undefined) {
      setEmailNotification(story.video.emailNotification);
    }
  }, [story]);

  const handleConfirm = async () => {
    setStep(2);
    try {
      await storyBoardsApiClient.video.generateVideo({
        storyId: story?.id ?? "",
        params: { emailNotification },
      });
      sendEventToGoogleTagManager("video_generation_started", {
        story_id: story?.id,
        outcome: "success",
      }, userId, availableCredits);
    } catch (error) {
      console.error("Error starting video generation:", error);
      sendEventToGoogleTagManager("video_generation_started", {
        story_id: story?.id,
        outcome: "error",
      }, userId, availableCredits);
    }
  };

  return (
    <div className="modal-container fixed inset-0 flex items-center justify-center p-8 z-50">
      <div className="text-center">
        {step === 1 ? (
          <>
            <h3 className="text-xl font-semibold text-gray-900">Video Generation Beta</h3>
            <p className="mt-2 text-md text-gray-600">
              This BETA feature creates a simple clip with transitions between your images. <br /><strong>Note: </strong>This is not an AI feature.
            </p>
            {isFree && (
              <div className="mt-10 text-md text-gray-600">
                This is a paid feature.
              </div>
            )}
            <div className="flex items-center justify-center mt-4">
              {!isFree && (
                <>
                  <input
                    type="checkbox"
                    id="emailNotification"
                    checked={emailNotification}
                    onChange={(e) => setEmailNotification(e.target.checked)}
                    className="mr-2 h-4 w-4 text-sky-600 border-gray-300 rounded focus:ring-sky-500"
                  />
                  <label htmlFor="emailNotification" className="text-sm text-gray-600">
                    Send me an email when it's done
                  </label>
                </>
              )}
            </div>
            <div className="mt-6 flex gap-4 justify-center">
              <button
                className={"px-5 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-75 bg-sky-500 text-white hover:bg-sky-600 focus:ring-sky-400"}
                onClick={
                  isFree
                    ? () => {
                      setIsOpen(false);
                      navigate(`/account`);
                    }
                    : handleConfirm
                }
                disabled={false} // Remove the disabled prop since the button should navigate if free
              >
                {isFree ? "Upgrade" : "Create Video"}
              </button>
              <button
                className="px-5 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-75"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <h3 className="text-xl font-semibold text-gray-900">Video Generation in Progress</h3>
            <p className="mt-2 text-md text-gray-600">
              We are now creating your video.<br /> You can close this window and continue using the application.
            </p>
            <button
              className="mt-6 px-5 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:ring-opacity-75"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
}
