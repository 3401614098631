export const initializeGoogleTagManager = (id: any) => {
  (function (w: any, d, s, l: any, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f: any = d.getElementsByTagName(s)[0],
      j: any = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", id);
};

export const sendEventToGoogleTagManager = (eventName: string, eventData: any, userId?: string, availableCredits?: number) => {
  window.dataLayer = window.dataLayer || [];
  if (userId) {
    window.dataLayer.push({
      event: eventName,
      user_id: userId,
      credits: availableCredits,
      ...eventData,
    });
  } else {
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    });
  }
};

