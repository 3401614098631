import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { storyBoardsApiClient } from '../../../../../core/config/api';
import { TCharacter } from '../../../character/Character';
import { useBaseModalContext } from '../BaseModalContext';
import CastUI from './castUI';
import { useAppContext } from '../../../../../core/utils/AppContext';

interface CastUIWrapperProps {
  mode: 'light' | 'dark';
}

const CastUIWrapper: React.FC<CastUIWrapperProps> = ({ mode }) => {
  const { storyId } = useParams();
  const { charactersList, setCharactersList } = useAppContext();
  const baseModalContext = useBaseModalContext();

  console.log('CastUIWrapper renders... with charactersList:', charactersList);

  useEffect(() => {
    retrieveCharactersRequest();
  }, []);

  const retrieveCharactersRequest = async () => {
    try {
      const result = await storyBoardsApiClient.character.getCharacters({
        storyId: storyId ?? ''
      });
      if (result.status === 200) {
        setCharactersList(result.data.characters);
        baseModalContext.setAlreadyUsedCharacterImages(result.data.characters.map((character: TCharacter) => character.image) ?? []);
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.log({ error });
      toast.error('Something went wrong');
    }
  };

  const removeCharacterFromState = ({ id }: { id: string }) => {
    setCharactersList(prevList => prevList.filter(character => character?.id !== id));
    baseModalContext.setAlreadyUsedCharacterImages(prevImages =>
      prevImages.filter(image => charactersList.find(c => c.id !== id)?.image !== image)
    );
  };

  const handleCreateCharacterClick = () => {
    baseModalContext.openModal({
      mode: "CREATE",
      data: { character: { id: '', name: '', description: '', image: '', scene: [] } }
    });
  };

  const handleCharacterClick = (newMode: string, { character }: { character: TCharacter }) => {
    console.log('clicked character:', character);
    baseModalContext.setMode(newMode);
    console.log(`mode set to: ${newMode}`);
    console.log('data passed to openModal:', { character });
    baseModalContext.openModal({
      mode: newMode,
      data: { character }
    });
  }

  const updateCharacterInState = (updatedCharacter: TCharacter) => {
    console.log(`CastUIWrapper - Updating character in state: ${updatedCharacter}`)
    setCharactersList(prevList =>
      prevList.map(character =>
        character.id === updatedCharacter.id ? updatedCharacter : character
      )
    );
  };

  return (
    <CastUI
      removeCharacterFromState={removeCharacterFromState}
      handleCreateCharacterClick={handleCreateCharacterClick}
      switchMode={handleCharacterClick}
      updateCharacterInState={updateCharacterInState}
      UImode={mode}
      CastUiMode="AI_FOUND"
    />
  );
};

export default CastUIWrapper;
