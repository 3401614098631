import { SET_STORY } from "../constants/storiesConstants";
import { storyBoardsApiClient } from "../../config/api";
import { useAppContext } from "../../utils/AppContext";
import { Dispatch } from "redux";

export type TStoriesStore = {}; 

export const getStory = ({ id }: { id: string | undefined }) => {
  return async (dispatch: Dispatch) => {
    try {
      if (id) {
        const result = await storyBoardsApiClient.story.getStory({ id });
        if (result.status === 200) {
          dispatch({ type: SET_STORY, data: result.data });
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };
};
