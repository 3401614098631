import { useNavigate } from "react-router";
import {useBaseModalContext} from "../base-modal/BaseModalContext";

export default function SubscriptionResult() {
    const {modalData, closeModal} = useBaseModalContext();

    const navigate = useNavigate();

    const handleCloseModal = () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.delete('subscription_status');
        url.search = params.toString();

        navigate(url.pathname + url.search, { replace: true });

        closeModal();
    }

    if(modalData.status === "success"){
    return (
        <div>
            <div>Thank You for your support! 🥳
                Happy Storytelling...</div>
            <div className="flex items-center justify-center px-2 py-2 mt-3">
                <button
                    onClick={() => handleCloseModal()}
                    className="text-sm font-semibold text-neutral-600 px-4 py-1 rounded border border-transparent hover:border-neutral-400">Let's
                    Go!
                </button>
            </div>
            </div>
            )
            } else if (modalData.status === "failed") {
        return <div>
            <div className='mb-4'>Unfortunately we were not able finish the payment process! 🙁
                Please try it again.</div>
           <div className="flex items-center justify-center"><button onClick={()=>handleCloseModal()} className="text-sm font-semibold text-neutral-600 px-4 py-1 rounded border border-transparent hover:border-neutral-400">Try again</button></div>
        </div>
    }else{
       return <></>
    }
}