import React, { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { useBaseModalContext } from "../modals/base-modal/BaseModalContext";
import { toast } from "react-toastify";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import { downloadImage } from "../../../core/utils/downloadImage";
import useUserPlan from "../../../core/utils/creditCost";


const Carousel = ({ images, shot, scenes }: { images: any, shot: any, scenes: any }) => {
  const { userId, availableCredits, account, story } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app?.account?.stats?.availableCredits,
    account: state.app?.account,
    story: state.app?.story
  }));

  const { isFree, isPlan, costs } = useUserPlan();

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const isAdmin = process.env.REACT_APP_ADMIN === 'true';

  const [selectedImages, setSelectedImages] = useState<{
    url: string,
    paidUrl: string,
    freeUrl: string,
    description: string,
    sceneNumber: number,
    shotNumber: number
  }[]>([]);

  useEffect(() => {
    const getCurrentSceneSelectedImages = () => {
      try {
        let carouselImagesArray: {
          url: string,
          paidUrl: string,
          freeUrl: string,
          description: string,
          sceneNumber: number,
          shotNumber: number
        }[] = [];

        let startingIndex = 0;

        scenes.forEach((scene: any, sceneIndex: number) => {
          scene?.shots.forEach((shotInScene: any, shotIndex: number) => {
            shotInScene.selectedImage && carouselImagesArray.push({
              url: shotInScene.selectedImage,
              paidUrl: shotInScene?.images.find((image: any) => image.url === shotInScene.selectedImage)?.paidUrl ?? shotInScene.selectedImage,
              freeUrl: shotInScene?.images.find((image: any) => image.url === shotInScene.selectedImage)?.freeUrl ?? shotInScene.selectedImage,
              description: shotInScene?.shotDescription ?? "",
              sceneNumber: sceneIndex + 1,
              shotNumber: shotIndex + 1
            });
            if (shotInScene?.id === shot?.id) {
              startingIndex = carouselImagesArray.length - 1;
            }
          });
        });

        setSelectedImages(carouselImagesArray);
        setSelectedImageIndex(startingIndex);
      } catch (error) {
        console.error({ error });
      }
    };
    getCurrentSceneSelectedImages();
  }, [shot?.id, scenes, shot?.selectedImage]);

  const changeImage = (step: number) => {
    if (selectedImages.length === 0) return;

    const newIndex = (selectedImageIndex + step + selectedImages.length) % selectedImages.length;

    setSelectedImageIndex(newIndex);
  };

  const getCurrentImage = () => {
    if (selectedImages.length === 0) return null;

    return selectedImages[selectedImageIndex];
  };

  const currentImage = getCurrentImage();

  const { openModal } = useBaseModalContext();

  const projectTitle = story.label;
  const sceneNumber = currentImage?.sceneNumber ?? 0;
  const shotNumber = currentImage?.shotNumber ?? 0;

  const handleDownloadImageButtonClick = (projectTitle: string, sceneNumber: number, shotNumber: number) => {
    if (story.isTemplate) {
      toast.info("You cannot download images in a template project");
      return;
    }
    else if (isFree && !isAdmin) {
      openModal({ mode: "SUBSCRIBE", data: {}, trigger: "DOWNLOAD_IMAGE_BUTTON", story: story?.id });
    } else {
      if (currentImage?.paidUrl && currentImage?.paidUrl !== "") {
        sendEventToGoogleTagManager("jpg_download", {
          outcome: "success",
        }, userId, availableCredits);

        // Log the full URL to the console
        console.log("Image URL:", currentImage.paidUrl);
        const fileName = `${projectTitle}_scene_${sceneNumber}_shot_${shotNumber}.jpeg`;
        downloadImage(currentImage.paidUrl, fileName)

      } else {
        toast.error("Something went wrong while trying to download the image!");
      }
    }
  };


  return (
    <div className="carousel relative">
      <div className="carousel-inner relative w-full overflow-hidden flex justify-center items-center">
        <div className="carousel-item group relative flex justify-center items-center w-full">
          {selectedImageIndex !== 0 && (
            <button
              className="absolute left-4 sm:left-10 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-70 rounded-lg hover:bg-opacity-50 transition-all duration-300 ease-in-out z-20 flex items-center justify-center p-2 sm:p-3 cursor-pointer"
              onClick={() => changeImage(-1)}
            >
              <ChevronLeftIcon className="h-12 w-8 text-white" />
            </button>
          )}

          <div className="flex flex-col px-2 sm:px-4 items-center w-full mx-2 relative z-10">
            <div className="text-sm sm:text-xl text-neutral-800 font-semibold py-2">
              {`Scene: ${currentImage?.sceneNumber ?? 0} / Shot: ${currentImage?.shotNumber ?? 0}`}
            </div>
            <div className="w-full max-w-[960px] h-[508x] relative bg-gray-200 flex items-center justify-center">
              {currentImage && (
                currentImage?.url === "https://story-board-images.s3.us-west-1.amazonaws.com/placeholder_result.webp" ? (
                  <img
                    src={currentImage?.url}
                    className="block w-[960px] h-auto object-contain"
                    alt="generated image"
                  />
                ) : (
                  <img
                    src={isFree ? currentImage?.freeUrl : currentImage.paidUrl}
                    className="block w-full h-auto object-contain"
                    alt="generated image"
                  />
                )
              )}
              {!currentImage && (
                <div className="text-neutral-500">loading...</div>
              )}
              {currentImage?.url !== "https://story-board-images.s3.us-west-1.amazonaws.com/placeholder_result.webp" && (
                <button
                  onClick={() => handleDownloadImageButtonClick(projectTitle, sceneNumber, shotNumber)}
                  className="absolute top-2 right-2 sm:top-4 sm:right-4 bg-slate-200 border border-neutral-900 rounded p-1 sm:p-[4px]"
                >
                  <ArrowDownTrayIcon className="h-6 w-6 sm:h-8 sm:w-8" />
                </button>
              )}
            </div>

            <div className="text-xs sm:text-md pt-2 sm:pt-6 text-neutral-800 py-2">
              {currentImage?.description}
            </div>
          </div>

          {selectedImageIndex !== selectedImages.length - 1 && (
            <button
              className="absolute right-4 sm:right-10 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-70 rounded-lg hover:bg-opacity-50 transition-all duration-300 ease-in-out z-20 flex items-center justify-center p-2 sm:p-3 cursor-pointer"
              onClick={() => changeImage(1)}
            >
              <ChevronRightIcon className="h-12 w-8 text-white" />
            </button>
          )}
        </div>
      </div>
    </div>
  );



};

export default Carousel;
