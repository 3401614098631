import React from "react";
import BaseModal from "./base-modal/BaseModal";
import { useBaseModalContext } from "./base-modal/BaseModalContext";

export default function Modals() {
  const { isOpen, mode } = useBaseModalContext();
  // const modalManager = useSelector((state: any) => state?.app?.modalManager);
  //
  // console.log({ modalManager });
  //
  // const setModalType = () => {
  //   switch (modalManager?.modal) {
  //     case ENUM_MODAL_TYPE.EMPTY: {
  //       return null;
  //     }
  //     case ENUM_MODAL_TYPE.IMAGE_VIEWER: {
  //       return <ImageViewer />;
  //     }
  //     case ENUM_MODAL_TYPE.CONFIRM: {
  //       return <ConfirmDelete />;
  //     }
  //     case ENUM_MODAL_TYPE.CREATE_TEAM: {
  //       return <CreateTeam />;
  //     }
  //     case ENUM_MODAL_TYPE.CREATE_PROJECT: {
  //       return <CreateProject addStory={modalManager?.data?.addStory} />;
  //     }
  //     case ENUM_MODAL_TYPE.EDIT_CHARACTER: {
  //       return <EditCharacter />;
  //     }
  //     case ENUM_MODAL_TYPE.INVITE_TEAM_MEMBER: {
  //       return <InviteTeamMember />;
  //     }
  //     case ENUM_MODAL_TYPE.FIRST_LOGIN_EMAIL_CONFIRM: {
  //       return <FirstLoginEmailConfirm />;
  //     }
  //     case ENUM_MODAL_TYPE.RENAME_PROJECT: {
  //       return <RenameProject />;
  //     }
  //     default: {
  //       return null;
  //     }
  //   }
  // };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => {
      }}
      mode={mode}
      // images={shot?.images}
      // shot={shot}
      // mainImage={mainImage}
      // imageData={{
      //   src: mainImage,
      //   alt: "Selected Image",
      //   title: "",
      //   description: shot?.description || ""
      // }}
      // scenes={scenes}
    />
    // <Transition.Root
    //   show={modalManager?.modal !== ENUM_MODAL_TYPE.EMPTY && true}
    //   as={Fragment}
    // >
    //   <Dialog
    //     as="div"
    //     className={"relative z-10"}
    //     onClose={() =>
    //       // @ts-ignore
    //       modalManager?.modal !== ENUM_MODAL_TYPE.FIRST_LOGIN_EMAIL_CONFIRM && store.dispatch(setModal({ modal: ENUM_MODAL_TYPE.EMPTY, data: null }))
    //     }
    //   >
    //     <Transition.Child
    //       as={Fragment}
    //       enter="ease-out duration-300"
    //       enterFrom="opacity-0"
    //       enterTo="opacity-100"
    //       leave="ease-in duration-200"
    //       leaveFrom="opacity-100"
    //       leaveTo="opacity-0"
    //     >
    //       <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    //     </Transition.Child>
    //
    //     <div className="fixed inset-0 z-10 overflow-y-auto ">
    //       <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
    //         <Transition.Child
    //           as={Fragment}
    //           enter="ease-out duration-300"
    //           enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //           enterTo="opacity-100 translate-y-0 sm:scale-100"
    //           leave="ease-in duration-200"
    //           leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    //           leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //         >
    //           <Dialog.Panel
    //             className="relative transform overflow-hidden rounded bg-neutral-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6">
    //             {setModalType()}
    //           </Dialog.Panel>
    //         </Transition.Child>
    //       </div>
    //     </div>
    //   </Dialog>
    // </Transition.Root>
  );
}
