import { SET_TEAMS } from "../constants/teamsConstants";
import { storyBoardsApiClient } from "../../config/api";

export type TTeamsStore = {};

export const getTeams = () => {
  return async (dispatch: any) => {
    try {
      const result = await storyBoardsApiClient.team.getTeams();
      if (result.status === 200) {
        dispatch({ type: SET_TEAMS, data: result.data });
      }
    } catch (error) {
      console.log({ error });
    }
  };
};

export const deleteTeam = ({ id }: { id: string }) => {
  return async (dispatch: any) => {
    try {
      const result = await storyBoardsApiClient.team.deleteTeam({ id });
      if (result.status === 200) {
        dispatch({ type: SET_TEAMS, data: result.data });
      }
    } catch (error) {
      console.log({ error });
    }
  };
};
