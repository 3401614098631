import { SET_STORY, SET_STORIES } from "../constants/storiesConstants";

export const storyReducer = (state = {}, action: any) => {
  switch (action.type) {
    case SET_STORY:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export const storiesReducer = (state = {}, action: any) => {
  switch (action.type) {
    case SET_STORIES:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};
