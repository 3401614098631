import { StarIcon } from "@heroicons/react/20/solid";
import React from "react";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

interface IRequiredProps {
  index: string;
  img: string;
  title: string;
  description: string;
  clientIndex: number;
}

interface IOptionalProps {
  onClick?: Function;
  isActive?: boolean;
  showStar?: boolean;
  borderColor?: string;
  hoverBorderColor?: string;
  textColor?: string;
  hoverTextColor?: string;
  descriptionTextColor?: string;
  hoverDescriptionTextColor?: string;
}

interface IProps extends IRequiredProps, IOptionalProps {
}

export default function Style({
  index,
  img,
  title,
  description,
  clientIndex,
  onClick,
  isActive = false,
  showStar,
  borderColor = "border-transparent",
  hoverBorderColor = "hover:border-neutral-300",
  textColor = "text-neutral-900",
  hoverTextColor = "hover:text-neutral-700",
  descriptionTextColor = "text-neutral-400",
  hoverDescriptionTextColor = "hover:text-neutral-500"
}: IProps) {
  return (
    <div
      className="cursor-pointer"
      onClick={() => onClick && onClick(index)}
    >
      <div
        className={classNames(
          "border-2 transition-all duration-300 ease-in-out transform hover:scale-105",
          isActive ? "border-neutral-400 hover:border-neutral-600" : borderColor,
          isActive ? "hover:border-neutral-600" : hoverBorderColor,
          "rounded-lg overflow-hidden",
          "flex flex-col items-center",
          "hover:shadow-lg" // Shadow on hover
        )}
      >
        {/* Image container ensuring the image is square */}
        <div className="w-full aspect-square relative">
          <img
            className="w-full h-full"
            src={img}
            alt={title}
          />
          {/* Add square with clientIndex */}
          <div className="absolute top-0 left-0 bg-neutral-800 text-white w-8 h-8 flex items-center justify-center">
            {clientIndex}
          </div>
          {
            showStar && (
              <div className="absolute bottom-0 right-0 text-white px-2 py-1 rounded rounded-tr-md">
                <StarIcon className="w-[27px] text-yellow-500" />
              </div>
            )
          }
        </div>
        {/* Text container */}
        <div className="p-2 text-center">
          <p className={classNames("text-md font-semibold font-medium", textColor, hoverTextColor)}>
            {title}
          </p>
          <p className={classNames("text-xs", descriptionTextColor, hoverDescriptionTextColor)}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
