import { SET_MODAL } from "../constants/modalManagerConstants";
import { ENUM_MODAL_TYPE } from "../../types/modal.type";

export type TModalStore = {
  modal?: ENUM_MODAL_TYPE;
  data?: any;
};

export const setModal = ({ modal, data }: TModalStore) => {
  return async (dispatch: any) => {
    dispatch({ type: SET_MODAL, modal, data });
  };
};
