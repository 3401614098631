import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./core/redux/store";
import { TourProvider } from "@reactour/tour";
import { CustomTourProvider, useTourContext  } from "./core/utils/TourContext";

import "./index.scss";
import { BaseModalContextProvider } from "./app/components/modals/base-modal/BaseModalContext";
import { AppContextProvider } from "./core/utils/AppContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const radius = 10

const TourController = () => {
  const { setTourRunning } = useTourContext();

  return (
    <TourProvider
      steps={[]}
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#ef5a3d',
          borderRadius: radius,
          zIndex: 20000,
          width: '900px'
        }),
        maskArea: (base) => ({ 
          ...base, 
          rx: radius }),
        maskWrapper: (base) => ({ 
          ...base, 
          color: '#57697E',
          zIndex: 20000 }),
        controls: (base) => ({ 
          ...base, 
          marginTop: 50 }),
        close: (base) => ({ 
          ...base, 
          right: 10, 
          left: 'auto', 
          top: 10,
          width: '16px', 
          height: '16px' }),
        arrow: (base) => ({
          ...base,
          zIndex: 22000,
          width: '30px', 
          height: '30px' }),
      }}
      disableInteraction = {true}
      disableFocusLock = {false}
      showBadge= {false}
      showDots= {false}
      scrollSmooth={true}
      afterOpen={() => setTourRunning(true)}
      beforeClose={() => {
        console.log("Tour finished or closed");
        setTourRunning(false);
      }}
      onClickMask={() => {
        // No-op to prevent closing the tour on mask click
      }}   
    >
      <BaseModalContextProvider>
          <App />
      </BaseModalContextProvider>
    </TourProvider>
  );
};

root.render(
  <AppContextProvider>
    <CustomTourProvider>
      <Provider store={store}>
        <Router>
          <TourController />
        </Router>
      </Provider>
    </CustomTourProvider>
  </AppContextProvider>
);

reportWebVitals();
