import image1 from "./0000_european_16_male.webp";
import image2 from "./0001_european_20_female.webp";
import image3 from "./0002_european_35_female.webp";
import image4 from "./0003_european_20_female.webp";
import image5 from "./0004_european_35_male.webp";
import image6 from "./0005_european_50_female.webp";
import image7 from "./0006_european_20_female.webp";
import image8 from "./0007_european_55_male.webp";
import image9 from "./0008_european_60_female.webp";
// import image10 from "./00090_european_65_male.webp";
import image11 from "./0010_european_25_female.webp";
import image12 from "./0011_european_16_female.webp";
import image13 from "./0012_european_30_male.webp";
import image14 from "./0013_european_40_male.webp";
import image15 from "./0014_european_45_male.webp";
import image16 from "./0015_black_35_female.webp";
import image17 from "./0016_european_55_female.webp";
import image18 from "./0017_european_55_male.webp";
import image19 from "./0018_european_70_female.webp";
import image20 from "./0019_european_20_female.webp";
import image21 from "./0020_black_20_female.webp";
import image22 from "./0021_south_american_25_female.webp";
import image23 from "./0022_european_95_female.webp";
import image24 from "./0023_european_17_female.webp";
import image25 from "./0024_black_35_male.webp";
import image26 from "./0025_european_35_female.webp";
import image27 from "./0026_black_35_female.webp";
import image28 from "./0027_black_50_male.webp";
import image29 from "./0028_black_40_female.webp";
import image30 from "./0029_south_american_25_female.webp";
import image31 from "./0030_european_30_female.webp";
import image32 from "./0031_european_55_female.webp";
import image33 from "./0032_black_55_female.webp";
import image34 from "./0033_black_60_male.webp";
import image35 from "./0034_european_10_female.webp";
import image36 from "./0035_black_65_male.webp";
import image37 from "./0036_european_16_female.webp";
import image38 from "./0037_black_16_male.webp";
import image39 from "./0038_european_21_female.webp";
import image40 from "./0039_asian_21_female.webp";
import image41 from "./0040_black_25_male.webp";
import image42 from "./0041_european_25_female.webp";
import image43 from "./0042_black_25_female.webp";
import image44 from "./0043_european_30_female.webp";
import image45 from "./0044_european_70_female.webp";
import image46 from "./0045_black_30_male.webp";
import image47 from "./0046_black_14_female.webp";
import image48 from "./0047_black_40_male.webp";
import image49 from "./0048_black_27_female.webp";
import image50 from "./0049_black_40_male.webp";
import image51 from "./0050_black_28_female.webp";
import image52 from "./0051_european_45_female.webp";
import image53 from "./0052_black_55_male.webp";
import image54 from "./0053_black_70_male.webp";
import image55 from "./0054_south_american_20_male.webp";
import image56 from "./0055_european_35_female.webp";
// import image57 from "./0056_asian_35_female.webp";
import image58 from "./0057_south_american_35_male.webp";
import image59 from "./0058_south_american_50_male.webp";
import image60 from "./0059_european_50_female.webp";
import image61 from "./0060_south_american_55_male.webp";
import image62 from "./0061_european_60_female.webp";
import image63 from "./0062_asian_60_female.webp";
import image64 from "./0063_south_american_60_male.webp";
import image65 from "./0064_south_american_65_male.webp";
import image66 from "./0065_south_american_16_male.webp";
import image67 from "./0066_european_16_female.webp";
import image68 from "./0067_asian_16_female.webp";
import image69 from "./0068_south_american_25_male.webp";
import image70 from "./0069_european_25_female.webp";
import image71 from "./0070_asian_25_female.webp";
import image72 from "./0071_european_16_female.webp";
import image73 from "./0072_european_30_female.webp";
import image74 from "./0073_asian_30_female.webp";
import image75 from "./0074_south_american_30_male.webp";
import image76 from "./0075_south_american_30_female.webp";
import image77 from "./0076_european_40_female.webp";
import image78 from "./0077_asian_40_female.webp";
import image79 from "./0078_south_american_40_male.webp";
import image80 from "./0079_south_american_45_male.webp";
import image81 from "./0080_european_45_female.webp";
import image82 from "./0081_south_american_55_male.webp";
import image83 from "./0082_european_70_female.webp";
import image84 from "./0083_south_american_70_male.webp";
import image85 from "./0084_middle_eastern_20_male.webp";
import image86 from "./0085_middle_eastern_35_male.webp";
import image87 from "./0086_middle_eastern_50_male.webp";
import image88 from "./0087_middle_eastern_55_male.webp";
import image89 from "./0088_middle_eastern_60_male.webp";
import image90 from "./0089_south_american_65_female.webp";
import image91 from "./0090_european_40_female.webp";
import image92 from "./0091_middle_eastern_65_male.webp";
import image93 from "./0092_south_american_16_female.webp";
import image94 from "./0093_middle_eastern_14_male.webp";
import image95 from "./0094_middle_eastern_25_male.webp";
import image96 from "./0095_middle_eastern_30_male.webp";
import image97 from "./0096_south_american_30_female.webp";
import image98 from "./0097_south_american_40_female.webp";
import image99 from "./0098_middle_eastern_40_male.webp";
import image100 from "./0099_middle_eastern_45_male.webp";
import image101 from "./0100_middle_eastern_55_male.webp";
import image102 from "./0101_european_50_female.webp";
import image103 from "./0102_middle_eastern_70_male.webp";
import image104 from "./0103_south_american_70_female.webp";
import image105 from "./0104_asian_20_male.webp";
import image106 from "./0105_asian_35_male.webp";
import image107 from "./0106_asian_50_male.webp";
import image108 from "./0107_asian_55_male.webp";
import image109 from "./0108_european_23_female.webp";
import image110 from "./0109_asian_60_male.webp";
import image111 from "./0110_asian_65_male.webp";
import image112 from "./0111_nordic_65_female.webp";
import image113 from "./0112_european_20_female.webp";
import image114 from "./0113_nordic_16_female.webp";
import image115 from "./0114_asian_16_male.webp";
import image116 from "./0115_asian_25_male.webp";
import image117 from "./0116_nordic_25_female.webp";
import image118 from "./0117_asian_40_male.webp";
import image119 from "./0118_asian_45_male.webp";
import image120 from "./0119_nordic_45_female.webp";
import image121 from "./0120_european_20_female.webp";
import image122 from "./0121_nordic_70_female.webp";
import image123 from "./0122_european_20_female.webp";
import image124 from "./0123_middle_eastern_35_female.webp";
import image125 from "./0124_middle_eastern_50_female.webp";
import image126 from "./0125_middle_eastern_55_female.webp";
import image127 from "./0126_middle_eastern_60_female.webp";
import image128 from "./0127_middle_eastern_25_female.webp";
import image129 from "./0128_asian_21_female.webp";
import image130 from "./0129_middle_eastern_30_female.webp";
import image131 from "./0130_middle_eastern_40_female.webp";
import image132 from "./0131_asian_20_female.webp";
import image133 from "./0132_middle_eastern_45_female.webp";
import image134 from "./0133_asian_33_female.webp";
import image135 from "./0134_middle_eastern_55_female.webp";
import image136 from "./0135_middle_eastern_70_female.webp";
import image137 from "./0136_indian_28_male.webp";
import image138 from "./0137_indian_28_female.webp";
import image139 from "./0138_indian_30_male.webp";
import image140 from "./0139_indian_30_female.webp";
import image141 from "./0140_indian_32_male.webp";
// import image142 from "./0141_indian_32_female.webp";
// import image143 from "./0142_indian_35_male.webp";
import image144 from "./0143_indian_35_female.webp";
import image145 from "./0144_indian_16_male.webp";
import image146 from "./0145_indian_16_female.webp";
import image147 from "./0146_indian_20_male.webp";
import image148 from "./0147_indian_20_female.webp";
import image149 from "./0148_indian_24_male.webp";
import image150 from "./0149_indian_24_female.webp";
import image151 from "./0150_indian_38_male.webp";
import image152 from "./0151_indian_38_female.webp";
import image153 from "./0152_indian_45_male.webp";
import image154 from "./0153_indian_45_female.webp";
import image155 from "./0154_indian_60_male.webp";
import image156 from "./0155_indian_60_female.webp";
import image157 from "./0156_european_35_female.webp";
import image158 from "./0157_black_14_female.webp";
import image159 from "./0158_black_16_female.webp";
import image160 from "./0159_black_18_female.webp";
import image161 from "./0160_black_20_female.webp";
import image162 from "./0161_black_22_female.webp";
import image163 from "./0162_black_24_female.webp";
import image164 from "./0163_black_26_female.webp";
import image165 from "./0164_black_28_female.webp";
import image166 from "./0165_black_30_female.webp";
import image167 from "./0166_black_32_female.webp";
import image168 from "./0167_black_34_female.webp";
import image169 from "./0168_black_36_female.webp";
import image170 from "./0169_black_38_female.webp";
import image171 from "./0170_black_40_female.webp";
import image172 from "./0171_black_42_female.webp";
import image173 from "./0172_black_66_female.webp";
import image174 from "./0173_white_24_male.webp";
import image175 from "./0174_white_28_male.webp";

export const characters = [
    {
        "img": "0000_european_16_male.webp",
        "age": "16",
        "sex": "male",
        "ethnicity": "european",
        "face_prompt": "16 year old Peter Savonis with european complexion, with very short curly brown hair",
        "id": "0000",
        "name": "0000_european_16_male",
        "image": image1
    },
    {
        "img": "0001_european_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Elara Thorne as a 20 year old freckled european woman, with long curly red hair",
        "id": "0001",
        "name": "0001_european_20_female",
        "image": image2
    },
    {
        "img": "0002_european_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "(Sophia Warden) as a 35 year old gorgeous european woman, with straight brown hair just below the shoulders",
        "id": "0002",
        "name": "0002_european_35_female",
        "image": image3
    },
    {
        "img": "0003_european_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Callista Fairwood as a 20 year old european woman, long brown hair, little makeup, a little overweight",
        "id": "0003",
        "name": "0003_european_20_female",
        "image": image4
    },
    {
        "img": "0004_european_35_male.webp",
        "age": "35",
        "sex": "male",
        "ethnicity": "european",
        "face_prompt": "35 year old Christopher, with curly very short short brown hair with buzzed sides and european complexion",
        "id": "0004",
        "name": "0004_european_35_male",
        "image": image5
    },
    {
        "img": "0005_european_50_female.webp",
        "age": "50",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "50 year old beautiful (Hilda Spryer) with european look and wavy blonde hair",
        "id": "0005",
        "name": "0005_european_50_female",
        "image": image6
    },
    {
        "img": "0006_european_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Sienna Halloway as a gorgeous 20 year old european woman, (long blonde hair:1.1), little makeup",
        "id": "0006",
        "name": "0006_european_20_female",
        "image": image7
    },
    {
        "img": "0007_european_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "european",
        "face_prompt": "a man ([Karen Bilbao | Harris Nobs] as a (55 year old) european man), with very short straight silver hair, clean shaven",
        "id": "0007",
        "name": "0007_european_55_male",
        "image": image8
    },
    {
        "img": "0008_european_60_female.webp",
        "age": "60",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman([Eva Randall | Jessica Hardy] as a (60 year old) european woman) , with curly ear length (gray hair:1.1) ",
        "id": "0008",
        "name": "0008_european_60_female",
        "image": image9
    },
    // {
    //     "img": "00090_european_65_male.webp",
    //     "age": "65",
    //     "sex": "male",
    //     "ethnicity": "european",
    //     "face_prompt": "Peter Walzu as a 65 year old european man, with short gray hair and a long beard",
    //     "id": "0009",
    //     "name": "0009_european_65_male",
    //     "image": image10
    // },

    {
        "img": "0010_european_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Tamsin Vale as a 25 year old european woman, dark medium hair, little makeup, overweight",
        "id": "0010",
        "name": "0010_european_25_female",
        "image": image11
    },
    {
        "img": "0011_european_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "(Carla Baldwin) as a 16 year old supermodel european woman, with long blonde hair",
        "id": "0011",
        "name": "0011_european_16_female",
        "image": image12
    },
    {
        "img": "0012_european_30_male.webp",
        "age": "30",
        "sex": "male",
        "ethnicity": "european",
        "face_prompt": "a man ([Peter McCintosh | Howard Conings] as a 30 year old european man), with short blonde balding hair and a goatie beard",
        "id": "0012",
        "name": "0012_european_30_male",
        "image": image13
    },
    {
        "img": "0013_european_40_male.webp",
        "age": "40",
        "sex": "male",
        "ethnicity": "european",
        "face_prompt": "Sven Morrow as a 40 year old european man, with short wavy brown hair",
        "id": "0013",
        "name": "0013_european_40_male",
        "image": image14
    },
    {
        "img": "0014_european_45_male.webp",
        "age": "45",
        "sex": "male",
        "ethnicity": "european",
        "face_prompt": "a good looking man ([Braien Vaiksaar | Erik van Gils] as a 45 year old european man), with short straight blonde hair",
        "id": "0014",
        "name": "0014_european_45_male",
        "image": image15
    },
    {
        "img": "0015_black_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "[Idris Elbara | Kitty Honinger] as a 35 year old black woman, dark short hair, little makeup, fat",
        "id": "0015",
        "name": "0015_black_35_female",
        "image": image16
    },
    {
        "img": "0016_european_55_female.webp",
        "age": "55",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Mireille Sutherland as a 55 year old european woman, with short blonde hair",
        "id": "0016",
        "name": "0016_european_55_female",
        "image": image17
    },
    {
        "img": "0017_european_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "european",
        "face_prompt": "[Tom Andrews | Gregory Potts] as a 55 year old european man, with short wavy gray hair and a short white beard",
        "id": "0017",
        "name": "0017_european_55_male",
        "image": image18
    },
    {
        "img": "0018_european_70_female.webp",
        "age": "70",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Brielle Covington as a 70 year old european woman, with wavy silver hair and blue eyes",
        "id": "0018",
        "name": "0018_european_70_female",
        "image": image19
    },
    {
        "img": "0019_european_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Petra Jenkins | Uriella Cordasa] as a 20 year old european woman, with shoulder length curly dark hair in pigtails and green eyes",
        "id": "0019",
        "name": "0019_european_20_female",
        "image": image20
    },
    {
        "img": "0020_black_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "Tanisha Covington as a 20 year old cute black woman, with an afro haircut",
        "id": "0020",
        "name": "0020_black_20_female",
        "image": image21
    },
    {
        "img": "0021_south_american_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "Evangeline Sparrow as a 25 year old sexy gorgeous latina woman, dark shoulder length hair, wispy bangs",
        "id": "0021",
        "name": "0021_south_american_25_female",
        "image": image22
    },
    {
        "img": "0022_european_95_female.webp",
        "age": "95",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman [Rebecca Sweden | Tonya Sporado] as a 95 year old european woman, light grey hair in a bun, wrinkled face, happy, makeup",
        "id": "0022",
        "name": "0022_european_95_female",
        "image": image23
    },
    {
        "img": "0023_european_17_female.webp",
        "age": "17",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Evangeline Sparrow as a 17 year old timid white girl, amber chin length hair, wispy bangs",
        "id": "0023",
        "name": "0023_european_17_female",
        "image": image24
    },
    {
        "img": "0024_black_35_male.webp",
        "age": "35",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "[Ludwig Ramires | W. M. Henson] as a 35-year-old very good looking black man, with buzzed hair",
        "id": "0024",
        "name": "0024_black_35_male",
        "image": image25
    },
    {
        "img": "0025_european_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman [Petra Harris | Samantha Etter] as a 35 year old white woman, with shoulder-length black hair",
        "id": "0025",
        "name": "0025_european_35_female",
        "image": image26
    },
    {
        "img": "0026_black_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "Tunia Patusa as a 35 year old black woman, dark shoulder length hair",
        "id": "0026",
        "name": "0026_black_35_female",
        "image": image27
    },
    {
        "img": "0027_black_50_male.webp",
        "age": "50",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "a man ([Roger Rogerson | Kanye Washington] as a 50-year-old black man), with short gray hair",
        "id": "0027",
        "name": "0027_black_50_male",
        "image": image28
    },
    {
        "img": "0028_black_40_female.webp",
        "age": "40",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "Felicity Harrow as a 40 year old black woman, modern short hair",
        "id": "0028",
        "name": "0028_black_40_female",
        "image": image29
    },
    {
        "img": "0029_south_american_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "Else DeVille as a 25 year old gorgeous latina woman, dark shoulder length hair and heavy makeup",
        "id": "0029",
        "name": "0029_south_american_25_female",
        "image": image30
    },
    {
        "img": "0030_european_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Liora Hastings as a 30 year old gorgeous european woman with natural makeup, blonde long hair",
        "id": "0030",
        "name": "0030_european_30_female",
        "image": image31
    },
    {
        "img": "0031_european_55_female.webp",
        "age": "55",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Daniela Pautzer | Silvie Norimus] as a (55 year old:1.1) white woman, with short curly greying hair reaching the ears and blue eyes, wearing hoop earrings, wrinkles",
        "id": "0031",
        "name": "0031_european_55_female",
        "image": image32
    },
    {
        "img": "0032_black_55_female.webp",
        "age": "55",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "Wilma Rasmus as a 55 year old black woman, ponytail",
        "id": "0032",
        "name": "0032_black_55_female",
        "image": image33
    },
    {
        "img": "0033_black_60_male.webp",
        "age": "60",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "Erwin Crane as a 60-year-old black man, with bald head ",
        "id": "0033",
        "name": "0033_black_60_male",
        "image": image34
    },
    {
        "img": "0034_european_10_female.webp",
        "age": "10",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Jessica Robinson | Raicarda DiCaprio] as a 10 year old european girl, light medium hair",
        "id": "0034",
        "name": "0034_european_10_female",
        "image": image35
    },
    {
        "img": "0035_black_65_male.webp",
        "age": "65",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "a man ([Carl Hanson | Roberto Union] as a 65-year-old black man), with short afro hair and a weathered look",
        "id": "0035",
        "name": "0035_black_65_male",
        "image": image36
    },
    {
        "img": "0036_european_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a white woman ([Priska Barionah | Hannah Twain] as a 16 year old white girl), with braided black hair and friendly eyes",
        "id": "0036",
        "name": "0036_european_16_female",
        "image": image37
    },
    {
        "img": "0037_black_16_male.webp",
        "age": "16",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "[Mustafa Angston | Will Smouth] as a 16-year-old black man, with short curly black hair",
        "id": "0037",
        "name": "0037_black_16_male",
        "image": image38
    },
    {
        "img": "0038_european_21_female.webp",
        "age": "21",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Isolde Mercer as a 21 year old european woman, dark medium length hair, natural makeup, outdoor clothes",
        "id": "0038",
        "name": "0038_european_21_female",
        "image": image39
    },
    {
        "img": "0039_asian_21_female.webp",
        "age": "21",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Elysia Harcourt as a 21 year old asian woman, dark medium length hair, natural makeup, outdoor clothes",
        "id": "0039",
        "name": "0039_asian_21_female",
        "image": image40
    },
    {
        "img": "0040_black_25_male.webp",
        "age": "25",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "[Reto Martin | Ludwig Ramirez] as a 25-year-old black man, with short straight black hair",
        "id": "0040",
        "name": "0040_black_25_male",
        "image": image41
    },
    {
        "img": "0041_european_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Christina Glover | Naomi Jackson] as a 25 year old european woman, with long curly black hair",
        "id": "0041",
        "name": "0041_european_25_female",
        "image": image42
    },
    {
        "img": "0042_black_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "Synthia Riwalso as a 25 year old black woman, dark long hair, lots of makeup",
        "id": "0042",
        "name": "0042_black_25_female",
        "image": image43
    },
    {
        "img": "0043_european_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Michaele Rmshaw | Danielle Splice] as a supermodel type 30 year old european woman, with short blonde hair",
        "id": "0043",
        "name": "0043_european_30_female",
        "image": image44
    },
    {
        "img": "0044_european_70_female.webp",
        "age": "70",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Erika Hannes | Sophie Hunger] as a 70year old european woman , grey ear length hair",
        "id": "0044",
        "name": "0044_european_70_female",
        "image": image45
    },
    {
        "img": "0045_black_30_male.webp",
        "age": "30",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "[Kay Stenberg | William Monae] as a 30-year-old black man, with a short afro hair",
        "id": "0045",
        "name": "0045_black_30_male",
        "image": image46
    },
    {
        "img": "0046_black_14_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "Ego Otomas as a 14 year old black girl, pigtails",
        "id": "0046",
        "name": "0046_black_14_female",
        "image": image47
    },
    {
        "img": "0047_black_40_male.webp",
        "age": "40",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "[Walton Nyong'o | Richard W. Ross] as a 40-year-old black man, with short stylish african hair",
        "id": "0047",
        "name": "0047_black_40_male",
        "image": image48
    },
    {
        "img": "0048_black_27_female.webp",
        "age": "27",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "VAnessa Patusos as a 27 year old hot black woman, blonde long hair",
        "id": "0048",
        "name": "0048_black_27_female",
        "image": image49
    },
    {
        "img": "0049_black_40_male.webp",
        "age": "40",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "[Ludwig | Hannes Perrills] as a 45-year-old black man, with short black hair",
        "id": "0049",
        "name": "0049_black_40_male",
        "image": image50
    },
    {
        "img": "0050_black_28_female.webp",
        "age": "40",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "Sabine Kerch as a 28 year old nerdy black woman, dark short hair",
        "id": "0050",
        "name": "0050_black_28_female",
        "image": image51
    },
    {
        "img": "0051_european_45_female.webp",
        "age": "45",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Julia Norris| Desire Cowell] as a stunning 45 year old european woman, with very short shaggy black hair and blue eyes and a node piercing",
        "id": "0051",
        "name": "0051_european_45_female",
        "image": image52
    },
    {
        "img": "0052_black_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "David Nwosu as a 55-year-old black man, with a balding head and salt-and-pepper beard",
        "id": "0052",
        "name": "0052_black_55_male",
        "image": image53
    },
    {
        "img": "0053_black_70_male.webp",
        "age": "70",
        "sex": "male",
        "ethnicity": "black",
        "face_prompt": "[Carl Union | Robert Bassett] as a 70-year-old black man, with bald head",
        "id": "0053",
        "name": "0053_black_70_male",
        "image": image54
    },
    {
        "img": "0054_south_american_20_male.webp",
        "age": "20",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "[Ludwig Peroni | Juan Smorinsky], as a 20 year old south american man, with short dark hair and buzzed sides",
        "id": "0054",
        "name": "0054_south_american_20_male",
        "image": image55
    },
    {
        "img": "0055_european_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman ([Rahel Vollweider | Tanja Vasinova] as a slender 35 year old european woman), with light skin, long brown hair in a ponytail, wearing glasses",
        "id": "0055",
        "name": "0055_european_35_female",
        "image": image56
    },
    // {
    //     "img": "0056_asian_35_female.webp",
    //     "age": "35",
    //     "sex": "female",
    //     "ethnicity": "black",
    //     "face_prompt": "Kendra Malmosa as a 35 year old black woman, dark shoulder length hair, quisical, glasses",
    //     "id": "0056",
    //     "name": "0056_black_35_female",
    //     "image": image57
    // },
    {
        "img": "0057_south_american_35_male.webp",
        "age": "35",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "[Sanchez Cruz | Paolo Vergara], as a 35 year old perubian man, with short straight black hair",
        "id": "0057",
        "name": "0057_south_american_35_male",
        "image": image58
    },
    {
        "img": "0058_south_american_50_male.webp",
        "age": "50",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "[Rodriguez Mendes | Arthur Mandela], as a 50 year old chilean man, with very short greying hair",
        "id": "0058",
        "name": "0058_south_american_50_male",
        "image":image59
    },
    {
        "img": "0059_european_50_female.webp",
        "age": "50",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Ariella Montana | Eve Herzigova] as a 50 year old european woman, with straight black hair shoulder length hair",
        "id": "0059",
        "name": "0059_european_50_female",
        "image": image60
    },
    {
        "img": "0060_south_american_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "George Longoria, as a 55 year old south american man, with short greying hair",
        "id": "0060",
        "name": "0060_south_american_55_male",
        "image": image61
    },
    {
        "img": "0061_european_60_female.webp",
        "age": "60",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Kendra Smith | Shannon Soberty] as a 60 year old european woman, with medium length curly grey hair",
        "id": "0061",
        "name": "0061_european_60_female",
        "image": image62
    },
    {
        "img": "0062_asian_60_female.webp",
        "age": "60",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Harriet Rommel as a 60 year old black woman, short grey hair",
        "id": "0062",
        "name": "0062_asian_60_female",
        "image": image63
    },
    {
        "img": "0063_south_american_60_male.webp",
        "age": "60",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "[Walton Dawson | Peter Gomez], as a 60 year old south american man, with straight short greying hair and a three day beard",
        "id": "0063",
        "name": "0063_south_american_60_male",
        "image": image64
    },
    {
        "img": "0064_south_american_65_male.webp",
        "age": "65",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "[Henry Tigen | Andrew Pascales], as a 65 year old mexican man, with short wavy silver hair, wrinkles,tanned, happy",
        "id": "0064",
        "name": "0064_south_american_65_male",
        "image": image65
    },
    {
        "img": "0065_south_american_16_male.webp",
        "age": "16",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "Aleksandr Cho, as a 16 year old south american man, with short brown hair",
        "id": "0065",
        "name": "0065_south_american_16_male",
        "image": image66
    },
    {
        "img": "0066_european_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Patricia Villiger | Magdalena Bruskowa] as a 16 year old european woman, with medium length blonde hair",
        "id": "0066",
        "name": "0066_european_16_female",
        "image": image67
    },
    {
        "img": "0067_asian_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Yu Yo  as a 35 year old asian woman, black shoulder length straight cut hair",
        "id": "0067",
        "name": "0067_asian_16_female",
        "image": image68
    },
    {
        "img": "0068_south_american_25_male.webp",
        "age": "25",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "Leonidas Mwang, as a 25 year old south american man, with straight black hair and hazel eyes",
        "id": "0068",
        "name": "0068_south_american_25_male",
        "image": image69
    },
    {
        "img": "0069_european_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman ([Samantha POowell | Jessica Arba] as a 25 year old (caucasian woman:1.2)), with a very short brown straight bob haircut with straight bangs and a shaved nape",
        "id": "0069",
        "name": "0069_european_25_female",
        "image": image70
    },
    {
        "img": "0070_asian_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Hanyo Yogoshi as a cute 25 year old korean woman, short punky hair hair",
        "id": "0070",
        "name": "0070_asian_25_female",
        "image": image71
    },
    {
        "img": "0071_european_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Marcella Beaumont as a 16 year old european woman , nerdy look, two long dark braids, cute, thick glasses, braces, no makeup",
        "id": "0071",
        "name": "0071_european_16_female",
        "image": image72
    },
    {
        "img": "0072_european_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Kendra Ayles | Vanessa Duchard] as a 30 year old european woman, with long wavy brown hair",
        "id": "0072",
        "name": "0072_european_30_female",
        "image": image73
    },
    {
        "img": "0073_asian_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Ming Ying as a 30 year old north korean woman, dark shoulder length hair",
        "id": "0073",
        "name": "0073_asian_30_female",
        "image": image74
    },
    {
        "img": "0074_south_american_30_male.webp",
        "age": "30",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "Andre Santos-Mu\u00c3\u00b1oz, as a 30 year old south american man, with short brown hair, glasses",
        "id": "0074",
        "name": "0074_south_american_30_male",
        "image": image75
    },
    {
        "img": "0075_south_american_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "Marina Lopez as a 30 year old south american woman, wild medium hair",
        "id": "0075",
        "name": "0075_south_american_30_female",
        "image": image76
    },
    {
        "img": "0076_european_40_female.webp",
        "age": "40",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman Giselle Wakefield as an extremely atractive 40 year old european woman, with straight black shoulder length hair",
        "id": "0076",
        "name": "0076_european_40_female",
        "image": image77
    },
    {
        "img": "0077_asian_40_female.webp",
        "age": "40",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Tunia Patusa as a 40 year old philipino woman, chin length standard haircut",
        "id": "0077",
        "name": "0077_asian_40_female",
        "image": image78
    },
    {
        "img": "0078_south_american_40_male.webp",
        "age": "40",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "[Gustavo Paloma | Henry McMan], as a 40 year old south american man, with straight black hair",
        "id": "0078",
        "name": "0078_south_american_40_male",
        "image": image79
    },
    {
        "img": "0079_south_american_45_male.webp",
        "age": "45",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "Juan Fatguet, as a good looking 45 year old south american man, with short wavy salt-and-pepper hair",
        "id": "0079",
        "name": "0079_south_american_45_male",
        "image": image80
    },
    {
        "img": "0080_european_45_female.webp",
        "age": "45",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman ([Pamela Rivera | Kim Hae-Sung] as a 45 year old european woman), with curly black hair reaching her ears and green eyes, heavy makeup",
        "id": "0080",
        "name": "0080_european_45_female",
        "image": image81
    },
    {
        "img": "0081_south_american_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "[Philipp Martinez | Juan Carlos Depalma], as a 55 year old south american man, with short salt-and-pepper hair",
        "id": "0081",
        "name": "0081_south_american_55_male",
        "image": image82
    },
    {
        "img": "0082_european_70_female.webp",
        "age": "70",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "[Chow Hun-Fat | Kendra Watanabe] as a 70 year old european woman, with straight european hair ",
        "id": "0082",
        "name": "0082_european_70_female",
        "image": image83
    },
    {
        "img": "0083_south_american_70_male.webp",
        "age": "70",
        "sex": "male",
        "ethnicity": "south_american",
        "face_prompt": "Dariusz Patel, as a 70 year old south american man, with very short curly grey hair and a friendly complexion",
        "id": "0083",
        "name": "0083_south_american_70_male",
        "image": image84
    },
    {
        "img": "0084_middle_eastern_20_male.webp",
        "age": "20",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "a man ([Hank Frasor | Roger Gunman] as a 20 year old Middle Eastern man), with short curly black hair and a short goatee beard",
        "id": "0084",
        "name": "0084_middle_eastern_20_male",
        "image": image85
    },
    {
        "img": "0085_middle_eastern_35_male.webp",
        "age": "35",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Achmed Szedan | Habib Cotzil] as a 35 year old Middle Eastern man, with short straight dark hair",
        "id": "0085",
        "name": "0085_middle_eastern_35_male",
        "image": image86
    },
    {
        "img": "0086_middle_eastern_50_male.webp",
        "age": "50",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Pedro Consalez | Wilson Humphrey] as a 50 year old Middle Eastern man, with wavy short salt-and-pepper hair and a friendly face",
        "id": "0086",
        "name": "0086_middle_eastern_50_male",
        "image": image87
    },
    {
        "img": "0087_middle_eastern_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "a man [Rudger Navaly | Jesus Theron] as a 55 year old Middle Eastern man, with short straight silver hair",
        "id": "0087",
        "name": "0087_middle_eastern_55_male",
        "image": image88
    },
    {
        "img": "0088_middle_eastern_60_male.webp",
        "age": "60",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Sebastian Morello | Yu Han] as a 60 year old Middle Eastern man, with short curly gray hair",
        "id": "0088",
        "name": "0088_middle_eastern_60_male",
        "image": image89
    },
    {
        "img": "0089_south_american_65_female.webp",
        "age": "65",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "Leandra Sterling as a 65 year old south american woman, with wavy gray hair ",
        "id": "0089",
        "name": "0089_south_american_65_female",
        "image": image90
    },
    {
        "img": "0090_european_40_female.webp",
        "age": "40",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Odessa Blackwell as a 40 year old european woman, ear length hair, no makeup, housewife look",
        "id": "0090",
        "name": "0090_european_40_female",
        "image": image91
    },
    {
        "img": "0091_middle_eastern_65_male.webp",
        "age": "65",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Hamide Hasiro | Jesus Gantalios] as a 65 year old Middle Eastern man, with short grey hair and a short grey beard and kind eyes",
        "id": "0091",
        "name": "0091_middle_eastern_65_male",
        "image": image92
    },
    {
        "img": "0092_south_american_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "Penelope Ramirez (a very attractive 16 year old south american woman), with curly black hair",
        "id": "0092",
        "name": "0092_south_american_16_female",
        "image": image93
    },
    {
        "img": "0093_middle_eastern_14_male.webp",
        "age": "14",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "a man [Pablino Charaz | Achmud Achmad] as a 14 year old Middle Eastern boy, with short curly brown hair",
        "id": "0093",
        "name": "0093_middle_eastern_14_male",
        "image": image94
    },
    {
        "img": "0094_middle_eastern_25_male.webp",
        "age": "25",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Frank Blunt | Keira Kinosis] as a 25 year old Middle Eastern man, with very short straight black hair",
        "id": "0094",
        "name": "0094_middle_eastern_25_male",
        "image": image95
    },
    {
        "img": "0095_middle_eastern_30_male.webp",
        "age": "30",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "a man ([Roberto Ramirez | Tom Langer] as a 30 year old muscular middle eastern man), with wavy short brown hair and a mustache",
        "id": "0095",
        "name": "0095_middle_eastern_30_male",
        "image": image96
    },
    {
        "img": "0096_south_american_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "Hilda Degaborez as a 30 year old stunningly beautiful south american woman, with long wavy black hair",
        "id": "0096",
        "name": "0096_south_american_30_female",
        "image": image97
    },
    {
        "img": "0097_south_american_40_female.webp",
        "age": "40",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "[Sabine Regan | Samatha Cruz] portraying a 40 year old south american woman, with curly brown hair",
        "id": "0097",
        "name": "0097_south_american_40_female",
        "image": image98
    },
    {
        "img": "0098_middle_eastern_40_male.webp",
        "age": "40",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "[HAsan Palmirez | Tony Stephenson] as a 40 year old Middle Eastern man, with straight short brown hair",
        "id": "0098",
        "name": "0098_middle_eastern_40_male",
        "image": image99
    },
    {
        "img": "0099_middle_eastern_45_male.webp",
        "age": "45",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "a man [Haldwin Geronimo | Estephan Marcos] as a 45 year old middle Eastern man with deep lines and short salt-and-pepper hair",
        "id": "0099",
        "name": "0099_middle_eastern_45_male",
        "image": image100
    },
    {
        "img": "0100_middle_eastern_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "Rhiannon Eldridge as a 55 year old Middle Eastern man, with short silver hair",
        "id": "0100",
        "name": "0100_middle_eastern_55_male",
        "image": image101
    },
    {
        "img": "0101_european_50_female.webp",
        "age": "50",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Althea Redwood as a 50 year old european woman, shoulder length blonde hair, little makeup",
        "id": "0101",
        "name": "0101_european_50_female",
        "image": image102
    },
    {
        "img": "0102_middle_eastern_70_male.webp",
        "age": "70",
        "sex": "male",
        "ethnicity": "middle_eastern",
        "face_prompt": "a man [Hunter Streep | Wasimir Slovenko] as a overweight 70 year old Middle Eastern man, with thin grey hair",
        "id": "0102",
        "name": "0102_middle_eastern_70_male",
        "image": image103
    },
    {
        "img": "0103_south_american_70_female.webp",
        "age": "70",
        "sex": "female",
        "ethnicity": "south_american",
        "face_prompt": "Celeste Whitmore as a 70 year old south american woman, with straight gray hair and hazel eyes",
        "id": "0103",
        "name": "0103_south_american_70_female",
        "image": image104
    },
    {
        "img": "0104_asian_20_male.webp",
        "age": "20",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "Haruto Takahashi as a 20 year old asian man, with short thick black hair",
        "id": "0104",
        "name": "0104_asian_20_male",
        "image": image105
    },
    {
        "img": "0105_asian_35_male.webp",
        "age": "35",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "[Peter Johansson | Paul Lawrence] as an attractive 35 year old asian man, with wavy medium short black hair",
        "id": "0105",
        "name": "0105_asian_35_male",
        "image": image106
    },
    {
        "img": "0106_asian_50_male.webp",
        "age": "50",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "a man ([Hiro Naguso | Mioto Kazumo] as a very good looking 50 year old asian man), with short salt-and-pepper hair and a short beard",
        "id": "0106",
        "name": "0106_asian_50_male",
        "image": image107
    },
    {
        "img": "0107_asian_55_male.webp",
        "age": "55",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "[Hazimo Osaka | Osuzo Berry] as a 55 year old asian man, with short black hair",
        "id": "0107",
        "name": "0107_asian_55_male",
        "image": image108
    },
    {
        "img": "0108_european_23_female.webp",
        "age": "23",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Sinead O'Reilly as a 23 year old european woman, long brown hair, bangs, little makeup",
        "id": "0108",
        "name": "0108_european_23_female",
        "image": image109
    },
    {
        "img": "0109_asian_60_male.webp",
        "age": "60",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "Tien Pham as a 60 year old asian man, with short straight gray hair with a side part",
        "id": "0109",
        "name": "0109_asian_60_male",
        "image": image110
    },
    {
        "img": "0110_asian_65_male.webp",
        "age": "65",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "Pichai Thirakul as a 65 year old asian man, with short wavy grey hair",
        "id": "0110",
        "name": "0110_asian_65_male",
        "image": image111
    },
    {
        "img": "0111_nordic_65_female.webp",
        "age": "65",
        "sex": "female",
        "ethnicity": "nordic",
        "face_prompt": "[Evelyn Stormare | Talia Wollter] as a 65 year old Nordic woman, with straight grey hair, golden ear rings",
        "id": "0111",
        "name": "0111_nordic_65_female",
        "image": image112
    },
    {
        "img": "0112_european_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Chiara Ferraro as a 20 year old stunningly beautiful italian woman, long brown hair, little makeup",
        "id": "0112",
        "name": "0112_european_20_female",
        "image": image113
    },
    {
        "img": "0113_nordic_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "nordic",
        "face_prompt": "Alina Popescu as a 16 year old Nordic woman, with long blonde hair and blue eyes",
        "id": "0113",
        "name": "0113_nordic_16_female",
        "image": image114
    },
    {
        "img": "0114_asian_16_male.webp",
        "age": "16",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "[Elwin Pelwin | Walton Cruise] as a 16 year old asian man, with very short straight black hair fashionable",
        "id": "0114",
        "name": "0114_asian_16_male",
        "image": image115
    },
    {
        "img": "0115_asian_25_male.webp",
        "age": "25",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "[Hendrik Cazares | Ulrich Wiesen] as a 25 year old asian man, with wavy short black hair",
        "id": "0115",
        "name": "0115_asian_25_male",
        "image": image116
    },
    {
        "img": "0116_nordic_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "nordic",
        "face_prompt": "[Alexander Ludwig | Kjell Bergqvist] as a 25 year old Nordic woman, with straight blonde hair and green eyes",
        "id": "0116",
        "name": "0116_nordic_25_female",
        "image": image117
    },
    {
        "img": "0117_asian_40_male.webp",
        "age": "40",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "[Hannes Grunz | Travis Menson] as a 40 year old asian man, with straight very short black hair",
        "id": "0117",
        "name": "0117_asian_40_male",
        "image": image118
    },
    {
        "img": "0118_asian_45_male.webp",
        "age": "45",
        "sex": "male",
        "ethnicity": "asian",
        "face_prompt": "[Kevin Martinez | Gonzales Devigo] as a 45 year old asian man, with short black hair",
        "id": "0118",
        "name": "0118_asian_45_male",
        "image": image119
    },
    {
        "img": "0119_nordic_45_female.webp",
        "age": "45",
        "sex": "female",
        "ethnicity": "nordic",
        "face_prompt": "Emilie J\u00c3\u00b8rgensen as a 45 year old nordic woman, with straight blonde hair and blue eyes",
        "id": "0119",
        "name": "0119_nordic_45_female",
        "image": image120
    },
    {
        "img": "0120_european_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "Nadia Zaytsev as a 20 year old european woman, (very short light brown hair:1.2), little makeup, a little overweight",
        "id": "0120",
        "name": "0120_european_20_female",
        "image": image121
    },
    {
        "img": "0121_nordic_70_female.webp",
        "age": "70",
        "sex": "female",
        "ethnicity": "nordic",
        "face_prompt": "a woman ([Petra Mortensen | Silvia Stormare] as a 70 year old Nordic woman), with wavy shoulder length grey hair with bangs and blue eyes",
        "id": "0121",
        "name": "0121_nordic_70_female",
        "image": image122
    },
    {
        "img": "0122_european_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a woman ([Tanja Pine | Valerie Momoa] as a gorgeous 20 year old swiss woman), short blonde hair, slim",
        "id": "0122",
        "name": "0122_european_20_female",
        "image": image123
    },
    {
        "img": "0123_middle_eastern_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "Fariha Khan as a stunningly beautiful 35 year old Middle Eastern woman, with curly black hair",
        "id": "0123",
        "name": "0123_middle_eastern_35_female",
        "image": image124
    },
    {
        "img": "0124_middle_eastern_50_female.webp",
        "age": "50",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "Amirul Rahma as a tasteful 50 year old Middle Eastern woman, with straight gray hair",
        "id": "0124",
        "name": "0124_middle_eastern_50_female",
        "image": image125
    },
    {
        "img": "0125_middle_eastern_55_female.webp",
        "age": "55",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "Nasir Ahmad as a 55 year old Middle Eastern woman, with wavy salt-and-pepper hair",
        "id": "0125",
        "name": "0125_middle_eastern_55_female",
        "image": image126
    },
    {
        "img": "0126_middle_eastern_60_female.webp",
        "age": "60",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "Zara Mahmood as a 60 year old Middle Eastern woman, with straight silver hair ",
        "id": "0126",
        "name": "0126_middle_eastern_60_female",
        "image": image127
    },
    {
        "img": "0127_middle_eastern_25_female.webp",
        "age": "25",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "Regula Siddig as a 25 year old attractive Middle Eastern woman, with curly brown hair and a warm smile",
        "id": "0127",
        "name": "0127_middle_eastern_25_female",
        "image": image128
    },
    {
        "img": "0128_asian_21_female.webp",
        "age": "21",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Yuna Tanaka as a 21 year old asian woman, very short bob haircut with bangs, natural makeup",
        "id": "0128",
        "name": "0128_asian_21_female",
        "image": image129
    },
    {
        "img": "0129_middle_eastern_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "Anna Smirnova as a 30 year old Middle Eastern woman, with wavy black hair and dark brown eyes",
        "id": "0129",
        "name": "0129_middle_eastern_30_female",
        "image": image130
    },
    {
        "img": "0130_middle_eastern_40_female.webp",
        "age": "40",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Midra Hamada | Naveena Andrews] as a 40 year old Middle Eastern woman, with straight brown hair and green eyes",
        "id": "0130",
        "name": "0130_middle_eastern_40_female",
        "image": image131
    },
    {
        "img": "0131_asian_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Kyung-soon Lee as a 20 year old korean woman, short black hair, makeup, beautiful face, supermodel",
        "id": "0131",
        "name": "0131_asian_20_female",
        "image": image132
    },
    {
        "img": "0132_middle_eastern_45_female.webp",
        "age": "45",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Oded Fehr | Khaled Abol Naga] as a 45 year old Middle Eastern woman, with curly salt-and-pepper hair ",
        "id": "0132",
        "name": "0132_middle_eastern_45_female",
        "image": image133
    },
    {
        "img": "0133_asian_33_female.webp",
        "age": "33",
        "sex": "female",
        "ethnicity": "asian",
        "face_prompt": "Yu Ymamoto as a 33 year old japanese woman, black shoulder length hair, natural makeup",
        "id": "0133",
        "name": "0133_asian_33_female",
        "image": image134
    },
    {
        "img": "0134_middle_eastern_55_female.webp",
        "age": "55",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "[Yahya Abdul | Hazuri Sleiman] as a 55 year old Middle Eastern woman, with short black hair",
        "id": "0134",
        "name": "0134_middle_eastern_55_female",
        "image": image135
    },
    {
        "img": "0135_middle_eastern_70_female.webp",
        "age": "70",
        "sex": "female",
        "ethnicity": "middle_eastern",
        "face_prompt": "Noor Abdullah as a 70 year old Middle Eastern woman, with wavy european hairs",
        "id": "0135",
        "name": "0135_middle_eastern_70_female",
        "image": image136
    },
    {
        "img": "0136_indian_28_male.webp",
        "age": "28",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Ranbir Kapoor | Siddharth Malhotra] as a 28 year old Indian man with short, wavy black hair",
        "id": "0136",
        "name": "0136_indian_28_male",
        "image": image137
    },
    {
        "img": "0137_indian_28_female.webp",
        "age": "28",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Disha Patani | Kiara Advani] as a 28 year old Indian woman with long straight black hair",
        "id": "0137",
        "name": "0137_indian_28_female",
        "image": image138
    },
    {
        "img": "0138_indian_30_male.webp",
        "age": "30",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Ayushmann Khurrana | Rajkummar Rao] as a 30 year old Indian man with a short, neatly combed hairstyle and a light beard",
        "id": "0138",
        "name": "0138_indian_30_male",
        "image": image139
    },
    {
        "img": "0139_indian_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "Yami Gautam as a stunning bollywood star 30 year old Indian woman with mid-length curly black hair and expressive eyes",
        "id": "0139",
        "name": "0139_indian_30_female",
        "image": image140
    },
    {
        "img": "0140_indian_32_male.webp",
        "age": "32",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "Kartik Aaryan as a 32 year old Indian man with a buzzed haircut",
        "id": "0140",
        "name": "0140_indian_32_male",
        "image": image141
    },
    {
        "img": "0141_indian_32_female.webp",
        "age": "32",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Bhumi Pednekar | Kriti Sanon] as a 32 year old Indian woman with long wavy black hair",
        "id": "0141",
        "name": "0141_indian_32_female",
        "image": image147
    },
    {
        "img": "0142_indian_35_male.webp",
        "age": "35",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Arjun Kapoor | Aditya Roy Kapur] as a 35 year old Indian man with medium-length messy black hair and a rugged look",
        "id": "0142",
        "name": "0142_indian_35_male",
        "image": image14
    },
    {
        "img": "0143_indian_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Sonakshi Sinha | Sonam Kapoor] as a 35 year old slim Indian woman with a sleek ponytail and a graceful demeanor",
        "id": "0143",
        "name": "0143_indian_35_female",
        "image": image144
    },
    {
        "img": "0144_indian_16_male.webp",
        "age": "16",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Ishaan Khatter | Anmol Thakeria Dhillon] as a 16 year old Indian boy with tousled black hair and a youthful glow",
        "id": "0144",
        "name": "0144_indian_16_male",
        "image": image145
    },
    {
        "img": "0145_indian_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Ananya Panday | Sara Ali Khan] as a 16 year old Indian girl with long, flowing black hair and a bright smile",
        "id": "0145",
        "name": "0145_indian_16_female",
        "image": image146
    },
    {
        "img": "0146_indian_20_male.webp",
        "age": "20",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Ahaan Shetty | Aryan Khan] as a 20 year old Indian man with a short, spiky hairstyle and an energetic presence",
        "id": "0146",
        "name": "0146_indian_20_male",
        "image": image147
    },
    {
        "img": "0147_indian_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Janhvi Kapoor | Tara Sutaria] as a 20 year old Indian woman with a long, braided black hairstyle and a radiant look",
        "id": "0147",
        "name": "0147_indian_20_female",
        "image": image148
    },
    {
        "img": "0148_indian_24_male.webp",
        "age": "24",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Karan Deol | Dhruv Vikram] as a 24 year old Indian man with medium-length black hair and a determined gaze",
        "id": "0148",
        "name": "0148_indian_24_male",
        "image": image149
    },
    {
        "img": "0149_indian_24_female.webp",
        "age": "24",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Shanaya Kapoor | Banita Sandhu] as a 24 year old Indian woman with a chic bob cut and a modern flair",
        "id": "0149",
        "name": "0149_indian_24_female",
        "image": image150
    },
    {
        "img": "0150_indian_38_male.webp",
        "age": "38",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Farhan Akhtar | Abhishek Bachchan] as a 38 year old Indian man with a short, groomed beard and slightly wavy black hair",
        "id": "0150",
        "name": "0150_indian_38_male",
        "image": image151
    },
    {
        "img": "0151_indian_38_female.webp",
        "age": "38",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Dia Mirza | Chitrangada Singh] as a 38 year old Indian woman with a long, layered black hairstyle and a poised expression",
        "id": "0151",
        "name": "0151_indian_38_female",
        "image": image152
    },
    {
        "img": "0152_indian_45_male.webp",
        "age": "45",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Hrithik Roshan | John Abraham] as a 45 year old Indian man with short, styled black hair showing early signs of greying",
        "id": "0152",
        "name": "0152_indian_45_male",
        "image": image153
    },
    {
        "img": "0153_indian_45_female.webp",
        "age": "45",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": "[Sushmita Sen | Aishwarya Rai] as a 45 year old Indian woman with mid-length wavy black hair and an elegant presence",
        "id": "0153",
        "name": "0153_indian_45_female",
        "image": image154
    },
    {
        "img": "0154_indian_60_male.webp",
        "age": "60",
        "sex": "male",
        "ethnicity": "indian",
        "face_prompt": "[Kamal Haasan | Boman Irani] as a 60 year old Indian man with salt-and-pepper hair and a wise demeanor",
        "id": "0154",
        "name": "0154_indian_60_male",
        "image": image155
    },
    {
        "img": "0155--",
        "age": "60",
        "sex": "female",
        "ethnicity": "indian",
        "face_prompt": " Kirron Kher as a 60 year old Indian woman with gracefully aging features and short white hair",
        "id": "0155",
        "name": "0155_indian_60_female",
        "image": image156
    },
    {
        "img": "0156_european_35_female.webp",
        "age": "35",
        "sex": "female",
        "ethnicity": "european",
        "face_prompt": "a man Christopher Mouser as a 35 year old european man, with curly short brown hair",
        "id": "0156",
        "name": "0156_european_35_female",
        "image": image157
    },
    {
        "img": "0157_black_14_female.webp",
        "age": "14",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Keisha Brown | Tia Mitchell] as a (14 year old) black woman) , with curly shoulder length (black hair:1.1) ",
        "id": "0157",
        "name": "0157_black_14_female",
        "image": image158
    },
    {
        "img": "0158_black_16_female.webp",
        "age": "16",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Aaliyah Johnson | Brianna Harris] as a (16 year old) black woman) , with straight short (black hair:1.1) ",
        "id": "0158",
        "name": "0158_black_16_female",
        "image": image159
    },
    {
        "img": "0159_black_18_female.webp",
        "age": "18",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Naomi Wilson | Jasmine Taylor] as a (18 year old) black woman) , with braided long (black hair:1.1) ",
        "id": "0159",
        "name": "0159_black_18_female",
        "image": image160
    },
    {
        "img": "0160_black_20_female.webp",
        "age": "20",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Lauren Smith | Kayla Davis] as a (20 year old) black woman) , with curly short (black hair:1.1) ",
        "id": "0160",
        "name": "0160_black_20_female",
        "image": image161
    },
    {
        "img": "0161_black_22_female.webp",
        "age": "22",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Ashley White | Sierra Jackson] as a (22 year old) black woman) , with straight long (black hair:1.1) ",
        "id": "0161",
        "name": "0161_black_22_female",
        "image": image162
    },
    {
        "img": "0162_black_24_female.webp",
        "age": "24",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Brittany Clark | Monica Robinson] as a (24 year old) black woman) , with braided short (black hair:1.1) ",
        "id": "0162",
        "name": "0162_black_24_female",
        "image": image163
    },
    {
        "img": "0163_black_26_female.webp",
        "age": "26",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "Kendra Hall as a supermodel (26 year old) black woman, with curly medium length (black hair:1.1) ",
        "id": "0163",
        "name": "0163_black_26_female",
        "image": image164
    },
    {
        "img": "0164_black_28_female.webp",
        "age": "28",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Whitney Adams | Kiara Nelson] as a overweight (28 year old) black woman) , with straight medium length (black hair:1.1) ",
        "id": "0164",
        "name": "0164_black_28_female",
        "image": image165
    },
    {
        "img": "0165_black_30_female.webp",
        "age": "30",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Maya Baker | Simone Carter] as a (30 year old) black woman) , with braided medium length (black hair:1.1) ",
        "id": "0165",
        "name": "0165_black_30_female",
        "image": image166
    },
    {
        "img": "0166_black_32_female.webp",
        "age": "32",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Kristen Young | Tasha Turner] as a (32 year old) black woman) , with curly short (black hair:1.1) ",
        "id": "0166",
        "name": "0166_black_32_female",
        "image": image167
    },
    {
        "img": "0167_black_34_female.webp",
        "age": "34",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Chloe Parker | Asia Phillips] as a stunning (34 year old) black woman) , with straight short (black hair:1.1) ",
        "id": "0167",
        "name": "0167_black_34_female",
        "image": image168
    },
    {
        "img": "0168_black_36_female.webp",
        "age": "36",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Carla Campbell | Latoya Mitchell] as a average (36 year old) black woman) , with braided long (black hair:1.1) ",
        "id": "0168",
        "name": "0168_black_36_female",
        "image": image169
    },
    {
        "img": "0169_black_38_female.webp",
        "age": "38",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Marie Howard | Latasha Henderson] as a (38 year old) black woman) , with curly medium length (black hair:1.1) ",
        "id": "0169",
        "name": "0169_black_38_female",
        "image": image170
    },
    {
        "img": "0170_black_40_female.webp",
        "age": "40",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Linda Bailey | Tara Patterson] as a radiant (40 year old) black woman) , with straight long (black hair:1.1) ",
        "id": "0170",
        "name": "0170_black_40_female",
        "image": image171
    },
    {
        "img": "0171_black_42_female.webp",
        "age": "42",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Sandra Hughes | Tamika Sanders] as a (42 year old) black woman) , with braided short (black hair:1.1) ",
        "id": "0171",
        "name": "0171_black_42_female",
        "image": image172
    },
    {
        "img": "0172_black_66_female.webp",
        "age": "66",
        "sex": "female",
        "ethnicity": "black",
        "face_prompt": "a woman ([Gloria Stevens | Deborah Robinson] as a (66 year old) black woman) , with curly short (gray hair:1.1) and brown eyes",
        "id": "0172",
        "name": "0172_black_66_female",
        "image": image173
    },
    {
        "img": "0173_white_24_male.webp",
        "age": "24",
        "sex": "male",
        "ethnicity": "white",
        "face_prompt": "a man ([Jake Morrison | Liam Wallace] as a (24 year old) white man) , with thick, wavy, chestnut hair that is kept short on the sides and longer on top, styled in a modern quiff",
        "id": "0173",
        "name": "0173_white_24_male",
        "image": image174
    },
    {
        "img": "0174_white_28_male.webp",
        "age": "28",
        "sex": "male",
        "ethnicity": "white",
        "face_prompt": "a man ([Ryan Harper | Evan Mitchell] as a (28 year old) white man) , with dark blonde hair that is medium length, slightly curled at the ends, and styled in a relaxed, tousled manner",
        "id": "0174",
        "name": "0174_white_28_male",
        "image": image175
    }
];