export const FREE_STYLE_INDICES = ["1", "68", "53"];

export const stylesData = [
    {
      index: "1",
      clientIndex: 1,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style01.webp",
      title: "Ink Intensity",
      description: ""
    },
    {
      index: "16",
      clientIndex: 2,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style02.webp",
      title: "Dynamic Simplicity",
      description: ""
    },
    {
      index: "5",
      clientIndex: 3,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style03.webp",
      title: "Sweeping Elegance",
      description: ""
    },
    {
      index: "59",
      clientIndex: 4,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style04.webp",
      title: "Ethereal Washes",
      description: ""
    },
    {
      index: "91",
      clientIndex: 5,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_5_91.webp",
      title: "Vibrant Vectorcraft",
      description: ""
    },
    {
      index: "2",
      clientIndex: 6,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_6_2.webp",
      title: "Vivid Portraiture",
      description: ""
    },
    {
      index: "68",
      clientIndex: 7,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_7_68.webp",
      title: "Everyday Vibes",
      description: ""
    },
    {
      index: "82",
      clientIndex: 8,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_8_82.webp",
      title: "Stellar Sketch",
      description: ""
    },
    {
      index: "48",
      clientIndex: 9,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_9_48.webp",
      title: "Dynamic Scenes",
      description: ""
    },
    {
      index: "53",
      clientIndex: 10,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_10_53.webp",
      title: "City Chronicles",
      description: ""
    },
    {
      index: "99",
      clientIndex: 11,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_11_99.webp",
      title: "Illustrated Detail",
      description: ""
    },
    {
      index: "100",
      clientIndex: 12,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_12_100.webp",
      title: "Dark Narrative",
      description: ""
    },
    {
      index: "101",
      clientIndex: 13,
      img: "https://story-boards-static-dev.s3.us-west-1.amazonaws.com/styles/style_13_101.webp",
      title: "Linear Perspective",
      description: ""
    }
  ];