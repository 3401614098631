import React, { useState, useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import { TReduxStore } from "../../../core/redux/store";
import { useBaseModalContext } from "../../components/modals/base-modal/BaseModalContext";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { storyBoardsApiClient } from "../../../core/config/api";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { getStory } from "../../../core/redux/actions/storiesActions";
import store from "../../../core/redux/store";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET,
};

interface Video {
  id: string;
  url: string;
  resolution?: string;
  format?: string;
  created_at: string;
  size?: string;
  duration?: string;
  version?: number;
}

interface IProps {
  onDownloadVideo: Function;
}

export default function VideoScreen({ onDownloadVideo }: IProps) {
  const story = useSelector((state: TReduxStore) => state.app.story);

  const [videos, setVideos] = useState<Video[]>([]);
  const [validVideos, setValidVideos] = useState<Video[]>([]);

  const { setMode, openModal, setIsOpen: seIsModalOpen, setVideoUrl } = useBaseModalContext();

  const [downloadingVideoId, setDownloadingVideoId] = useState<string | null>(null);

  useEffect(() => {
    // Function to fetch or refresh story and videos
    const fetchStoryAndVideos = async () => {
      if (story.id) {
        // console.log("videos before fetch: ", story)
        //@ts-ignore
        await store.dispatch(getStory({ id: story.id }));
        // console.log("videos after fetch: ", story)
        if (story && story.video && story.video.videos) {
          // Filter out deleted videos but keep their original version
          const filteredVideos = story.video.videos
            .map((video: any, index: number) => ({
              ...video,
              originalVersion: index + 1, // Keep track of original version
            }))
            .filter((video: { url: string }) => video.url !== "deleted");

          setVideos(filteredVideos);
          console.log("filtered videos: ", filteredVideos);
        }
      }
    };

    fetchStoryAndVideos();
  }, []);

  useEffect(() => {
    const validVideos: Video[] = [];
    videos.forEach((video) => {
      const videoElement = document.createElement("video");
      videoElement.src = video.url;
      videoElement.onloadedmetadata = () => {
        validVideos.push(video);
        if (validVideos.length === videos.length) {
          setValidVideos(validVideos);
        }
      };
      videoElement.onerror = () => {
        console.warn(`Invalid video URL: ${video.url}`);
        if (validVideos.length + 1 === videos.length) {
          setValidVideos(validVideos);
        }
      };
    });
  }, [videos]);

  const handleVideoSelect = (video: Video) => {
    setVideoUrl(video.url);
    setMode("VIDEO");
    seIsModalOpen(true);
  };

  const handleDownloadClick = async (e: React.MouseEvent, videoUrl: string, videoId: string, storyLabel: string) => {
    e.stopPropagation();
    setDownloadingVideoId(videoId);

    try {
      console.log("Starting download process for video:", storyLabel);
      console.log("Video URL:", videoUrl);

      // Fetch the file first to check for any CORS or network issues
      const response = await fetch(videoUrl, {
        method: 'GET',
        mode: 'cors', // Ensure CORS mode is enabled
      });

      // Log the status and response details
      console.log("Fetch response received");
      console.log("Response status:", response.status);
      console.log("Response status text:", response.statusText);

      if (!response.ok) {
        // Log the error and throw an exception if the fetch fails
        console.error("Failed to fetch the file. Status:", response.status);
        const errorText = await response.text();
        console.error("Error details:", errorText);
        throw new Error(`Failed to fetch the file. HTTP status: ${response.status}`);
      }

      // Assuming the fetch was successful, proceed with the download
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      console.log("Creating temporary anchor element for download...");
      const link = document.createElement('a');
      link.href = blobUrl;

      // Use the story label as the file name, replacing any illegal characters
      const sanitizedLabel = storyLabel.replace(/[^a-z0-9_\-]/gi, '_'); // Replace illegal characters with underscores
      link.download = `${sanitizedLabel}.mp4`; // Set the desired filename based on the story label

      document.body.appendChild(link);

      // Trigger the download by simulating a click
      link.click();

      // Clean up by removing the element and revoking the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);

      console.log("Download process completed successfully");
    } catch (error) {
      console.error("Error in download process:", error);
      toast.error(`Failed to download video: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setDownloadingVideoId(null);
    }
  };

  const handleDeleteVideo = async (video: Video) => {
    if (!story?.id) {
      toast.error("Story ID is missing.");
      return;
    }

    try {
      await storyBoardsApiClient.video.deleteVideo({
        storyId: story.id,
        awsUrl: video.url,
      });

      // Remove the deleted video from the state
      setVideos(prevVideos => prevVideos.filter(v => v.id !== video.id));
      setValidVideos(prevValidVideos => prevValidVideos.filter(v => v.id !== video.id));

      // toast.success("Video deleted successfully.");
    } catch (error) {
      console.error("Error deleting video:", error);
      toast.error("Failed to delete video. Please try again.");
    }
  };

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="video-screen-container flex justify-center w-full">
      <div className="w-full max-w-[80%]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr min-h-[60vh]">
          {validVideos.map((video) => (
            <div
              key={video.id}
              className="relative cursor-pointer video-tile group aspect-video"
              style={{ aspectRatio: '16 / 9' }}
              onClick={() => handleVideoSelect(video)}
            >
              <div className="relative">
                <video
                  src={video.url}
                  className="w-full h-full object-cover"
                  controls={false}
                  muted
                  onMouseOver={(e) => (e.currentTarget as HTMLVideoElement).play()}
                  onMouseOut={(e) => (e.currentTarget as HTMLVideoElement).pause()}
                />
                {downloadingVideoId === video.id && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <LoadingSpinner size="w-12 h-12" />
                  </div>
                )}
              </div>
              <div className="flex justify-between items-center mt-2">
                <span className="video-title text-xs text-neutral-300">
                  {video.version ? `V${video.version}` : "Version unknown"}{" "}
                  {typeof video.duration === "number" ? `- ${Math.floor(video.duration / 60)}:${String(Math.floor(video.duration % 60)).padStart(2, '0')}` : ""}
                </span>


                <Menu as="div" className="inline-block text-left z-[40]">
                  <div>
                    <Menu.Button
                      className="flex items-center text-gray-400 hover:text-gray-50 focus:outline-none"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right rounded-md bg-neutral-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-neutral-900 text-neutral-100" : "text-neutral-400",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadClick(e, video.url, video.id, story.label);
                              }}
                            >
                              Download
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-neutral-900 text-neutral-100" : "text-neutral-400",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                openModal({
                                  mode: "CONFIRM",
                                  data: {
                                    title: "Delete Video",
                                    description: `Are you sure you want to delete this video? This action cannot be undone.`,
                                    function: () => handleDeleteVideo(video)
                                  }
                                });
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          ))}
          <div
            id="create-video-button"
            className="relative ghost-card rounded-lg border-2 border-dashed border-neutral-500 aspect-video min-w-[245px] hover:bg-neutral-800 flex justify-center items-center cursor-pointer"
            style={{
              aspectRatio: '16 / 9',
            }}
            onClick={() => onDownloadVideo()}
          >
            <span className="text-4xl text-gray-500">+</span>
          </div>
        </div>
      </div>
    </div>
  );
}