import Auth from "./api/auth";
import User from "./api/user";
import Team from "./api/team";
import Story from "./api/story";
import Character from "./api/character";
import Shot from "./api/shot";
import Scene from "./api/scene";
import Image from "./api/image";
import { Payment } from "./api/payment/Payment";
import { Notifications } from './api/notifications/Notifications';
import Video from "./api/video";

export class ApiClient {
  private readonly prefix = "/core";
  public auth = new Auth(this.url + this.prefix);
  public user = new User(this.url + this.prefix);
  public team = new Team(this.url + this.prefix);
  public story = new Story(this.url + this.prefix);
  public character = new Character(this.url + this.prefix);
  public shot = new Shot(this.url + this.prefix);
  public scene = new Scene(this.url + this.prefix);
  public image = new Image(this.url + this.prefix);
  public payment = new Payment(this.url + this.prefix);
  public notification = new Notifications(this.url + this.prefix);
  public video = new Video(this.url + this.prefix);

  constructor(private readonly url: string) {
  }
}
