import React, { useState } from "react";
import store from "../../../../core/redux/store";
import { setModal } from "../../../../core/redux/actions/modalManagerActions";
import { ENUM_MODAL_TYPE } from "../../../../core/types/modal.type";

interface FileData {
  name: string;
  content: any;
}

interface IRequiredProps {
}

interface IOptionalProps {
}

interface IProps extends IRequiredProps, IOptionalProps {
}

export default function ConfigUploader({}: IProps) {
  const [fileData, setFileData] = useState<FileData | null>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target?.result as string;
        try {
          const parsedJson = JSON.parse(content);
          setFileData({
            name: file.name,
            content: Object.entries(parsedJson)
          });
        } catch (error) {
          console.error("Error parsing JSON file:", error);
          setFileData(null);
        }
      };

      reader.readAsText(file);
    }
  };

  return (
    <>
      <div className="flex flex-row mb-4">
        <input
          className="flex-1"
          type="file"
          accept=".json"
          onChange={handleFileUpload}
        />
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-neutral-100 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() =>
            store.dispatch(
              // @ts-ignore
              setModal({ modal: ENUM_MODAL_TYPE.CREATE_TEAM, data: null })
            )
          }
        >
          Upload styles
        </button>
      </div>
      {fileData && (
        <div
          className="flex flex-col gap-2 overflow-y-scroll"
          style={{ height: "500px" }}
        >
          {fileData.content?.map((style: any) => (
            <div
              className="flex flex-1 flex-row gap-4 border rounded-lg px-4 py-2 border-gray-300 bg-white"
              key={style[1]?.id}
            >
              <div className="flex flex-1 flex-row gap-4">
                <div className="text-md font-bold">{style[1]?.id}</div>
                <div>|</div>
                <div>
                  {Object.entries(style[1])?.map((config: any) => (
                    <div className="text-sm text-gray-400">
                      {config[0]}: {config[1]}
                    </div>
                  ))}
                </div>
              </div>
              <button
                type="button"
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
