import { WrenchScrewdriverIcon } from "@heroicons/react/20/solid";
import { CursorArrowRaysIcon } from "@heroicons/react/24/outline";
import { useBaseModalContext } from "../../base-modal/BaseModalContext";
import { storyBoardsApiClient } from "../../../../../core/config/api";
import { useAppContext } from "../../../../../core/utils/AppContext";
import { useState } from "react";
import { ENUM_MODAL_TYPE } from "../../../../../core/types/modal.type";
import { toast } from "react-toastify";

export default function GetStarted() {
  const { setMode } = useBaseModalContext();
  const { fetchProjects, setIsTemplateLoading  } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const { setIsOpen, setYoutubeId } = useBaseModalContext();

  const handleDemoProject = async () => {
    const templateInstalled = localStorage.getItem("demo-installed");

    if (templateInstalled === "template01") {
      toast.info("This demo project is already available");
      return;
    }
    setIsOpen(false);
    setIsTemplateLoading(true);
    setLoading(true);
    try {
      const result = await storyBoardsApiClient.story.createProjectWithTemplate({
        templateId: "template01"
      });
      setMode(undefined); // Close modal after setting up demo project
      if (result.status === 201) {
        await fetchProjects();
        localStorage.setItem("demo-installed", "template01");
      } else {
        throw new Error('Template setup failed');
      }
    } catch (error) {
      console.error("Error setting up template project:", error);
    } finally {
      setIsTemplateLoading(false);
      setLoading(false);
    }
  };

  const handleIntroVideo = () => {
    setYoutubeId("ek9_XUhe7DE") 
    setMode("VIDEO");
    setIsOpen(true);
  };

  const handleManualMode = () => {
    setMode("NEW_PROJECT");
  };

  return (
    <>
      <div className="sm:flex justify-center">
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left pt-12">
          <h3 className="text-xl font-semibold leading-6 text-neutral-900 text-center mx-16">
            How would you like to start?
          </h3>
          <div className="mt-8 flex flex-col items-center text-left mr-6">
            <button className="w-full flex items-center hover:bg-neutral-200 rounded" onClick={handleIntroVideo}>
              <CursorArrowRaysIcon className="w-[24px] h-[24px] m-8" />
              <p className="text-neutral-900 m-8">Watch Intro Video</p>
            </button>
            <button className="w-full flex items-center hover:bg-neutral-200 rounded" onClick={handleDemoProject}>
              <CursorArrowRaysIcon className="w-[24px] h-[24px] m-8" />
              <p className="text-neutral-900 m-8">Look at a demo project</p>
            </button>
            <button className="w-full flex items-center hover:bg-neutral-200 rounded" onClick={handleManualMode}>
              <WrenchScrewdriverIcon className="w-[24px] h-[24px] m-8" />
              <p className="text-neutral-900 m-8">Create a storyboard</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
