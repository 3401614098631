import { useState } from "react";
import { Dialog } from "@headlessui/react";
import store, { TReduxStore } from "../../../../../core/redux/store";
import { setModal } from "../../../../../core/redux/actions/modalManagerActions";
import { ENUM_MODAL_TYPE } from "../../../../../core/types/modal.type";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CustomLoadingSpinner } from "../../../loading/CustomLoadingSpinner";
import {useBaseModalContext} from "../../base-modal/BaseModalContext";
import { useAppContext } from "../../../../../core/utils/AppContext";
const storyBoardsApiClient = require("../../../../../core/config/api").storyBoardsApiClient;

interface IRequiredProps {
}

interface IOptionalProps {
}

interface IProps extends IRequiredProps, IOptionalProps {
}

export default function RenameProjectModal({}: IProps) {
const {setIsOpen, modalData} = useBaseModalContext();
  const [newLabel, setNewLabel] = useState<string>(modalData?.story?.label ?? "");

  const {renameProject} = useAppContext();

  const handleFormSubmit = async(e: any) => {
    e.preventDefault();
    setIsOpen(false);
    await renameProject(modalData?.story?.id, newLabel);
  };

  const [isRenameShotRequestInProgress, setIsRenameShotRequestInProgress] = useState<boolean>(false);

  const handleUpdateProjectTitle = async() => {
    setIsRenameShotRequestInProgress(true);
    try {
      const result = await storyBoardsApiClient.story.updateStory({
        id: modalData?.story?.id,
        label: newLabel,
        story: modalData?.story,
        description: modalData?.story?.description,
        style: modalData?.story?.style
      });
      console.log({ result });
    } catch {
      toast.error("Something went wrong");
    }
    setIsRenameShotRequestInProgress(false);
  }

  return (
    <div className="px-1">
      <div className="sm:flex sm:items-start">
        <div className="text-center sm:mt-0 sm:text-left">
          <div
            className="text-lg font-semibold leading-6 text-neutral-900 text-center"
          >
            {modalData?.title}
          </div>
          <div className="mt-2 flex flex-col items-center gap-2">
            <p className="text-base font-semibold text-neutral-900">Rename story to:</p>
            <form onSubmit={e => e.preventDefault()}>
              <div className="flex items-center gap-x-2 my-4">
                <input value={newLabel} onChange={e => setNewLabel(e.target.value)} type="text" className="bg-neutral-300 w-72" />
              </div>
            </form>
            <div className="flex gap-x-2 justify-end w-full">
              <button
                type="button"
                className="text-neutral-500 hover:bg-neutral-300 border border-neutral-500 rounded py-2 px-4 w-[80px]"
                onClick={() =>
                  setIsOpen(false)
                }
              >
                Cancel
              </button>
              
              <button className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 w-[80px]"
                onClick={handleFormSubmit}>
                  Save
              </button>
              {isRenameShotRequestInProgress && (
                <div className="mt-4 w-full sm:w-[calc(100%-12px)] h-[calc(100%-16px)] sm:ml-3 absolute top-0 left-0 flex items-center justify-center backdrop-blur-sm rounded-md">
                  <CustomLoadingSpinner size={20} />
                </div>
              )}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}