import React, { useEffect, useState } from "react";
import store, { TReduxStore } from "../../../core/redux/store";
import { setModal } from "../../../core/redux/actions/modalManagerActions";
import { ENUM_MODAL_TYPE } from "../../../core/types/modal.type";
import Team from "../../components/team";
import { useSelector } from "react-redux";
import { getTeams } from "../../../core/redux/actions/teamsActions";

type Props = {};

export default function TeamsScreen({}: Props) {
  const teams = useSelector((state: TReduxStore) => state.app.teams?.teams);

  useEffect(() => {
    //@ts-ignore
    store.dispatch(getTeams());
  }, []);

  return (
    <>
      <div className="flex flex-row pb-8">
        <div className="flex-1">
          <p className="text-xl font-bold">Your Teams</p>
        </div>
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-neutral-100 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() =>
            store.dispatch(
              // @ts-ignore
              setModal({ modal: ENUM_MODAL_TYPE.CREATE_TEAM, data: null })
            )
          }
        >
          Create Team
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {teams?.map((team: any) => (
          <Team key={team?.id} team={team} />
        ))}
      </div>
    </>
  );
}
