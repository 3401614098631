import React, { createContext, useContext, useState, ReactNode, useRef } from 'react';

// Creating the context
type TourContextType = {
  tourStartClicked: boolean;
  setTourStartClicked: React.Dispatch<React.SetStateAction<boolean>>;
  tourRunning: boolean;
  setTourRunning: React.Dispatch<React.SetStateAction<boolean>>;
  handlePrint: () => void;
  printRef: React.MutableRefObject<any>;
  closeTour: () => void; 
};

const TourContext = createContext<TourContextType | undefined>(undefined);

type CustomTourProviderProps = {
  children: ReactNode;
};

export const useTourContext = (): TourContextType => {
  const context = useContext(TourContext);
  if (context === undefined) {
    throw new Error('useTourContext must be used within a CustomTourProvider');
  }
  return context as TourContextType;
};

// CustomTourProvider component
export const CustomTourProvider: React.FC<CustomTourProviderProps> = ({ children }) => {
  const [tourStartClicked, setTourStartClicked] = useState(false);
  const [tourRunning, setTourRunning] = useState(false);
  const printRef = useRef<any>(null);

  const handlePrint = () => {
    if (printRef.current) {
      printRef.current.handlePrint();
    }
  };

  const closeTour = () => {
    setTourRunning(false);
    localStorage.setItem("app-intro", "viewed");
  };

  return (
    <TourContext.Provider value={{ tourStartClicked, setTourStartClicked, tourRunning, setTourRunning, handlePrint, printRef, closeTour }}>
      {children}
    </TourContext.Provider>
  );
};
