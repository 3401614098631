import { useNavigate } from "react-router";
import { changeUrl } from "../../../core/utils/changeUrl";

function ExportButton() {
	const navigate = useNavigate();

	return (
		<div className="fixed right-6 col-span-full flex justify-end w-full z-50">
			<button id="export-button" className="text-white hover:text-neutral-200 bg-sky-500 hover:bg-sky-600 py-1.5 rounded font-semibold w-[70px] absolute top-0 right-4"
				onClick={() => navigate(`${changeUrl(window.location.pathname, "export")}`)}>
				Export
			</button>
		</div>
	);
}

export default ExportButton;