import { CustomLoadingSpinner } from "../loading/CustomLoadingSpinner";
import UniversalTippy from "../universal-tippy/UniversalTippy";

interface IRequiredProps {
  handleGenerateProjectFromTemplate: (tempalteId: string) => void;
  templateId: string;
  index: number;
  loadingTemplateId: string;
  templateTitle: string;
}

interface IOptionalProps {
  templateThumbnail?: string;
}

interface IProps extends IRequiredProps, IOptionalProps {
}

export default function Template({ handleGenerateProjectFromTemplate, index, loadingTemplateId, templateId, templateTitle, templateThumbnail }: IProps) {
  return (
    <UniversalTippy content="Demo projects show you how to build your own story">
    <div onClick={() => handleGenerateProjectFromTemplate(templateId)} className={"relative w-[300px] h-[164px] cursor-pointer border-2 border-transparent rounded"}>
      <div className={"w-full h-full rounded bg-white flex flex-col"}>
        {
          templateThumbnail !== undefined ? (
            <div className={`flex-1 flex items-center justify-center h-[120px] bg-cover bg-[url('${templateThumbnail}')]`}></div>
          ) : (
            <div className="flex-1 flex items-center justify-center h-[120px]">
              <img
                src={"https://story-boards-static-dev.s3.us-west-1.amazonaws.com/assets/Blogging-bro.svg"}
                className={"w-[100px]"} alt="blogging-bro"
              />
            </div>
          )
        }
        <div className={"h-[40px] bg-neutral-900 flex items-center px-4 text-neutral-100 text-sm rounded-b"}>
          {templateTitle}
        </div>
      </div>
      {loadingTemplateId === templateId && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-sm rounded-md">
          <div className="flex flex-col items-center">
            <CustomLoadingSpinner size={30} />
            <p className="mt-4 text-white text-lg">
              Setting Up
            </p>
          </div>
        </div>
      )}

    </div>
    </UniversalTippy>
  );
}