import React from "react";
import { useNavigate, useParams } from "react-router";
import store from "../../../core/redux/store";
import { setModal } from "../../../core/redux/actions/modalManagerActions";
import { ENUM_MODAL_TYPE } from "../../../core/types/modal.type";
import { deleteTeam } from "../../../core/redux/actions/teamsActions";
import {useBaseModalContext} from "../../components/modals/base-modal/BaseModalContext";

type Props = {};

export default function TeamScreen({}: Props) {
  const { teamId } = useParams();
  const navigate = useNavigate();
const{openModal} = useBaseModalContext();
  return (
    <>
      <div className="flex flex-col gap-8 pb-8">
        <div className="flex-1">
          <p className="text-xl font-bold">Your Team: {teamId}</p>
        </div>
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-100" />
          </div>
        </div>

        <div className="flex flex-row space-between">
          <div className="flex-1">
            <p className="text-xl font-bold">Team members</p>
          </div>
          <div>
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-neutral-100 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() =>
                store.dispatch(
                  // @ts-ignore
                  setModal({
                    modal: ENUM_MODAL_TYPE.INVITE_TEAM_MEMBER,
                    data: null
                  })
                )
              }
            >
              Invite member
            </button>
          </div>
        </div>

        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-100" />
          </div>
        </div>

        <div>
          <button
            type="button"
            className="rounded-md bg-red-50 px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-indigo-100"
            onClick={() =>
                openModal({
                  mode: "CONFIRM",
                  data: {
                    title: "Delete Team",
                    description: `You are planning to remove this team. Are you sure about it?`,
                    //@ts-ignore
                    function: () => store.dispatch(deleteTeam({ id: teamId })),
                    redirect: () => navigate("/teams")
                  }
                }
              )
            }
          >
            Delete Team
          </button>
        </div>
      </div>
    </>
  );
}
