import { toast } from "react-toastify";

export const downloadImage = (imageUrl: string, fileName: string) => {
  fetch(imageUrl, {
    method: "GET",
    headers: {},
    cache: "no-store"
  })
  .then((response) => {
    if (response.ok) {
      return response.blob();
    }
    throw new Error("Network response was not ok.");
  })
  .then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;  
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  })
  .catch((err) => {
    console.error("Fetch error:", err);
    toast.error("Something went wrong while trying to download the image!");
  });
}