import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useBaseModalContext } from "../base-modal/BaseModalContext";
import { useSelector } from "react-redux";
import { sendEventToGoogleTagManager } from "../../../../core/utils/googleTagManager";
import { TReduxStore } from "../../../../core/redux/store";
import { useTrialEligibility } from "../../../../core/utils/useTrialEligibility";

interface IRequiredProps { }

interface IOptionalProps {
  trigger?: string;
  story?: TStory;
}

interface IProps extends IRequiredProps, IOptionalProps { }

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/month (billed annually)" }
];

const tiers: any = [
  {
    name: "Startup",
    id: "tier-startup",
    href: "#",
    price: { monthly: "$15", annually: "$13" },
    description: "A plan that scales with your rapidly growing business.",
    features: [
      "2 Stories",
      "10 Characters",
      "4 styles",
      "Manual Story building",
      "1x Story import Wizard",
      "200 images",
      "PDF & JPG export",
      "Support"
    ],
    featured: true,
    cta: "Select Plan"
  }
];

type TStory = {
  id: string;
  label: string;
  description?: string;
  story?: string;
  inAnalyze?: boolean;
  updatedAt: Date;
  updatedBy: any;
};

export default function Subscribe({ trigger }: IProps) {
  const { userId, availableCredits, account } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits,
    account: state.app.account
  }));
  const navigate = useNavigate();
  const { isOpen, setIsOpen } = useBaseModalContext();
  const isEligibleForTrial = useTrialEligibility(account);

  const story = useSelector((state: TReduxStore) => state.app.story);
  console.log(`subscribe modal: ${trigger}`)

  useEffect(() => {
    if (isOpen) {
      sendEventToGoogleTagManager(
        "upgrade_modal",
        {
          outcome: "success",
          trigger: trigger || "unknown"
        },
        userId,
        availableCredits
      );
    }
  }, [isOpen]);

  const getMessage = (trigger?: string) => {
    const actionText = isEligibleForTrial ? "Start your free trial" : "Subscribe";

    if (trigger === "DOWNLOAD_IMAGE_BUTTON") {
      return (
        <div className="flex flex-col items-center text-center">
          <p className="text-lg leading-8 text-gray-600">
            {actionText} to download images as JPG.
          </p>
          <p className="text-lg leading-8 text-gray-600 mt-4">
            Alternatively, you can download your story as a{" "}
            <a href={`/stories/${story?.id}/export`} className="text-blue-500 underline">
              PDF
            </a>{" "}
            file.
          </p>
        </div>
      );
    } else if (trigger === "IMG2IMG" || trigger === "INPAINT") {
      return (
        <span>
          {actionText} to continue using this feature
        </span>
      );
    } else if (trigger === "SHOT") {
      return (
        <span>
          It looks like you've hit the limit for adding more shots. <br /> {actionText} for unlimited shots.
        </span>
      );
    } else if (trigger === "PROJECT") {
      return (
        <span>
          You've reached the project limit. <br /> {actionText} to create additional projects.
        </span>
      );
    } else if (trigger === "AI_WIZARD") {
      return (
        <span>
          The AI Wizard limit has been reached. <br /> {actionText} for unlimited AI Wizard uses.
        </span>
      );
    } else {
      return (
        <span>
          You've reached the limits of your current plan. <br /> {actionText} for unlimited features.
        </span>
      );
    }
  };

  return (
    <div className="modal-container w-full h-full flex items-center justify-center z-10">
      <div className="mt-3 sm:mt-5 px-6 py-6">
        <div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-1 flex-col gap-4 items-center justify-center">
              <div>
                <div className="mx-auto max-w-4xl text-center">
                  <div className="mx-auto my-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    {getMessage(trigger)}
                  </div>
                  <div className="mb-4">
                    <button
                      id="upgrade-button"
                      type="button"
                      className="text-lg font-semibold text-white bg-sky-500 rounded px-4 py-2 hover:text-gray-300 shadow-sm"
                      onClick={() => {
                        setIsOpen(false);
                        navigate(`/account`);
                      }}
                    >
                      {isEligibleForTrial ? "Free Trial" : "Subscribe"}
                    </button>
                  </div>
                  <button
                    id="upgrade-button"
                    type="button"
                    className="text-sm font-semibold text-neutral-600 px-4 py-1 rounded border border-transparent hover:border-neutral-400"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Not right now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
