import React, { useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { CustomLoadingSpinner } from "../../../loading/CustomLoadingSpinner";
import { useBaseModalContext } from "../../base-modal/BaseModalContext";

export default function ConfirmDelete() {
  const { setIsOpen, modalData } = useBaseModalContext();
  const [isConfirmDeleteRequestInProgress, setIsConfirmDeleteRequestInProgress] = useState(false);

  const handleConfirm = async () => {
    setIsConfirmDeleteRequestInProgress(true);
    setIsOpen(false);
    if (modalData.function) {
      await modalData.function();
    }
    setIsConfirmDeleteRequestInProgress(false);
    if (modalData.redirect) {
      modalData.redirect();
    }
  };

  return (
    <div className="px-2 py-3">
      <div className="sm:flex sm:items-start max-w-[900px]">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-neutral-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <div className="text-base font-semibold leading-6 text-neutral-800">
            {modalData?.title}
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{modalData?.description}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="relative">
          <button
            type="button"
            className="text-white bg-red-500 hover:bg-red-600 rounded py-2 px-4 ml-2 h-full"
            onClick={handleConfirm}
          >
            DELETE
          </button>
          {isConfirmDeleteRequestInProgress && (
            <div className="absolute top-0 left-0 w-full sm:w-[calc(100%-12px)] h-full flex items-center justify-center backdrop-blur-sm rounded-md sm:ml-3">
              <CustomLoadingSpinner size={20} />
            </div>
          )}
        </div>
        <button
          type="button"
          className="text-neutral-500 hover:bg-neutral-300 border border-neutral-500 rounded py-2 px-4"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
