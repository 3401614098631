import React from "react";
import Navbar from "../../components/profile";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

type Props = {};

export default function Dashboard({}: Props) {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="w-full h-full">
        {/*<Sidebar />*/}

        <div className="w-full h-full">
          {/*<Navbar />*/}

          <Outlet />
        </div>
      </div>
    </>
  );
}
