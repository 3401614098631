import { useEffect, useState } from "react";
import { storyBoardsApiClient } from "../../config/api";
import { setAccount } from "../../redux/actions/accountActions";
import store from "../../redux/store";
import { useAppContext } from "../../utils/AppContext";
import useAnalyticsClientId from "../../utils/clientID";

export function useAuth() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setUser } = useAppContext();
  const clientId = useAnalyticsClientId();

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await storyBoardsApiClient.user.getProfile();
        if (result.status === 200) {
          console.log({ profile: result });

          // If clientId from the hook is available and different from the one in the profile, update it
          if (clientId && clientId !== result.data.clientId) {
            console.log('Client ID changed, updating profile...');
            const updateResult = await storyBoardsApiClient.user.updateClientId({
              userId: result.data.id,
              clientId: clientId
            });
            if (updateResult.status === 200) {
              result.data.clientId = clientId;
            }
          }

          setData(result);
          // @ts-ignore
          store.dispatch(setAccount({ data: result.data }));
          setUser(result.data);
        }
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [setUser, clientId]);

  return [data, loading, error];
}