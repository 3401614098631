import React, { useEffect, useMemo, useRef, useState } from "react";
import Shot from "../../components/shot";
import { useParams } from "react-router";
import { storyBoardsApiClient } from "../../../core/config/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import store, { TReduxStore } from "../../../core/redux/store";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CustomLoadingSpinner } from "../../components/loading/CustomLoadingSpinner";
import { useTour } from "@reactour/tour";
import { useTourContext } from "../../../core/utils/TourContext";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import Accordion from "../../components/accordion/Accordion";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { appSteps } from "../../components/tour/TourSteps";
import { inpaintSteps } from "../../components/tour/TourSteps";
import { useMobileView } from "../../../core/utils/isMobile";
import PreviewScreen from "../preview-screen";
import { ArrowRightIcon, CheckIcon, QueueListIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import { pricing } from "../../../core/config/pricing";
import { useBaseModalContext } from "../../components/modals/base-modal/BaseModalContext";
import { useAppContext } from "../../../core/utils/AppContext";
import { getStory } from "../../../core/redux/actions/storiesActions";
import UniversalTippy from "../../components/universal-tippy/UniversalTippy";
import { useLocation } from "react-router-dom";
import EditDescription from "../../components/edit-description/EditDescription";
import useAuth from "../../../core/hooks/auth";
import Style from "../../components/style";
import { FREE_STYLE_INDICES, stylesData } from "../../components/style-selector/styles-data";
import { ChevronDownIcon, ChevronUpIcon, StarIcon } from "@heroicons/react/20/solid";
import CastUIWrapper from "../../components/modals/base-modal/castui/castUIWrapper";
import useUserPlan from "../../../core/utils/creditCost";
import { Switch } from "@headlessui/react";
import ExportButton from "../../components/exportbutton/ExportButton";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type Props = {};

export default function BoardScreen({ }: Props) {
  const { userId, availableCredits, account } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits,
    account: state.app?.account,
    story: state.app.story
  }));

  const { openModal } = useBaseModalContext();
  const { currentStory, setCurrentStory } = useAppContext();
  const [currentAnalyseStep, setCurrentAnalyseStep] = useState<number | null>(null);

  const story = currentStory;
  const { setSteps, setIsOpen, currentStep, setCurrentStep, isOpen } = useTour();
  const { setTourRunning, tourRunning, setTourStartClicked, tourStartClicked } = useTourContext();

  const [scenes, setScenes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [inGeneration, setInGeneration] = useState<boolean>(false);
  const [selectedShot, setSelectedShot] = useState<any>();
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const { storyId } = useParams();
  const { isFree, isPlan, costs } = useUserPlan();

  const [addShotInProgress, setAddShotInProgress] = useState<boolean>(false);
  const [addSceneInProgress, setAddSceneInProgress] = useState<boolean>(false);

  const [loadingImageUrl, setLoadingImageUrl] = useState(null);

  const [newInProgress, setNewInProgress] = useState<string[]>([]);

  const appContext = useAppContext();

  const [requestedShots, setRequestedShots] = useState(10);

  const [mainImage, setMainImage] = useState<string>(
    `${selectedShot?.selectedImage ?? ""}`
  );

  const [previewButtonClicked, setPreviewButtonClicked] = useState(false);

  const [onlyCurrentSubscriptionSwitch, setOnlyCurrentSubscriptionSwitch] = useState(false);

  const updateSceneWithShot = (_scenes: any[], _selectedShot: any): any[] => {
    const updatedScenes: any[] = scenes?.map((scene: any) => {
      const updatedShots: any[] = scene?.shots?.map((shot: any) =>
        shot?.id === selectedShot?.id ? { ...shot, ...selectedShot } : shot
      );

      return { ...scene, shots: updatedShots };
    });

    return updatedScenes;
  };

  useEffect(() => {
    const _scenes = updateSceneWithShot(scenes, selectedShot);
    setScenes([..._scenes]);
  }, [selectedShot]);

  const retrieveAllScenesRequest = async () => {
    try {
      const result = await storyBoardsApiClient.scene.retrieveAll({
        storyId: storyId ?? ""
      });
      if (result.status === 200) {
        const updatedScenes = result.data.scenes.map((scene: any, sceneIndex: number) => {
          const updatedShots = scene.shots.map((shot: any, shotIndex: number) => {
            const globalIndex = result.data.scenes
              .slice(0, sceneIndex)
              .reduce((count: any, prevScene: any) => count + prevScene.shots.length, 0) + shotIndex;
            return { ...shot, globalIndex };
          });
          return { ...scene, shots: updatedShots };
        });
        setScenes(updatedScenes);
        setOpenAccordionId(updatedScenes[0]?.id);
        setSelectedShot(updatedScenes[0].shots[0]);
        setMainImage(`${updatedScenes[0].shots[0]?.selectedImage ?? ""}`);
        console.log("fetched scenes: ", updatedScenes);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const { charactersList, setCharactersList } = useAppContext();

  const retrieveCharactersRequest = async () => {
    try {
      const result = await storyBoardsApiClient.character.getCharacters({
        storyId: storyId ?? ""
      });
      if (result.status === 200) {
        setCharactersList(result.data.characters);
        console.log("fetched characters: ", charactersList);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const result = await storyBoardsApiClient.story.getStory({
        id: storyId ?? ""
      });
      if (result.status === 200) {
        setCurrentStory(result.data);
        updateStep(result.data.analyzeStep);
        console.log('Fetched story data:', currentStory);
        console.log('Fetched analyze step:', result.data.analyzeStep);
      } else {
        console.log("non 200 response")
        console.log(result)
      }
    } catch (error) {
      console.error("Error fetching initial story data:", error);
      //toast.error("Failed to load story data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await Promise.all([
          fetchInitialData(),
          retrieveAllScenesRequest(),
          retrieveCharactersRequest(),
        ]);
        setInitialLoadComplete(true);
      } catch (error) {
        console.error("Error in loading checks:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeApp();
  }, [storyId]);

  // TOUR //

  const isTourViewed = () => localStorage.getItem("app-tour") === "viewed";

  // This effect checks if the app tour has been viewed when the component mounts and the loading state is false.
  // If the tour has not been viewed, it waits for the timeline element to be present, then starts the tour after a 2-second delay.

  //restrict when setIsOpen can become true
  const setIsOpenWithCondition = (value: boolean) => {
    if (value === true && story?.analyzeStep !== 0) {
      console.log("Tour cannot start: story.analyzeStep is not 0");
      return;
    }
    setIsOpen(value);
  };

  const startAppTour = () => {
    if (setSteps) {
      setSteps(appSteps);
      setIsOpen(true);
      setCurrentStep(0);
      setTourRunning(true);
    }
  };

  const endAppTour = () => {
    if (setSteps) {
      setSteps([]);
      setIsOpen(false);
      setTourRunning(false);
      localStorage.setItem("app-tour", "viewed");
    }
  };

  useEffect(() => {
    if (isTourViewed()) return;

    console.log("checking for tour conditions");
    console.log("loading:", loading, "story.analyzeStep:", story?.analyzeStep, "story.type:", story?.type);

    if (!loading &&
      story?.analyzeStep === 0 &&
      story?.type !== 'ai' &&
      !story?.inAnalyze &&
      !story.inGeneration) {
      console.log("proceeding with tour");

      const checkAndStartTour = () => {
        const timelineElement = document.getElementById("timeline");
        if (timelineElement) {
          clearInterval(intervalId);
          console.log("waiting 3 seconds");

          setTimeout(() => {
            console.log("app tour start");
            startAppTour();
            sendEventToGoogleTagManager("tour_automatic_start", {
              category: "App Tour",
              action: "automatic_start",
              label: "Tour Started"
            }, userId, availableCredits);
          }, 3000);
        }
      };

      const intervalId = setInterval(checkAndStartTour, 500);
      return () => clearInterval(intervalId);
    } else {
      console.log("not showing tour")
    }
  }, [loading, userId, availableCredits, setIsOpen, setCurrentStep, setTourRunning, currentAnalyseStep, story]);

  // This effect runs when the 'isOpen' or 'tourRunning' states change.
  // If the tour is closed, it updates the local storage and sends an event to Google Tag Manager.
  useEffect(() => {
    if (!isOpen && tourRunning && story?.analyzeStep === 0) {
      endAppTour();
      sendEventToGoogleTagManager("tour_closed", {
        category: "App Tour",
        action: "closed",
        label: "Tour Closed"
      }, userId, availableCredits);
    }
  }, [isOpen, tourRunning, userId, availableCredits, story?.analyzeStep]);

  // This effect starts the tour manually if 'tourStartClicked' is true.
  // It resets the 'tourStartClicked' state and sets up the tour.
  useEffect(() => {
    if (tourStartClicked) {
      console.log("tour started manually")
      setTourStartClicked(false);
      setCurrentStep(0);
      setIsOpenWithCondition(true);
      setTourRunning(true);

      sendEventToGoogleTagManager("tour_manual_start", {
        category: "App Tour",
        action: "manual_start",
        label: `Tour Started at Step: ${currentStep}`
      }, userId, availableCredits);
    }
  }, [tourStartClicked, setIsOpen]);

  // This effect sets up and starts the mode-toggle tour if the conditions are met.
  // It checks the number of shots in the current story and ensures the tour hasn't been viewed before.
  // It starts the tour after a 5-second delay.
  const shotsInCurrentStory = scenes?.reduce((acc, scene) => {
    const nrshots = acc + scene.shots.length;
    return nrshots;
  }, 0);

  useEffect(() => {
    if (
      localStorage.getItem("app-tour") == "viewed" &&
      localStorage.getItem("mode-toggle-tour") !== "viewed" &&
      shotsInCurrentStory > 2 &&
      story?.analyzeStep === 0
    ) {
      const timer = setTimeout(() => {
        if (!isOpen && setSteps) {
          setSteps([
            {
              selector: '#switch-mode-toggle',
              content: (
                <div>
                  <p>Click here to toggle between the <strong>BUILDER</strong> and <strong>PREVIEW</strong> screens</p>
                </div>
              ),
              position: 'top',
              action: (node) => {
                if (previewButtonClicked) {
                  setCurrentStep(currentStep + 1);
                }
              },
              stepInteraction: true,
              styles: {
                close: (base) => ({
                  ...base,
                  display: 'none',
                }),
                arrow: (base) => ({
                  ...base,
                  display: 'none',
                }),
              },
            },
            // Add other steps as needed
          ]);
          setCurrentStep(0);
          setTourRunning(true);
          setIsOpenWithCondition(true);
          localStorage.setItem("mode-toggle-tour", "viewed");

          // Scroll to the edit button
          const editButton = document.querySelector('#switch-mode-toggle');
          if (editButton) {
            editButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }, 5000); // 5 second delay

      // Cleanup timeout on component unmount or when dependencies change
      return () => clearTimeout(timer);
    }
  }, [setSteps, scenes, previewButtonClicked, currentStep, isOpen, shotsInCurrentStory, story]);

  // This effect starts the inpaint mode tour if the board screen view mode is "INPAINT" and the tour hasn't been viewed before.
  // It sets up and starts the tour.
  useEffect(() => {
    if (appContext.boardScreenViewMode === "INPAINT" && localStorage.getItem("inpaint-tour") !== "viewed" && setSteps) {
      setSteps(inpaintSteps)
      setCurrentStep(0);
      setTourRunning(true);
      setIsOpenWithCondition(true);
      localStorage.setItem("inpaint-tour", "viewed");
    }
  }, [appContext, setSteps]);

  // This effect resets the tour state when the story ID changes.
  useEffect(() => {
    setTourRunning(false);
    setTourStartClicked(false);
    if (setSteps) {
      setSteps([]);
    }
    setCurrentStep(0);
  }, [storyId]);

  // TOUR   ///

  useEffect(() => {
    console.log("Loading state:", loading);
  }, [loading]);

  useEffect(() => {
    if (appContext.boardScreenViewMode === "IMG2IMG") {
      appContext.setDroppedFileImg2Img(undefined);
    }
  }, [appContext.boardScreenViewMode]);

  const createShotRequest = async ({ sceneId, index }: { sceneId: string, index: number }) => {
    setAddShotInProgress(true);
    try {
      const result = await storyBoardsApiClient.shot.create({
        storyId: storyId ?? "",
        shotDescription: "Add a Shot Description",
        promptDescription: "Add Shot Prompt",
        sceneId: sceneId,
        angle: "wide",
        index
      });
      if (result.status === 201) {
        sendEventToGoogleTagManager("create_shot_success", {
          story_management: "Story Management",
          shot_action: "create",
          story_id: story?.id,
          outcome: "success"
        }, userId, availableCredits);
        let _scenes = [...scenes];
        const _sceneIndex = _scenes.findIndex((scene: any) => scene?.id === result.data?.scene?.id);
        const _shots = _scenes[_sceneIndex].shots;
        _shots.splice(result.data.index, 0, result.data);

        // Recalculate globalIndex for each shot
        _scenes = updateGlobalIndices(_scenes);

        setScenes([..._scenes]);

        let newShot = result.data;
        newShot.scene = undefined;
        newShot.story = undefined;

        setSelectedShot(newShot);
      } else {
        sendEventToGoogleTagManager("create_shot_fail", {
          story_management: "Story Management",
          shot_action: "create",
          story_id: story?.id,
          outcome: "failure"
        }, userId, availableCredits);
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
      sendEventToGoogleTagManager("create_shot_fail", {
        story_management: "Story Management",
        shot_action: "create",
        story_id: story?.id,
        outcome: "failure"
      }, userId, availableCredits);
    }
    setAddShotInProgress(false);
  };

  const [selectedStyle, setSelectedStyle] = useState<string>();

  useEffect(() => {
    setSelectedStyle(story?.style);
  }, [story]);

  const createSceneRequest = async ({ index, content, description, scenes }: {
    index: number,
    content: string,
    description: string
    scenes: any[]
  }) => {
    setAddSceneInProgress(true);

    // Calculate the global index of the new shot that would be added
    const newShotGlobalIndex = scenes.reduce((total, scene) => total + (scene.shots?.length || 0), 0);

    if ((isFree || !account?.subscription?.id) && newShotGlobalIndex >= FREE_TIER_SHOT_LIMIT) {
      openModal({ mode: "SUBSCRIBE", data: {}, trigger: "SHOT" });
      setAddSceneInProgress(false);
      return;
    }

    try {
      const result = await storyBoardsApiClient.scene.create({
        storyId: storyId ?? "",
        index,
        content,
        description
      });

      if (result.status === 201) {
        const _scenes = [...scenes];
        _scenes.splice(result.data?.index, 0, result.data);
        setScenes([..._scenes]);
        sendEventToGoogleTagManager("create_scene_success", {
          story_management: "Story Management",
          scene_action: "create",
          story_id: story?.id,
          outcome: "success"
        }, userId, availableCredits);
      } else {
        toast.error("Something went wrong");
        sendEventToGoogleTagManager("create_scene_fail", {
          story_management: "Story Management",
          scene_action: "create",
          story_id: story?.id,
          outcome: "failure"
        }, userId, availableCredits);
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
      sendEventToGoogleTagManager("create_scene_fail", {
        story_management: "Story Management",
        scene_action: "create",
        story_id: story?.id,
        outcome: "failure",
        error_message: error || "Unknown error"
      }, userId, availableCredits);
    }

    setAddSceneInProgress(false);
  };

  const newImageRequest = async ({
    type, shot, metadata
  }: {
    type: "NEW" | "VERSION" | "TUNE";
    shot: any;
    metadata: any;
  }) => {
    setNewInProgress([...newInProgress, shot?.id]);
    console.log("new image request: ", metadata)

    try {
      const result = await storyBoardsApiClient.shot.newImage({
        id: shot?.id,
        storyId: storyId ?? "",
        type,
        metadata
      });

      if (result.status === 201) {
        appContext.setAiCreditsSpentSinceReload((prev) => prev + 2);

        if (selectedShot?.id === shot?.id) {
          setSelectedShot(result.data.shot);
        }

        setScenes((prevState) => {
          return prevState.map((scene) => {
            return {
              ...scene,
              shots: scene?.shots?.map((shot: any) => {
                if (shot?.id === result?.data?.shot?.id) {
                  return result.data.shot;
                }
                return shot;
              })
            };
          });
        });

        // Successful Image Generation
        sendEventToGoogleTagManager("generate_image_success", {
          story_management: "Story Management",
          image_action: "generate",
          story_id: story?.id,
          style_id: result?.data?.story?.style ?? story?.style,
          outcome: "success"
        }, userId, availableCredits);

        // toast("A new image created successfully");
      } else {
        sendEventToGoogleTagManager("generate_image_fail", {
          story_management: "Story Management",
          image_action: "generate",
          story_id: story?.id,
          outcome: "fail",
          error_code: result.status,
          error_message: result.statusText || "Unexpected status code returned",
        }, userId, availableCredits);

        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });

      toast.error("Something went wrong");

      sendEventToGoogleTagManager("generate_image_fail", {
        story_management: "Story Management",
        image_action: "generate",
        story_id: story?.id,
        outcome: "fail",
        error_message: error,
      }, userId, availableCredits);
    }

    setNewInProgress((prevState) =>
      [...prevState].filter((shotId) => shotId !== shot?.id)
    );
  };

  const deleteShotRequest = async ({ shotId }: { shotId: string }) => {
    if (story.isTemplate) {
      toast.info("You cannot delete shots in a template project");
      return;
    }

    const sceneOfShotId = scenes.find((scene: any) =>
      scene?.shots?.some((shot: any) => shot?.id === shotId)
    );

    if (sceneOfShotId?.shots?.length > 1) {
      try {
        const result = await storyBoardsApiClient.shot.delete({
          id: shotId,
          storyId: storyId ?? ""
        });
        if (result.status === 200) {
          console.log({ result });

          let _scenes = [...scenes];
          const _sceneIndex = _scenes.findIndex((scene: any) =>
            scene?.shots?.some((shot: any) => shot?.id === shotId)
          );
          const _shots = _scenes[_sceneIndex].shots.filter((shot: any) => shot?.id !== shotId);
          _scenes[_sceneIndex].shots = _shots;

          // Recalculate globalIndex for each shot
          _scenes = updateGlobalIndices(_scenes);

          setScenes([..._scenes]);

          appContext.setBoardScreenViewMode("BUILDER");

          setSelectedShot(scenes?.length > 0 ? scenes[0]?.shots[0] ?? {} : {});
          sendEventToGoogleTagManager("delete_shot_success", {
            story_management: "Story Management",
            action: `delete shot - story: ${storyId}`,
            outcome: "success"
          }, userId, availableCredits);
        } else {
          sendEventToGoogleTagManager("delete_shot_fail", {
            story_management: "Story Management",
            action: `delete shot - story: ${storyId}`,
            outcome: "failure"
          }, userId, availableCredits);
          toast.error("Something went wrong with deleting the Shot Card");
        }
      } catch (error) {
        console.log({ error });
        sendEventToGoogleTagManager("delete_shot_fail", {
          story_management: "Story Management",
          action: `delete shot - story: ${storyId}`,
          outcome: "error",
          error_message: error || "Unknown error"
        }, userId, availableCredits);
        toast.error("Something went wrong with deleting the Shot Card");
      }
    } else {
      toast.warning("You can't delete the only shot in a scene");
    }
  };

  interface Shot {
    id: string;
    selectedImage?: string;
    shotDescription?: string;
    index: number;
    globalIndex?: number;
  }

  interface Scene {
    id: string;
    content: string;
    description: string;
    index: number;
    sceneId: string;
    shots?: Shot[]
  }

  const [currentScene, setCurrentScene] = useState<Scene>({
    id: "",
    content: "",
    description: "",
    index: 0,
    sceneId: ""
  });

  const handleSceneUpdate = (updatedSceneData: any) => {
    const updatedScenes = scenes.map((scene, index) => {
      if (index === updatedSceneData.index) {
        return { ...scene, content: updatedSceneData.content, description: updatedSceneData.description };
      }
      return scene;
    });

    setScenes(updatedScenes);
  };

  const handleShotReorder = async ({ newIndex, shotId }: {
    newIndex: number,
    shotId: string
  }) => {
    try {
      const result = await storyBoardsApiClient.scene.reorder({
        storyId: storyId ?? "",
        newIndex,
        shotId
      });

      if (result.status === 201) {
        return result.data;
      }

    } catch (error) {
      console.warn({ error });
    }
  };

  const handleSceneReorder = async ({ newIndex, sceneId }: {
    newIndex: number,
    sceneId: string
  }) => {
    try {
      const result = await storyBoardsApiClient.story.reorderScenes({
        storyId: storyId ?? "",
        newIndex,
        sceneId
      });

      if (result.status === 200 && result.data) {
        const updatedScenes = updateGlobalIndices(result.data.scenes);
        setScenes(updatedScenes);
      }

      return result;
    } catch (error) {
      console.warn({ error });
    }
  }

  const FREE_TIER_SHOT_LIMIT = 6;

  const countShots = (scenes: any[]) => {
    const totalshots = scenes.reduce((total: any, scene: { shots: string | any[]; }) => total + scene.shots.length, 0)
    console.log("total shots: ", totalshots)
    return totalshots;
  };

  function findUsedTier() {
    const subscriptionPlanId = account?.subscription?.id; // Assuming subscriptionId corresponds to planId
    let usedTier = pricing.tiers.find(tier =>
      tier?.id === subscriptionPlanId ||
      tier?.id === subscriptionPlanId
    );

    if (!subscriptionPlanId) {
      usedTier = pricing.tiers[0]
    }

    if (usedTier) {
      const { monthly, yearly } = usedTier.price;
      return {
        ...usedTier
      };
    }

    return usedTier;
  }

  const currentSubscription = findUsedTier();
  const isAdmin = process.env.REACT_APP_ADMIN === 'true';


  function AddShot({ scene, index, scenes }: { scene: any, index: number, scenes: any }) {
    const handleNewShot = () => {
      if (isFree && countShots(scenes) >= 6) {
        openModal({ mode: "SUBSCRIBE", data: {}, trigger: "SHOT" });
      } else {
        createShotRequest({ sceneId: scene?.id, index: index });
      }
    }

    return (
      <button
        id="plus-button"
        className="w-full sm:w-[calc(100%-32px)] relative flex justify-center items-center rounded-md border border-dashed border-neutral-500 hover:border-neutral-400 hover:bg-neutral-800 transition-all duration-300 h-[70px] sm:h-[110px] mt-2 mb-4 mx-3 sm:mx-0 px-3 sm:px-6 sm:ml-8 sm:pt-2"
        onClick={handleNewShot}
      >
        {addShotInProgress && (
          <div className="absolute bg-black bg-opacity-25 backdrop-blur left-0 top-0 w-full z-20 h-full flex items-center justify-center">
            <div style={{ width: "20px", height: "20px" }}>
              <CustomLoadingSpinner size={30} />
            </div>
          </div>
        )}
        <div
          id="plus-icon"
          className="text-neutral-500 hover:text-neutral-500 text-lg flex items-center justify-center transform opacity-80"
        >
          Add Shot
        </div>
      </button>
    );
  }

  const AddScene = ({ index, scenes }: { index: number, scenes: any }) => {
    return (
      <div id="plus-button"
        className="relative mb-10 cursor-pointer flex justify-center rounded-md items-center border border-1 border-dashed hover:border-neutral-500 border-neutral-600 transition-all duration-300 hover:bg-neutral-800 h-[50px] opacity-100  "
        onClick={() => createSceneRequest({ index, content: "Add a Scene Title", description: "", scenes })}
      >
        {addSceneInProgress && (
          <div
            className={"absolute bg-black bg-opacity-25 backdrop-blur left-0 top-0 w-full z-20 h-full flex items-center justify-center"}>
            <div style={{ width: "20px", height: "20px" }}>
              <CustomLoadingSpinner size={22} />
            </div>
          </div>
        )}
        <div id="plus-icon"
          className="w-30 h-7 text-neutral-500 text-lg flex items-center justify-center hover:cursor-pointer opacity-80">
          Add Scene
        </div>
      </div>
    );
  };

  function ShotCard(shot: Shot, shotIndex: number, scene: any, globalIndex: number, isFree: boolean) {
    const isMobile = useMobileView();

    const handleShotClick = () => {
      // console.log(`Clicked shot with ID: ${shot.id}, Local Index: ${shotIndex}, Global Index: ${globalIndex}`);

      if (globalIndex < FREE_TIER_SHOT_LIMIT || !isFree || isAdmin) {
        setSelectedShot(shot);
        // console.log(`Selected shot with global index: ${globalIndex}`);
      } else {
        // console.log(`Attempting to open subscribe modal for shot with global index: ${globalIndex}`);
        openModal({ mode: "SUBSCRIBE", data: {}, trigger: "SHOT" });
      }
    };

    const isDisabled = isFree && globalIndex >= FREE_TIER_SHOT_LIMIT && !story.isTemplate;

    return (
      <div
        id="shot-card"
        className={`group flex h-full w-full justify-center items-center px-3 sm:px-0 sm:pl-8 sm:pt-2 ${isDisabled ? 'cursor-pointer' : 'hover-trigger'
          }`}
        onClick={handleShotClick}
      >
        <div className={`flex flex-col w-full h-full relative`}>
          {/* Shot Item */}
          <div
            className={`group shot-item flex items-center bg-neutral-800 border-2 ${!isDisabled ? 'hover:scale-103' : ''
              } transition-all transform duration-300 rounded-md w-full mb-2 ${selectedShot.id === shot.id ? "border-neutral-500" : "border-none"
              } ${isDisabled ? "opacity-50" : ""}`}
          >
            {/* Shot Display */}
            <div
              id="shot-display"
              className="relative flex flex-col items-center justify-center ease-in-out shadow-lg group"
            >
              {newInProgress?.includes(shot.id) && (
                <div className="absolute bg-black bg-opacity-25 backdrop-blur left-0 top-0 w-full z-20 h-full flex items-center justify-center">
                  <div style={{ width: "20px", height: "20px" }}>
                    <CustomLoadingSpinner size={30} />
                  </div>
                </div>
              )}
              {shot?.selectedImage ? (
                <div className="w-[80px] aspect-w-16 aspect-h-9 sm:w-[190px] sm:h-[107px]">
                  <img
                    src={`${shot?.selectedImage ?? require("../../../assets/logos/placeholder_result.webp")}`}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="w-[190px] h-[107px] bg-neutral-700 flex items-center justify-center">
                  <img src={require("../../../assets/logos/placeholder_result.webp")} className="w-full h-full" />
                </div>
              )}

              {/* Shot Number */}
              <div className="w-6 h-6 absolute top-0 left-0 bg-neutral-800 flex items-center justify-center group">
                <p id="shot-number" className="text-sm font-medium text-white">
                  {`${shotIndex + 1}`}
                </p>
              </div>
            </div>
            <div className="flex w-full items-center justify-between ml-4 mr-4">
              <div className="shot-description w-[80%] relative">
                <p className="text-neutral-200 text-xs sm:text-sm text-ellipsis overflow-hidden">
                  {shot?.shotDescription}
                </p>
              </div>
              {(!isFree || globalIndex < FREE_TIER_SHOT_LIMIT) && (
                <div className={`lg:hidden group-hover:flex flex-col sm:flex-row items-center`}>
                  <button
                    className="w-[24px] h-[24px] mb-2 sm:mb-0 sm:mr-2 flex text-white items-center justify-center rounded bg-neutral-800 hover:bg-neutral-700"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (shot !== selectedShot) {
                        setSelectedShot(shot);
                      }
                      appContext.setBoardScreenViewMode("EDIT_DESCRIPTION");
                    }}
                  >
                    <UniversalTippy content="This text will appear under your storyboard image">
                      <PencilIcon className="w-[16px] h-[16px]" />
                    </UniversalTippy>
                  </button>
                  <div className="w-[24px] h-[24px] sm:mr-2 flex text-white items-center justify-center rounded bg-neutral-800 hover:bg-neutral-700">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal({
                          mode: "CONFIRM",
                          data: {
                            title: "Delete Shot",
                            description: "Do you really want to delete this Shot?",
                            function: () => deleteShotRequest({ shotId: shot?.id }),
                          },
                        });
                      }}
                    >
                      <TrashIcon className="w-[16px] h-[16px]" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Overlay */}
          {isDisabled && !story.isTemplate && (
            <div
              className="absolute inset-0 z-10 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                openModal({ mode: "SUBSCRIBE", data: {}, trigger: "SHOT" });
              }}
            >
              <div className="absolute top-2 right-2 flex items-center justify-center bg-white bg-opacity-0 rounded-full p-1">
                <StarIcon className="h-5 w-5 text-yellow-500" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  const [openAccordionId, setOpenAccordionId] = useState(null);

  const toggleAccordion = (id: any) => {
    if (openAccordionId === id) {
      setOpenAccordionId(null);
    } else {
      setOpenAccordionId(id);
    }
  };

  const setImageRequest = async ({ image }: { image: any }) => {
    try {
      setLoadingImageUrl(image.url);
      const result = await storyBoardsApiClient.shot.setImage({
        id: selectedShot?.id,
        storyId: storyId ?? "",
        imageId: image?.id
      });
      if (result.status === 201) {
        setMainImage(`${image?.url}`);
        console.log({ result, img: image?.url });
        setSelectedShot && setSelectedShot(result.data);
        // retrieveAllScenesRequest();
      } else {
        toast.error("Something went wrong with updating the prompt");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    } finally {
      setLoadingImageUrl(null);
    }
  };

  function ShotGeneration() {
    return (
      <div className="h-full w-full flex flex-col">
        <div className="flex-1 flex justify-center items-center overflow-auto">
          {selectedShot && (
            <Shot
              key={selectedShot}
              newInProgress={newInProgress}
              newImageRequest={newImageRequest}
              shot={selectedShot}
              inGeneration={inGeneration}
              setSelectedShot={setSelectedShot}
              retrieveAllScenesRequest={() => retrieveAllScenesRequest()}
              scenes={scenes}
              setScenes={setScenes}
              setNewInProgress={setNewInProgress}
              loadingImageUrl={loadingImageUrl}
              setLoadingImageUrl={setLoadingImageUrl}
              mainImage={mainImage}
              setMainImage={setMainImage}
              setImageRequest={setImageRequest}
              sceneDescription={scenes.find(scene => scene.shots.some((shot: { id: any; }) => shot.id === selectedShot?.id))?.description}
            />
          )}
        </div>
      </div>
    );
  }

  function TimeLine() {

    // Function to reorder the list
    const reorder = (list: any[] | Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const onDragEnd = async (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      // Check if the destination index is within the range of grayed-out shots
      const isDestinationGrayedOut = isFree && result.destination.index >= 6;

      // Revert the drag if the destination is grayed out
      if (isDestinationGrayedOut) {
        return;
      }

      const move = (array: Shot[], moveInfo: { id: string; newIndex: number }): Shot[] => {
        const { id, newIndex } = moveInfo;
        const itemToMove = array.find((item: Shot) => item?.id === id);

        if (!itemToMove) {
          return array;
        }

        const newArray: Shot[] = array.filter((item: Shot) => item?.id !== id);

        newArray.splice(newIndex, 0, { ...itemToMove, index: newIndex });
        newArray.forEach((item: Shot, index: number) => (item.index = index));
        return newArray;
      };

      if (result.type === "SCENE") {
        const _scenes = reorder(scenes, result.source.index, result.destination.index);

        setScenes([..._scenes]);

        await handleSceneReorder({
          newIndex: result.destination.index,
          sceneId: result.draggableId,
        });
      } else if (result.type === "SHOT") {
        let _scenes = [...scenes];

        const sceneIndex = _scenes.findIndex((scene: any) => scene?.id === result.destination.droppableId);

        _scenes[sceneIndex].shots = move(_scenes[sceneIndex].shots, {
          id: result.draggableId,
          newIndex: result.destination.index,
        });

        _scenes = updateGlobalIndices(_scenes);
        setScenes([..._scenes]);

        await handleShotReorder({
          newIndex: result.destination.index,
          shotId: result.draggableId,
        });
      }
    };

    return (
      <>
        <div id="timeline-wrapper" className="relative max-h-[90vh] overflow-y-auto bg-opacity-100">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="scenes" type="SCENE">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  id="timeline"
                  className="pb-10 pr-6"
                >
                  {scenes?.map((scene, sceneIndex) => (
                    <Draggable key={scene?.id} draggableId={scene?.id} index={sceneIndex}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} className="accordion">
                          <Accordion
                            title={`Scene ${sceneIndex + 1}: ${scene?.content}`}
                            dragHandleProps={provided.dragHandleProps}
                            onUpdate={handleSceneUpdate}
                            content={currentScene.content}
                            description={currentScene.description}
                            scene={scene}
                            scenes={scenes}
                            sceneIndex={sceneIndex}
                            setScenes={setScenes}
                            isOpen={openAccordionId === scene?.id}
                            onToggle={() => toggleAccordion(scene?.id)}
                            setOpenAccordionId={setOpenAccordionId}
                            setSelectedShot={setSelectedShot}
                          />
                          {openAccordionId === scene?.id && (
                            <div className="shot-cards-container overflow-hidden">
                              <Droppable droppableId={scene?.id} type="SHOT">
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {scene?.shots?.map((shot: any, shotIndex: number) => (
                                      <Draggable
                                        key={shot?.id}
                                        draggableId={shot?.id}
                                        index={shotIndex}
                                        isDragDisabled={isFree && shot.globalIndex >= FREE_TIER_SHOT_LIMIT && !story.isTemplate}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="shot-card"
                                          >
                                            {ShotCard(shot, shotIndex, scene, shot.globalIndex, isFree)}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    {/* Plus Button - After Shot */}
                                    <AddShot scene={scene} index={scene?.shots?.length} scenes={scenes} />
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          )}

                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                  <AddScene index={scenes.length} scenes={scenes} />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  }

  // State to track whether the viewport is in mobile view
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 640);

  // Effect hook to add event listener for viewport changes
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth < 640);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDeleteImage = async (imageId: string) => {
    try {
      const result = await storyBoardsApiClient.image.delete({
        id: imageId,
        storyId: storyId ?? ""
      });
      console.log({ result })
      if (result.status === 200) {
        const updatedShot = {
          ...selectedShot,
          images: [...selectedShot.images].filter((image: any) => image?.id !== imageId)
        };

        setSelectedShot(updatedShot);
      } else {
        toast.error("Something went wrong with deleting the image");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const location = useLocation();
  const [viewMode, setViewMode] = useState<"builder" | "preview">("builder");

  useEffect(() => {
    if (location.state?.viewMode === "preview") {
      setViewMode("preview");
    } else {
      setViewMode("builder");
    }
  }, [location.state]);

  useEffect(() => {
    retrieveAllScenesRequest();
    if (viewMode === "builder") {
      setViewMode("builder");
      appContext.setBoardScreenViewMode("BUILDER");
    } else if (viewMode === "preview") {
      setViewMode("preview");
    }
  }, [viewMode]);

  const textareaRef: any = useRef(null);

  const [data]: any = useAuth();

  const updateStyleRequest = async ({ value, title }: { value: string, title: string }) => {
    setSelectedStyle(value);
    try {
      const result = await storyBoardsApiClient.story.updateStory({
        id: storyId ?? "",
        style: value
      });
      if (result.status === 200) {
        // toast.success(`Style ${title} selected`);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const validateAndAnalyzeStory = () => {
    const storyText = textareaRef.current.value;
    const wordCount = storyText.trim().split(/\s+/).length;

    if (wordCount > 600) {
      toast.error('Stories are currently limited to 600 words.');
    } else {
      analyzeStoryRequest({
        story: storyText,
        parameters: { requestedShots: requestedShots }
      });
    }
  };

  const analyzeStoryRequest = async ({ story, parameters }: { story: string, parameters: any }) => {
    const availableCredits = account?.stats?.availableCredits ?? 200;
    const availableWizardCredits = account?.stats?.availableWizardCredits ?? 1;
    console.log(`admin: ${isAdmin}`)
    console.log(`isFree: ${isFree}`)

    if (isFree) {
      if ((availableCredits < 20 && availableCredits !== -1) || availableWizardCredits === 9) {
        openModal({ mode: "SUBSCRIBE", data: {}, trigger: "ANALYZE" });
        return;
      }
    }

    sendEventToGoogleTagManager("ai_wizard_start", {
    }, userId, availableCredits);

    setCurrentStory((prevStory: { parameters: any; }) => ({
      ...prevStory,
      parameters: {
        ...prevStory.parameters,
        ...parameters
      }
    }));

    try {
      const result = await storyBoardsApiClient.story.analyze({
        id: storyId ?? "",
        story,
        parameters
      } as any);

      console.log(result, 'result')

      if (result.status === 201) {
        console.log('Setting isAnalyzing to true');
        setIsAnalyzing(true);
        sendEventToGoogleTagManager("ai_wizard_start", {
        }, userId, availableCredits);
        updateStep(2);
      } else if (result.status === 202) {
        getStory({ id: storyId })
        toast("Analysis process was successful!");
      } else {
        toast.error("Unexpected response status.");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong during the analysis process.");
    }
  }

  const generateAIWizardImagesRequest = async ({ id }: { id: string }) => {
    console.log('Generating AI Wizard images....');
    const availableCredits = account?.stats?.availableCredits ?? 200;
    const availableWizardCredits = account?.stats?.availableWizardCredits ?? 1;
    console.log(`admin: ${isAdmin}`)
    console.log(`isFree: ${isFree}`)

    if (isFree) {
      if ((availableCredits < 20 && availableCredits !== -1) || (availableWizardCredits === 0)) {
        openModal({ mode: "SUBSCRIBE", data: {}, trigger: "ANALYZE" });
        return;
      }
    }

    try {
      const result = await storyBoardsApiClient.story.generateAIWizardImages({
        id: storyId ?? ""
      });

      if (result.status === 201) {
        setInGeneration(true);
        setIsGenerating(true);
      } else {
        toast.error("Unexpected response status.");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong during the image generation process.");
    }
  }

  const handleDropFile = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const firstDroppedFile = Array.from(droppedFiles)[0];

      const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (!validImageTypes.includes(firstDroppedFile.type)) {
        toast.error("Invalid file type. Please upload a PNG, JPEG or JPG file.");
      } else {
        appContext.setDroppedFileImg2Img(firstDroppedFile);
      }
    }
  };

  const updateGlobalIndices = (scenes: (Scene | undefined)[]): (Scene | undefined)[] => {
    let globalIndex = 0;
    return scenes.map(scene => {
      if (!scene || !scene.shots) return scene;
      return {
        ...scene,
        shots: scene.shots.map((shot: Shot) => {
          const updatedShot = {
            ...shot,
            globalIndex: globalIndex++
          };
          // console.log(`Updated shot ${shot.id} with global index: ${updatedShot.globalIndex}`);
          return updatedShot;
        })
      };
    });
  };

  const [analysisSteps, setAnalysisSteps] = useState([
    { name: 'Step 1', href: '#', status: 'upcoming' },
    { name: 'Step 2', href: '#', status: 'upcoming' },
    { name: 'Step 3', href: '#', status: 'upcoming' },
    { name: 'Step 4', href: '#', status: 'upcoming' },
    { name: 'Step 5', href: '#', status: 'upcoming' },
  ]);


  // Function to update the status of analysis steps based on analyzeStep
  const updateStep = (newStep: number | null) => {
    console.log('Updating step to:', newStep);
    setCurrentAnalyseStep(newStep);
    if (story) {
      story.analyzeStep = newStep;
    }
  };

  // Assuming this is within a functional component
  useEffect(() => {
    if (story && story?.analyzeStep !== null) {
      console.log(`analysisStep = ${story?.analyzeStep}`);
      setAnalysisSteps(prevSteps =>
        prevSteps.map((step, index) => ({
          ...step,
          status: index < story?.analyzeStep - 1 ? 'complete' : index === story?.analyzeStep - 1 ? 'current' : 'upcoming'
        }))
      );
    }
  }, [story?.analyzeStep]);



  function AnalysisStepsBar({ steps }: { steps: any[] }) {
    console.log("StepBar at: ", steps)
    return (
      <nav aria-label="Progress">
        <ol role="list" className="flex items-center">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
              {step.status === 'complete' ? (
                <>
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="h-0.5 w-full bg-sky-600" />
                  </div>
                  <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-sky-600">
                    <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
                    <span className="sr-only">{step.name}</span>
                  </div>
                </>
              ) : step.status === 'current' ? (
                <>
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div aria-current="step" className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-sky-600 bg-white">
                    <span aria-hidden="true" className="h-2.5 w-2.5 rounded-full bg-sky-600" />
                    <span className="sr-only">{step.name}</span>
                  </div>
                </>
              ) : (
                <>
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                    <span aria-hidden="true" className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    <span className="sr-only">{step.name}</span>
                  </div>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    );
  }

  // Utility function for classNames
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout;

    if (isAnalyzing) {
      console.log('isAnalyzing is true in useffect...');
      pollingInterval = setInterval(async () => {
        try {
          console.log('Polling story data after analysis...');
          const result = await storyBoardsApiClient.story.getStory({
            id: storyId ?? ""
          });
          console.log('Polling story data after analysis...', 'Result:', result);
          if (result.data.analyzeStep === 3) {
            setCurrentStory(result.data);
            updateStep(result.data.analyzeStep);
            setIsAnalyzing(false);
            clearInterval(pollingInterval);
          }
        } catch (error) {
          console.error("Error fetching story during analysis polling:", error);
        }
      }, 5000);
    }

    if (isGenerating) {
      console.log('isGenerating is true in useffect...');
      pollingInterval = setInterval(async () => {
        try {
          console.log('Polling story data after generating...');
          const result = await storyBoardsApiClient.story.getStory({
            id: storyId ?? ""
          });
          console.log('Polling story data after generating...  Result:', result);
          if (result.data.analyzeStep === 0) {
            setCurrentStory(result.data);
            updateStep(result.data.analyzeStep);
            setIsGenerating(false);
            clearInterval(pollingInterval);
          }
        } catch (error) {
          console.error("Error fetching story during image generation polling:", error);
        }
      }, 11000);
    }

    return () => clearInterval(pollingInterval);
  }, [isAnalyzing, isGenerating, storyId]);

  // Hook to update the analysis steps depending on story
  useEffect(() => {
    if (story) {
      if (story.type === 'manual') {
        console.log("updatestep from hook [story]")
        updateStep(0);
      }
    }
  }, [story]);

  const prevNonZeroStepRef = useRef<number | null>(null);

  // this should reload the component if the analyse step changes from any other number to zero or 3
  useEffect(() => {
    console.log('Current analyse step changed:', currentAnalyseStep);
    if (currentAnalyseStep !== null) {
      if ((currentAnalyseStep === 0 || currentAnalyseStep === 3) && prevNonZeroStepRef.current !== null) {
        console.log(`found step ${currentAnalyseStep}. Refreshing page`);
        // Reload the component
        window.location.reload();
      } else if (currentAnalyseStep !== 0 && currentAnalyseStep !== 3) {
        prevNonZeroStepRef.current = currentAnalyseStep;
      }
    }
  }, [currentAnalyseStep]);

  const [storyText, setStoryText] = useState('');

  const commonContainerClasses = "h-screen w-full flex flex-col items-center mt-10 z-10";
  const commonContentClasses = "w-full max-w-4xl px-4 flex flex-col items-center space-y-8 z-10";
  const commonButtonClasses = "mt-8 px-6 py-3 flex items-center justify-center bg-sky-500 hover:bg-sky-600 rounded text-sm font-semibold text-neutral-100";
  const commonTitleClasses = "text-neutral-100 font-bold text-3xl mb-4 text-center";

  const renderButton = (text: any, onClick: any, disabled = false) => (
    <button
      type="button"
      className={`${commonButtonClasses} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      <ArrowRightIcon className="ml-2 h-5 w-5" />
    </button>
  );

  const renderContent = () => {
    console.log("renderContent currentAnalyseStep:", currentAnalyseStep);
    console.log("renderContent inAnalyze:", story.inAnalyze);

    if (story.type === 'manual' || currentAnalyseStep === 0) {
      return null; // We'll render the full UI outside of this function
    }

    switch (currentAnalyseStep) {
      case 1:
        console.log("case 1");
        if (!story.inAnalyze) {
          return null;
        }
        return (
          <>
            <h1 className={commonTitleClasses}>Step 1: Add Your Story</h1>
            <textarea
              rows={10}
              ref={textareaRef}
              className='w-full rounded bg-neutral-800 ring-1 ring-neutral-700 text-neutral-400 p-4'
              placeholder="Copy your text here"
              value={storyText}
              onChange={(e) => setStoryText(e.target.value)}
            />
            <div className="w-full mt-4 flex items-center justify-between">
              <span className="text-neutral-300">How many storyboard frames should this be?</span>
              <div className="relative flex items-center">
                <input
                  type="number"
                  min="1"
                  max="20"
                  value={requestedShots}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value >= 1 && value <= 20) {
                      setRequestedShots(value);
                    }
                  }}
                  className="w-16 px-2 py-1 text-center text-neutral-300 bg-neutral-800 border border-neutral-500 rounded-md appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
                <div className="flex flex-col ml-1">
                  <button
                    className="text-neutral-500 cursor-pointer focus:outline-none"
                    onClick={() => setRequestedShots(prev => Math.min(prev + 1, 20))}
                    type="button"
                  >
                    <ChevronUpIcon className="h-5 w-5" />
                  </button>
                  <button
                    className="text-neutral-500 cursor-pointer focus:outline-none"
                    onClick={() => setRequestedShots(prev => Math.max(prev - 1, 1))}
                    type="button"
                  >
                    <ChevronDownIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
            <div className="fixed bottom-20 right-20">
              {renderButton("Next", () => {
                if (!textareaRef.current.value) {
                  toast.error("Please enter your story.");
                } else if (isFree && requestedShots > 10) {
                  toast.error("Free users are limited to 10 frames. Please upgrade to create longer storyboards.");
                  setRequestedShots(10);
                } else {
                  validateAndAnalyzeStory();
                }
              }, !storyText)}
            </div>
          </>
        );


      case 2:
        console.log("case 2");
        return (
          <>
            <CustomLoadingSpinner size={60} />
            <h1 className={commonTitleClasses}>Step 2: Analyzing Your Story</h1>
            <p className="text-neutral-100 text-center">
              Please wait while we process your story...
            </p>
          </>
        );
      // Case 3: Character Selection
      case 3:
        console.log("case 3");
        return (
          <div className="flex flex-col h-full">
            <div className="flex-grow px-4 sm:px-6 md:px-8 py-4 sm:py-6 md:py-8 items-center">
              <h1 className={commonTitleClasses}>Step 3: Character Selection</h1>
              <p className="text-neutral-100 text-center mb-4">
                We found these characters in your story:
              </p>
              <div className="w-full h-full overflow-y-auto justify-center">
                <CastUIWrapper mode="dark" />
              </div>
            </div>
            <div className="fixed bottom-20 right-20">
              {renderButton("Next", () => { updateStep(4); })}
            </div>
          </div>
        );

      // Case 4: Choose Your Style
      case 4:
        console.log("case 4");
        return (
          <div className="flex flex-col">
            <div className="flex-none p-4 sm:p-6 md:p-8">
              <h1 className={commonTitleClasses}>Step 4: Choose Your Style</h1>
            </div>
            <div className="flex-grow overflow-y-auto p-4 sm:p-6 md:p-8">
              {isFree && (
                <div className="flex justify-end mb-4">
                  <div className="flex items-center gap-x-2">
                    <Switch
                      checked={onlyCurrentSubscriptionSwitch}
                      onChange={setOnlyCurrentSubscriptionSwitch}
                      className={`${onlyCurrentSubscriptionSwitch ? "bg-blue-600" : "bg-gray-400"
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span
                        className={`${onlyCurrentSubscriptionSwitch ? "translate-x-6" : "translate-x-1"
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                    <p className="text-neutral-300">In your subscription</p>
                  </div>
                </div>
              )}
              <div className="h-[40vh] overflow-y-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                  {stylesData.map((style, index) => {
                    const isStyleAvailable = isAdmin ||
                      FREE_STYLE_INDICES.includes(style.index) ||
                      !isFree;

                    if (!isFree || !onlyCurrentSubscriptionSwitch || isStyleAvailable) {
                      return (
                        <Style
                          key={style?.index}
                          index={style?.index}
                          img={style?.img}
                          title={style?.title}
                          description={style?.description}
                          clientIndex={index + 1}
                          onClick={(value: string) => {
                            if (isFree && !FREE_STYLE_INDICES.includes(value)) {
                              openModal({ mode: "SUBSCRIBE", data: {}, trigger: "STYLE" });
                            } else {
                              updateStyleRequest({ value, title: style?.title });
                            }
                          }}
                          isActive={selectedStyle === style?.index}
                          showStar={
                            !isAdmin &&
                            !FREE_STYLE_INDICES.includes(style?.index) &&
                            isFree
                          }
                          textColor="text-neutral-300"
                          hoverTextColor="hover:text-neutral-300"
                          borderColor="border-neutral-700"
                          hoverBorderColor="hover:border-neutral-600"
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
            <div className="fixed bottom-20 right-20">
              {renderButton("Create Storyboard", () => {
                updateStep(5);
                generateAIWizardImagesRequest({ id: story?.id });
              })}
            </div>
          </div>
        );
      case 5:
        console.log("case 5");
        return (
          <>
            <CustomLoadingSpinner size={60} />
            <h1 className={commonTitleClasses}>Step 5: Generating Images</h1>
            <p className="text-neutral-300 text-center">
              This process may take a few minutes.
            </p>
            <p className="text-neutral-300 text-center">
              You can safely close this window. We'll email you when it's ready.
            </p>
          </>
        );
      default:

    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex flex-col justify-center items-center min-h-screen backdrop-blur-lg">
          <CustomLoadingSpinner size={60} />
          <p className="text-neutral-100 mt-3">Getting your story ready...</p>
        </div>
      ) : !story || !initialLoadComplete ? (
        <div className="flex flex-col justify-center items-center min-h-screen">
          <p className="text-neutral-100">Error loading story. Please try again.</p>
        </div>
      ) : (
        story.type === 'manual' || currentAnalyseStep === 0 || (currentAnalyseStep === 1 && story.isAnalyzed) ? (
          // Full UI for manual stories or completed AI stories
          <div className="h-full w-full bg-neutral-900 z-20 overflow-hidden">
            <div id="app" className="app h-full flex flex-col sm:flex-row justify-center relative">
              <ExportButton />
              {viewMode === "builder" ? (
                isMobileView ? (
                  <>
                    {/* Mobile View */}
                    <div className="max-w-full w-full sm:px-4 mt-4 pb-10">
                      {ShotGeneration()}
                    </div>
                    <div className="max-w-full w-full sm:px-4 mt-4 h-full">
                      {TimeLine()}
                    </div>
                  </>
                ) : (
                  <>
                    {/* Web View */}
                    <div className="max-w-full sm:max-w-[800px] w-full sm:w-1/2 px-10 sm:px-0 md:px-4 h-full overflow-hidden">
                      {!["INPAINT", "IMG2IMG"].includes(appContext.boardScreenViewMode) && TimeLine()}
                      {appContext.boardScreenViewMode === "INPAINT" && (
                        <div id="inpaint-response">
                          {appContext.inpaintResponseImage !== undefined ? (
                            <div className="sm:px-4 lg:px-12 mt-[100px]">
                              <img src={appContext.inpaintResponseImage?.url} alt="Inpaint Response" />
                              <div className="flex flex-row w-full gap-x-4 mt-4 justify-end">
                                <button className="text-neutral-500 hover:bg-neutral-300 border border-neutral-500 rounded py-2 px-4 ml-2" onClick={() => {
                                  appContext.setInpaintResponseImage(undefined);
                                  // setNewImagePrompt(getMetadata()?.metadata?.imageDescription ?? "");
                                  handleDeleteImage(appContext.inpaintResponseImage?.id);
                                }}>
                                  Cancel
                                </button>
                                <button className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4" onClick={() => {
                                  setImageRequest({ image: appContext.inpaintResponseImage });
                                  appContext.setInpaintResponseImage(undefined);
                                  appContext.setBoardScreenViewMode("BUILDER");
                                }}>Save</button>
                              </div>
                            </div>
                          ) : (
                            <div className="sm:px-4 lg:px-12 mt-[100px]">
                              <img className="w-full h-full aspect-video" src={"https://story-board-images.s3.us-west-1.amazonaws.com/placeholder_result.webp"} alt="placeholder" />
                              <div className="flex flex-row w-full gap-x-4 mt-4 justify-end">
                                <button className="text-neutral-500 hover:bg-neutral-300 border border-neutral-500 rounded py-2 px-4 ml-2 cursor-not-allowed" disabled>
                                  Cancel
                                </button>
                                <button className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 cursor-not-allowed" disabled={true}>Save</button>
                              </div>
                            </div>
                          )
                          }
                        </div>
                      )}
                      {appContext.boardScreenViewMode === "IMG2IMG" && (
                        <UniversalTippy content="Add a source image that best matches your desired Shot">
                          <div className="sm:px-4 lg:px-12 mt-[100px]">
                            <div
                              className="w-full h-full bg-neutral-800/50 hover:bg-neutral-800 border border-neutral-600 aspect-video flex justify-center items-center cursor-pointer rounded-sm"
                              onDrop={(e) => handleDropFile(e)}
                              onDragOver={(e) => e.preventDefault()}
                              onClick={() => {
                                const uploadFileInput = document.getElementById("upload-file-input");
                                if (uploadFileInput) {
                                  uploadFileInput.click();
                                }
                              }}
                            >
                              {(appContext.droppedFileImg2Img !== undefined && ["image/png", "image/jpeg"].includes(appContext.droppedFileImg2Img.type)) ? (
                                <div className="relative w-full h-full">
                                  <img
                                    className="aspect-video rounded-md w-full h-full object-cover"
                                    id="dropped-image-img2img"
                                    src={URL.createObjectURL(appContext.droppedFileImg2Img)}
                                    alt="uploaded"
                                  />
                                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                                    <p className="text-white">Click or drag to replace image</p>
                                  </div>
                                </div>
                              ) : (
                                <div className="text-center p-4">
                                  <img src={require("../../../assets/icons/dragndrop.png")} className="mx-auto mb-4 w-1/5 filter brightness-75 invert" alt="Drag and Drop Icon" />
                                  <p className="text-neutral-300">Add Source Image</p>
                                </div>
                              )}
                              <input id="upload-file-input"
                                type="file"
                                hidden
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const droppedFiles = e.target.files;
                                  if (droppedFiles?.length) {
                                    appContext.setDroppedFileImg2Img(droppedFiles[0]);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </UniversalTippy>
                      )}
                    </div>
                    {appContext.boardScreenViewMode !== "EDIT_DESCRIPTION" ? (
                      <div className="max-w-full sm:max-w-[800px] w-full sm:w-1/2 sm:px-0 h-full overflow-hidden">
                        <div id="shot-generation" className="h-full overflow-y-auto">
                          {ShotGeneration()}
                        </div>
                      </div>
                    ) : (
                      <div className="max-w-full sm:max-w-[800px] w-full sm:w-1/2 sm:px-0 mt-4 sm:mt-0 sm:pb-8">
                        <EditDescription
                          scene={scenes.find((scene: any) => scene?.shots?.some((shot: any) => shot.id === selectedShot.id))}
                          shot={scenes.find((scene: any) => scene?.shots?.some((shot: any) => shot.id === selectedShot.id))?.shots.find((shot: any) => shot.id === selectedShot.id)}
                          story={story}
                          scenes={scenes}
                          setScenes={setScenes}
                        />
                      </div>
                    )}
                  </>
                )
              ) : (
                <PreviewScreen scenes={scenes} setScenes={setScenes} />
              )}
              {appContext.boardScreenViewMode !== "INPAINT" && (
                <div className="fixed bottom-8 right-8 z-[200] h-[48px]">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={viewMode === "preview"}
                      onChange={() => {
                        if (localStorage.getItem("mode-toggle-tour") !== "viewed") {
                          localStorage.setItem("mode-toggle-tour", "viewed")
                        }
                        setViewMode(viewMode === "preview" ? "builder" : "preview");
                        setPreviewButtonClicked(true);
                        setCurrentStep(currentStep + 1);
                      }}
                    />
                    <div className="relative w-16 h-8" id="switch-mode-toggle">
                      <div
                        className={`w-22 h-8 max-h-8 rounded-full ${viewMode === "preview"
                          ? "bg-gray-400 flex-row-reverse"
                          : "bg-gray-400"
                          } p-1 transition-colors duration-300 ease-in-out flex justify-between items-center`}
                      >
                        <div
                          className={`flex justify-center items-center w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${viewMode === "preview" ? "translate-x-0" : "translate-x-0"
                            }`}
                        >
                          {viewMode === "preview" ? (
                            <div className="flex justify-center items-center">
                              <Squares2X2Icon className="text-neutral-800 h-4 w-4" />
                            </div>
                          ) : (
                            <div className="flex justify-center items-center">
                              <QueueListIcon className="text-neutral-800 h-4 w-4" />
                            </div>
                          )}
                        </div>
                        {viewMode !== "preview" ? (
                          <div className="flex justify-center items-center mr-[4px]">
                            <Squares2X2Icon className="text-neutral-800 h-4 w-4" />
                          </div>
                        ) : (
                          <div className="flex justify-center items-center ml-[4px]">
                            <QueueListIcon className="text-neutral-800  h-4 w-4" />
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              )}
            </div>
          </div>
        ) : (
          // Step-by-step UI for AI stories in progress
          <div className={commonContainerClasses}>
            <div className={commonContentClasses}>
              <AnalysisStepsBar steps={analysisSteps} />
              {renderContent()}
            </div>
          </div>
        )
      )}
    </div>
  )
};
