import { HttpClient } from "../client";

import { AuthRto } from "../../models/auth/authRto.model";
import { AuthSignInDto } from "../../models/auth/authSignInDto.model";

import type { ApiActionParams } from "../../types/api";

export class Auth extends HttpClient {
  private readonly prefix: string = "auth";

  public constructor(url: string) {
    super(url);
  }

  public signIn = ({ dto }: ApiActionParams<AuthSignInDto>) => {
    return this.instance.post<AuthRto>(`/${this.prefix}/sign-in`, dto);
  };

  public signOut = () => {
    return this.instance.post<AuthRto>(`/${this.prefix}/logout`);
  };
}