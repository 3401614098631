import { HttpClient } from "../client";

export class Payment extends HttpClient {
  private readonly prefix: string = "payment";

  public constructor(url: string) {
    super(url);
  }

  public subscribe = ({
                        tier, frequency
                      }: {
    tier: number;
    frequency: string;
  }) => {
    return this.instance.post<{ status: any; data: any }>(
      `${this.prefix}/stripe`,
      {
        tier, frequency
      }
    );
  };

  public billingHistory = () => {
    return this.instance.get<{ status: any; data: any }>(
      `${this.prefix}/stripe/billing-history`
    );
  };

  public cancelSubscription = () => {
    return this.instance.post<{ status: any; data: any }>(
        `${this.prefix}/stripe/cancel-subscription`
    );
  };
}
