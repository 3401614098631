import { useSelector } from "react-redux";
import { useBaseModalContext } from "../modals/base-modal/BaseModalContext";

interface IRequiredProps { }

interface IOptionalProps { }

interface IProps extends IRequiredProps, IOptionalProps { }

export default function DowngradeModal({ }: IProps) {

  const { inTrial } = useSelector((state: any) => ({
    inTrial: state.app.account?.inTrial,
  }));

  const { setIsOpen, modalData } = useBaseModalContext();

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (modalData.downgrade) {
      await modalData.downgrade();
      setIsOpen(false);
    } else {
      console.warn("Downgrade function is not defined in modalData.");
    }
  };

  const cancel = async (e: any) => {
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <div className="text-lg font-semibold leading-6 text-neutral-900 text-center">
            {modalData?.title}
          </div>
          <div className="mt-8 flex flex-col items-center gap-2">
            <p className="text-base font-semibold text-neutral-900">
              {inTrial ? "We're sorry to see you go." : "Sorry you're leaving"}
            </p>
            <form onSubmit={handleFormSubmit}>
              <div className="flex items-center gap-x-2 my-4">
                {inTrial ? (
                  <>
                    Your trial will end right away,
                    <br />
                    and you'll transition to the Free Plan.
                  </>
                ) : (
                  <>
                    Your subscription will not renew after the current period
                    <br />
                    and you will move to the Free Plan. Thanks for being with us.
                  </>
                )}
              </div>
              <div className="relative">
                <div className="flex gap-x-4 justify-end pr-2 py-2">
                  <button
                    className="text-neutral-500 hover:bg-neutral-300 border border-neutral-500 rounded py-2 px-4 w-24"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 w-24"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}