import { HttpClient } from "../client";

import { ApiActionParams } from "../../types/api";
import { TeamRto } from "../../models/team/teamRto.model";

export class Image extends HttpClient {
  public constructor(url: string) {
    super(url);
  }

  private readonly prefix: string = "images";

  public update = ({
                     id,
                     storyId,
                     shotId
                   }: {
    id: string;
    storyId: string;
    shotId?: string;
  }) => {
    return this.instance.patch<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`,
      {
        shotId
      }
    );
  };

  public delete = ({
                     id,
                     storyId,
                   }: {
    id: string;
    storyId: string;
  }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`,

    );
  };
}
