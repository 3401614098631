import { useSelector } from 'react-redux';

type PlanType = 'Free' | 'Indie' | 'Pro' | 'Studio';

interface PlanCosts {
    image: number;
    inpaint: number;
    img2Img: number;
    wizard: number;
}

interface PlanInfo {
    isFree: boolean;
    isPlan: PlanType;
    costs: PlanCosts;
}

export const freeUserCosts: PlanCosts = {
    image: 2,
    inpaint: 8,
    img2Img: 15,
    wizard: 20
};

export const paidUserCosts: PlanCosts = {
    image: 1,
    inpaint: 5,
    img2Img: 10,
    wizard: 15
};

const useUserPlan = (): PlanInfo => {
    const account = useSelector((state: any) => state.app?.account);
    const isAdmin = process.env.REACT_APP_ADMIN === 'true';

    const checkUserPlan = (): PlanInfo => {
        const subscriptionId = account?.subscription?.id;
        const isActive = account?.subscription?.active;

        if (isAdmin) {
            console.log("admin override")
            return { isFree: false, isPlan: 'Studio', costs: paidUserCosts };
        }

        if (!isActive || !subscriptionId) {
            // console.log("Free   ")
            return { isFree: true, isPlan: 'Free', costs: freeUserCosts };
        }

        switch (subscriptionId) {
            case 'tier-indie':
                // console.log("Indie")
                return { isFree: false, isPlan: 'Indie', costs: paidUserCosts };
            case 'tier-pro':
                // console.log("Pro")
                return { isFree: false, isPlan: 'Pro', costs: paidUserCosts };
            case 'tier-studio':
                // console.log("Studio")
                return { isFree: false, isPlan: 'Studio', costs: paidUserCosts };
            default:
                // console.log("Free")
                return { isFree: true, isPlan: 'Free', costs: freeUserCosts };
        }
    };

    return checkUserPlan();
};

export default useUserPlan;