import React, { FC } from "react";
import styled, { keyframes } from "styled-components";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size: number;
}

const pulse = keyframes`
  0%, 60%, 100% {
    
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
`;

const scaleUp = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
`;

const SpinnerContainer = styled.div<Props>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: inline-block;
  position: relative;
`;

const Loader = styled.div<Props>`
  width: 100%;
  height: 100%;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: ${pulse} 1s linear infinite;
`;

const LoaderAfter = styled.div<Props>`
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: ${scaleUp} 1s linear infinite;
`;

export const CustomLoadingSpinner: FC<Props> = ({ size }) => {
  return (
    <SpinnerContainer size={size}>
      <Loader size={size}></Loader>
      <LoaderAfter size={size}></LoaderAfter>
    </SpinnerContainer>
  );
};
