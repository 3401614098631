import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import Projects from "../../app/layouts/projects";
import Dashboard from "../../app/layouts/dashboard";
import StoryLayout from "../../app/layouts/story";
import Modals from "../../app/components/modals";
import CharactersScreen from "../../app/screens/characters-screen";
import BoardScreen from "../../app/screens/board-screen";
import SignInScreen from "../../app/screens/sign-in-screen";
import TeamLayout from "../../app/layouts/team";
import TeamsScreen from "../../app/screens/teams-screen";
import TeamScreen from "../../app/screens/team-screen";
import AccountScreen from "../../app/screens/account-screen";
import DeveloperSettingsLayout from "../../app/layouts/developer-settings";
import ConfigScreen from "../../app/screens/config-screen";
import ExportScreen from "../../app/screens/export-screen";
import ResetPasswordScreen from "../../app/screens/reset-password-screen";
import CheckoutScreen from "../../app/screens/checkout/CheckoutScreen";

export const routes = (isLoggedIn: boolean) => [
  {
    path: "/",
    element: isLoggedIn ? (
      <>
        <Outlet />
      </>
    ) : (
      <Navigate to="/sign-in" />
    ),
    children: [
      {
        path: "",
        element: (
          <>
            <Modals />
            <Dashboard />
          </>
        ),
        children: [
          {
            path: "",
            exact: true,
            element: <Projects />
          },
          {
            path: "account",
            exact: true,
            element: <AccountScreen />
          },
          {
            path: "checkout",
            exact: true,
            element: <CheckoutScreen />
          },
          {
            path: "teams",
            element: <TeamLayout />,
            children: [
              {
                path: "",
                exact: true,
                element: <TeamsScreen />
              },
              {
                path: ":teamId",
                exact: true,
                element: <TeamScreen />
              }
            ]
          },
          {
            path: "stories/:storyId",
            element: <StoryLayout />,
            children: [
              {
                path: "characters",
                exact: true,
                element: <CharactersScreen />
              },
              {
                path: "board",
                exact: true,
                element: <BoardScreen />
              },
              {
                path: "export",
                exact: true,
                element: <ExportScreen />
              }
            ]
          },
          {
            path: "developer-settings",
            element: <DeveloperSettingsLayout />,
            children: [
              {
                path: "",
                exact: true,
                element: <ConfigScreen />
              }
            ]
          }
        ]
      },
      {
        path: "settings",
        exact: true,
        element: <div>Settings</div>
      }
    ]
  },
  {
    path: "/",
    element: !isLoggedIn ? (
      <>
        <Outlet />
      </>
    ) : (
      <Navigate to="/" />
    ),
    children: [
      { path: "", element: <Navigate to="/sign-in" /> },
      { path: "sign-in", element: <SignInScreen /> },
      { path: "sign-up", element: <div>SIGN UP</div> },
      { path: "reset-password", element: <ResetPasswordScreen /> },
      {
        path: "verification",
        element: <Outlet />,
        children: [
          {
            path: "activation",
            exact: true,
            element: <div>ACTIVATION</div>
          },
          {
            path: "email-change",
            exact: true,
            element: <div>EMAIL CHANGE</div>
          },
          {
            path: "recovery",
            exact: true,
            element: <div>RECOVERY</div>
          },
          {
            path: "termination",
            exact: true,
            element: <div>TERMINATION</div>
          }
        ]
      }
    ]
  }
];
