import { HttpClient } from "../client";

export class Video extends HttpClient {
  public constructor(url: string) {
    super(url);
  }

  private readonly prefix: string = "videos";

  public generateVideo = ({ storyId, params }: { storyId: string; params: any }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/${storyId}`,
      { params }
    );
  };

  public deleteVideo = ({ storyId, awsUrl }: { storyId: string; awsUrl: string }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `/${this.prefix}/${storyId}`,
      { data: { awsUrl } }
    );
  };
}