import { HttpClient } from "../client";

import { ApiActionParams } from "../../types/api";
import { TeamRto } from "../../models/team/teamRto.model";

export class Story extends HttpClient {
  public constructor(url: string) {
    super(url);
  }

  private readonly prefix: string = "stories";

  public createStory = ({
    label,
    story,
    description,
    id,
    type
  }: {
    label: string;
    story: string;
    description: string;
    id: string;
    type: 'ai' | "manual"
  }) => {
    return this.instance.post<{ status: any; data: any }>(`/${this.prefix}`, {
      label,
      story,
      description,
      id,
      type
    });
  };

  public createAiStory = ({ }: {}) => {
    return this.instance.post<{ status: any; data: any }>(`/${this.prefix}/ai`, {});
  };

  public getStory = ({ id }: { id: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `/${this.prefix}/${id}`
    );
  };

  public getStories = () => {
    return this.instance.get<{ status: any; data: any }>(`/${this.prefix}`);
  };

  public updateStory = ({
    id,
    label,
    story,
    description,
    style,
    analyzeStep
  }: {
    id: string;
    label?: string;
    story?: string;
    description?: string;
    style?: string;
    analyzeStep?: number;
  }) => {
    console.log("Updating story with:", { id, label, story, description, style, analyzeStep });
    return this.instance.patch<{ status: any; data: any }>(
      `/${this.prefix}/${id}`,
      { label, story, description, style, analyzeStep }
    );
  };

  public deleteStory = ({ id }: { id: string }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `/${this.prefix}/${id}`
    );
  };

  public analyze = ({ id, story, parameters }: { id: string, story: string, parameters: any }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/${id}/analyze`, { story, parameters }
    );
  };

  public generateImages = ({ id }: { id: string }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/${id}/generateImages`
    );
  };

  public generateAIWizardImages = ({ id }: { id: string }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/${id}/generateAIWizardImages`
    );
  };

  public reorderScenes = ({ storyId, newIndex, sceneId }: {
    storyId: string,
    newIndex: number,
    sceneId: string
  }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/${storyId}/reorder-scenes`,
      { newIndex, sceneId }
    );
  }

  public createExportPdf = ({ storyId, orientation, pageSize }: { storyId: string, orientation: "landscape" | "portrait", pageSize: "A4" | "LETTER" }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/${storyId}/create-export-pdf`, {
      orientation, pageSize
    }
    );
  }

  public createProjectWithTemplate = ({ templateId }: { templateId: string }) => {
    return this.instance.post<{ status: any; data: any }>(
      `/${this.prefix}/create-project-with-template`, {
      templateId
    }
    );
  }
}
