export enum ENUM_MODAL_TYPE {
  "EMPTY" = "empty",
  "IMAGE_VIEWER" = "image_viewer",
  "CONFIRM" = "confirm",
  "CREATE_PROJECT" = "create_project",
  "EDIT_CHARACTER" = "edit_character",
  "CREATE_TEAM" = "create_team",
  "INVITE_TEAM_MEMBER" = "invite_team_member",
  "FIRST_LOGIN_EMAIL_CONFIRM" = "first_login_email_confirm",
  "RENAME_PROJECT" = "rename_project",
  "GET_STARTED" = "get_started",
  "VIDEO" = 'video',
}
