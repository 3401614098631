import { BellIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from "../../../core/utils/useClickOutside";
import { toast } from 'react-toastify';
import { storyBoardsApiClient } from '../../../core/config/api';

export default function Notifications({}) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const modalRef: any = useRef(null);
  useOutsideClick({ ref: modalRef, isOpen: isVisible, setIsOpen: setIsVisible });

  const getNotificationsRequest = async () => {
    try{
      const result = await storyBoardsApiClient.notification.getNotifications({limit: 10, offset: 0})

      setCount(result.data.count)
      setNotifications(result.data.notifications)
    }catch(error){
      console.log("Unable to get notifications");
    }
  }

  const readNotificationRequest = async ({id}: {id: string}) => {
    try{
      const result = await storyBoardsApiClient.notification.readNotification({id})
    }catch(error){
      console.log("Unable to get notifications");
    }
  }

  const removeNotificationRequest = async ({id}: {id: string}) => {
    try{
      const result = await storyBoardsApiClient.notification.removeNotification({id})
    }catch(error){
      console.log("Unable to get notifications");
    }
  }

  // useEffect(()=> {
  //   getNotificationsRequest()
  // },[])

  return (
    <div ref={modalRef} className={"relative"}>
      <button className={"relative w-[32px] h-[32px] flex items-center justify-center hover:bg-neutral-700 rounded-md"}
              onClick={() => setIsVisible(!isVisible)}>
        <BellIcon className={"text-neutral-300 w-[20px] h-[20px]"} />
        {/*<div className={"absolute top-[2px] right-[2px] w-[8px] h-[8px] rounded-full bg-red-500"} />*/}
      </button>
      {isVisible && <div className={"absolute w-[400px] right-0 mt-3 bg-neutral-100 z-40 rounded-md shadow-md"}>
        <div className={"border-b border-neutral-300 px-4 py-2"}>
          <div className={"text-neutral-600"}>Notifications</div>
        </div>
        <div className={"px-4 max-h-[200px] overflow-y-auto"}>
          {notifications.length === 0 ? <p className={"text-neutral-600 py-2"}>No new notifications</p> : notifications?.map((notification)=> <div className='flex flex-col gap-y-2'><div>{notification.message}</div><button onClick={()=> readNotificationRequest({id: notification.id})}>Read</button><button onClick={()=> removeNotificationRequest({id:
            notification
            .id,
          })}>Delete</button></div>)}
        </div>
      </div>}
    </div>);
}