import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import { TReduxStore } from "../../../core/redux/store";
import { useSelector } from "react-redux";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export type TTeam = {
  id: string;
  label: string;
  domain: string;
};

interface IRequiredProps {
  team: TTeam;
}

interface IOptionalProps {
  onClick?: Function;
  isSelectable?: boolean;
}

interface IProps extends IRequiredProps, IOptionalProps {
}

export default function Team({ team, isSelectable, onClick }: IProps) {
  const recentTeam: string = useSelector(
    (state: TReduxStore) => state.app.account?.recentTeam
  );
  const navigate = useNavigate();

  const selectTeam = () => {
    onClick && onClick({ value: team?.id });
  };

  return (
    <div
      onClick={() => (isSelectable ? selectTeam() : navigate(team?.id))}
      className={`relative flex items-center space-x-3 rounded-lg border ${
        isSelectable && recentTeam === team?.id
          ? `border-blue-500 bg-blue-50`
          : `border-gray-300 bg-white`
      } px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400`}
    >
      <div className="min-w-0 flex-1">
        <a href="#" className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{team.label}</p>
          <p className="truncate text-sm text-gray-500">{team.domain}</p>
        </a>
      </div>
    </div>
  );
}
