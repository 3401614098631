import { DocumentIcon, XMarkIcon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { storyBoardsApiClient } from "../../../core/config/api";
import { useNavigate } from "react-router";
import Template from "./Template";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import { useSelector } from "react-redux";
import { useAppContext } from "../../../core/utils/AppContext";
import { toast } from "react-toastify";

interface IRequiredProps {
}

interface IOptionalProps {
}

interface IProps extends IRequiredProps, IOptionalProps {
}

interface IProps {
  isOpen?: boolean;
}

export default function Templates({ isOpen = true }: IProps) {
  const { userId, availableCredits } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits
  }));
  const [show, setShow] = useState<boolean>(isOpen)
  const [loadingTemplateId, setLoadingTemplateId] = useState<string>("");
  const navigate = useNavigate();
  const { setIsTemplateLoading, fetchProjects } = useAppContext();

  const handleGenerateProjectFromTemplate = async(templateId: string) => {

    const templateInstalled = localStorage.getItem(`demo-installed`);

    if (templateInstalled === templateId) {
      toast.info("This template project is already available");
      return;
    }

    setLoadingTemplateId(templateId);
    setIsTemplateLoading(true);

    try {
      const result = await storyBoardsApiClient.story.createProjectWithTemplate({
        templateId: templateId ?? ""
      });

      if (result.status === 201) {
        sendEventToGoogleTagManager("load_template_success", {
          event_category: "Project Management",
          event_action: "load template",
          event_label: "Success",
        }, userId, availableCredits);

        navigate(`/stories/${result.data?.id}/board`);
        localStorage.setItem(`demo-installed`, templateId);
        await fetchProjects();
      }
      console.log({ result });
    } catch (error) {
      console.error({ error });
    } finally {
      setLoadingTemplateId("");
      setIsTemplateLoading(false);
    }
  };

  const templateTitles = {
    "template01": "DAY IN THE LIFE OF ELLA"
  }

  return (
    <div id="templates-container" className={"w-full p-10"}>
      <div className={"w-full flex flex-col bg-neutral-700 rounded"}>
        <div className={"w-full h-[50px] flex px-4 justify-between items-center border-b border-b-neutral-600"}>
          <div className={"flex gap-x-2"}>
            <DocumentIcon className={"w-[20px] h-[20px] text-neutral-300"} />
            <h3 className={"text-neutral-300 font-bold text-sm"}>Demo Projects</h3>
          </div>
          <button onClick={() => setShow(!show)}>
            {show ? 
              <ChevronUpIcon className={"w-[20px] h-[20px] text-neutral-300"} /> :
              <ChevronDownIcon className={"w-[20px] h-[20px] text-neutral-300"} />
            }
          </button>
        </div>
        {show && (
          <div className={"w-full p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 auto-rows-fr"}>
            {["template01"].map((templateId, index) => (
              <Template
                key={templateId}
                handleGenerateProjectFromTemplate={handleGenerateProjectFromTemplate}
                templateId={templateId}
                templateTitle={templateTitles?.template01 ?? ""}
                index={index}
                loadingTemplateId={loadingTemplateId}
                templateThumbnail="https://story-board-images.s3.us-west-1.amazonaws.com/6ec1dd94-a22a-400a-a2b7-4ce6a88684e4/1712665061915.webp"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );

}

