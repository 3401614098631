import { useEffect } from "react";

export function useOutsideClick({
                                  ref,
                                  isOpen,
                                  setIsOpen
                                }: {
  ref: any;
  isOpen: boolean;
  setIsOpen: Function;
}) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        !isOpen && setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
}
