import React from "react";
import Character from "../../components/character";
import { TCharacter } from "../../components/character/Character";
import store, { TReduxStore } from "../../../core/redux/store";
import { ENUM_MODAL_TYPE } from "../../../core/types/modal.type";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../core/redux/actions/modalManagerActions";
import { useNavigate } from "react-router";
import { changeUrl } from "../../../core/utils/changeUrl";

type Props = {};

export default function CharactersScreen({}: Props) {
  const navigate = useNavigate();

  const characters = useSelector(
    (state: TReduxStore) => state.app.story?.characters
  );
  return (
    <div className={"p-8"}>
      <div className={"mx-auto max-w-7xl sm:px-6 lg:px-8 "}>
        {/*<div className="flex flex-row pb-8">*/}
        {/*  <div className="flex-1">*/}
        {/*    <p className="text-xl font-bold text-center">Your Characters</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="grid grid-cols-5 gap-4">
          {characters?.map((character: TCharacter) => (
            <Character
              key={character?.id}
              character={character}
              onClick={() =>
                store.dispatch(
                  //@ts-ignore
                  setModal({
                    modal: ENUM_MODAL_TYPE.EDIT_CHARACTER,
                    data: { character, mode: "UPDATE" }
                  })
                )
              }
            />
          ))}
        </div>

        <div className="flex flex-row justify-end mt-8 gap-x-2">
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-neutral-100 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() =>
              store.dispatch(
                //@ts-ignore
                setModal({
                  modal: ENUM_MODAL_TYPE.EDIT_CHARACTER,
                  data: { mode: "ADD" }
                })
              )
            }
          >
            Add character
          </button>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-neutral-100 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={()=>navigate(`${changeUrl(window.location.pathname, 'style')}`)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
