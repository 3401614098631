import { useState, useEffect } from 'react';

export function useMobileView() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateView = () => {
      setIsMobile(window.innerWidth < 768); // Example breakpoint
    };
    window.addEventListener('resize', updateView);
    updateView(); // Initialize check
    return () => window.removeEventListener('resize', updateView);
  }, []);

  return isMobile;
}
