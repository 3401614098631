import React, { Fragment, useEffect, useRef, useState } from "react";
import { storyBoardsApiClient } from "../../../core/config/api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { EllipsisVerticalIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { changeUrl } from "../../../core/utils/changeUrl";
import { useSelector } from "react-redux";
import { TReduxStore } from "../../../core/redux/store";
import { CustomLoadingSpinner } from "../../components/loading/CustomLoadingSpinner";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import ReactDOM from "react-dom";
import { useBaseModalContext } from "../../components/modals/base-modal/BaseModalContext";
import { Menu, Transition } from "@headlessui/react";
import { useTour } from "@reactour/tour";
import { useTourContext } from "../../../core/utils/TourContext";
import SaveIcon from "../../../assets/icons/saveIcon";
import { downloadImage } from "../../../core/utils/downloadImage";
import useUserPlan from "../../../core/utils/creditCost";
import { useTrialEligibility } from "../../../core/utils/useTrialEligibility";

type ImageWithMetadata = {
  id: string; // Add this line
  url: string;
  paidUrl: string;
  freeUrl: string;
  metadata: {
    angle: string;
    // Include other metadata properties here
  };
  // ... other properties of image
};


type Shot = {
  angle: string;
  createdAt: Date;
  id: string;
  index: number;
  promptDescription: string;
  shotDescription: string;
  updatedAt: Date;
  selectedImage: string;
  images: ImageWithMetadata[];
};

type Scene = {
  shots: Shot[];
  index: number;
};

type GalleryModalProps = {
  images: ImageWithMetadata[];
  setImage: (image: ImageWithMetadata) => Promise<void>;
  closeModal: () => void;
  shot: Shot;
};

type PreviewCardProps = {
  shot: Shot;
  index: number;
};

const cameraAngles = [
  { name: "closeup", color: "bg-red-500" },
  { name: "medium", color: "bg-green-500" },
  { name: "wide", color: "bg-blue-500" },
  { name: "top down", color: "bg-yellow-500" },
  { name: "dutch", color: "bg-purple-500" },
  { name: "low angle", color: "bg-pink-500" },
  { name: "high angle", color: "bg-indigo-500" },
  { name: "side view", color: "bg-teal-500" }
];

function getColorForAngle(angle: string) {
  const angleObj = cameraAngles.find(a => a.name.trim().toLowerCase() === angle.trim().toLowerCase());
  const color = angleObj ? angleObj.color : 'bg-gray-500';

  return color;
}


export default function PreviewScreen({ scenes, setScenes }: { scenes: Scene[], setScenes: Function }) {
  const { userId, availableCredits, account } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits,
    account: state.app?.account,
  }));
  const navigate = useNavigate();
  const { storyId } = useParams();

  const story = useSelector((state: TReduxStore) => state.app.story);

  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = process.env.REACT_APP_ADMIN === 'true';

  const retrieveAllScenesRequest = async () => {
    setIsLoading(true);
    console.log("retrieveAllScenesRequest");
    try {
      const result = await storyBoardsApiClient.scene.retrieveAll({
        storyId: storyId ?? ""
      });

      if (result.status === 200) {
        setScenes(result.data.scenes);
        setIsLoading(false);

        sendEventToGoogleTagManager("preview_screen_loaded", {
          story_management: "Story Management",
          scene_action: "create",
          story_id: story?.id,
          outcome: "success",
        }, userId, availableCredits);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
      console.log("Story received");
    }
  };

  const { isFree, isPlan, costs } = useUserPlan();

  const isShotBeyondFreeLimit = (index: number) => {
    return isFree && index >= 6 && !story.isTemplate;
    // return false;
  };

  function findSelectedImageAngle(shot: { selectedImage: any; images: any[]; }) {
    const selectedImageUrl = shot.selectedImage;
    const matchingImage = shot.images.find((image: { url: any; }) => image.url === selectedImageUrl);
    return matchingImage?.metadata?.angle || 'unknown';
  }

  const [loadingImageUrl, setLoadingImageUrl] = useState<string | null>(null);

  const GalleryModal: React.FC<GalleryModalProps> = ({ images, setImage, closeModal, shot }) => {

    const [mainImage, setMainImage] = useState<string>(`${shot?.selectedImage ?? ""}`);

    const validImages = shot?.images?.filter((image: any) => image.url !== "https://story-board-images.s3.us-west-1.amazonaws.com/placeholder_result.webp");

    const setImageRequest = async ({ image }: { image: any }) => {
      try {
        setLoadingImageUrl(image.url);
        const result = await storyBoardsApiClient.shot.setImage({
          id: shot?.id,
          storyId: storyId ?? "",
          imageId: image?.id,
        });
        if (result.status === 201) {
          setMainImage(image.url);
          const newScenes = scenes.map((sc) => {
            return {
              ...sc,
              shots: sc.shots.map((sh) => {
                if (sh?.id === shot?.id) {
                  return { ...sh, selectedImage: image.url };
                }
                return sh;
              }),
            };
          });
          setScenes(newScenes);
        } else {
          toast.error("Something went wrong with updating the prompt");
        }
      } catch (error) {
        console.log({ error });
        toast.error("Something went wrong");
      } finally {
        setLoadingImageUrl(null);
      }
    };
    return ReactDOM.createPortal(
      <div className="fixed inset-0 flex items-center justify-center z-30">
        <div className="modal-backdrop bg-black bg-opacity-50 absolute inset-0" onClick={closeModal}></div>
        <div className="modal-container mx-4 max-h-[90vh] max-w-[85vw] md:max-w-[90vw] lg:max-w-[1140px] h-auto lg:h-[650px] rounded-xl bg-neutral-100 bg-opacity-80 backdrop-blur-lg p-4 flex flex-col overflow-auto">
          {/* Title Section */}
          <div className="flex justify-center items-center pb-6 pt-6">
            <h2 className="text-xl font-semibold text-neutral-800">
              Previously generated Images
            </h2>
          </div>
          {/* Help Button */}
          <button
            className="absolute top-0 right-8 mt-3.5 mr-4 text-md font-semibold text-neutral-500 hover:text-neutral-700"
            aria-label="Help"
          >
            ?
          </button>

          {/* Close Button */}
          <button
            className="absolute top-0 right-0 mt-2 mr-4 text-2xl font-semibold text-neutral-500 hover:text-neutral-700"
            aria-label="Close"
            onClick={closeModal}
          >
            &times;
          </button>

          <div id="image-gallery" className={`grid grid-cols-1 ${validImages?.length === 1 ? "lg:px-28 sm:px-24 px-2" : ""}
                ${validImages?.length >= 2 ? "sm:grid-cols-2" : ""} ${validImages?.length >= 3 && "md:grid-cols-3"} ${validImages?.length >= 4 && "lg:grid-cols-4"}
                gap-2 p-4 items-center overflow-auto w-full`} style={{ maxHeight: "540px" }}
          >
            {validImages.length > 0 ? (
              validImages.map((image: any) => (
                <div key={image?.url} className="relative w-full flex justify-center">
                  {loadingImageUrl === image?.url && (
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur">
                      <CustomLoadingSpinner size={30} />
                    </div>
                  )}
                  <img
                    className="cursor-pointer transition-all h-auto w-full duration-300 ease-in-out transform hover:scale-105"
                    style={{ objectFit: "cover" }}
                    onClick={() => setImageRequest({ image })}
                    src={`${image?.url}`}
                    alt="shot-image"
                  />
                </div>
              ))
            ) : (
              <p className="text-lg text-center py-24 lg:py-0 lg:px-12 lg:mt-48">
                Nothing here yet. Generate some images for this Shot and they will display here.
              </p>
            )}
          </div>
        </div>
      </div>,
      document.getElementById('modal-root')!
    );
  };

  const PreviewCard: React.FC<PreviewCardProps> = ({ shot, index }) => {

    const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
    const closeGalleryModal = () => setIsGalleryModalOpen(false);
    const [tempShotDescription, setTempShotDescription] = useState(shot.shotDescription);

    const [isPreviewCardIsHovered, setIsPreviewCardIsHovered] = useState(false);
    const isBeyondFreeLimit = isShotBeyondFreeLimit(index);

    const saveDescription = async () => {
      if (story.isTemplate) {
        toast.info("Shots in demo projects cannot be changed");
        setTempShotDescription(shot.shotDescription);
        setIsEditMenuOpen(false);
        return;
      }
      try {
        setIsLoading(true);
        const result = await storyBoardsApiClient.shot.update({
          id: shot?.id,
          storyId: storyId ?? "",
          shotDescription: tempShotDescription
        });
        if (result.status === 200) {
          const updatedScenes: any[] = scenes.map(scene => {
            return {
              ...scene,
              shots: scene.shots.map((shotInScene: { id: any; }) => {
                if (shotInScene?.id === shot?.id) {
                  return { ...shotInScene, shotDescription: tempShotDescription };
                }
                return shotInScene;
              })
            };
          });
          setScenes(updatedScenes);
        } else {
          toast.error("Something went wrong with updating the prompt");
        }
      } catch (error) {
        console.log({ error });
        toast.error("Something went wrong");
      }
      setIsEditMenuOpen(false);
      setIsLoading(false);
    };

    const setImageRequest = async (image: ImageWithMetadata) => {
      try {
        setLoadingImageUrl(image.url);
        const result = await storyBoardsApiClient.shot.setImage({
          id: shot?.id,
          storyId: storyId ?? "",
          imageId: image?.id,
        });
        if (result.status === 201) {
          const newScenes = scenes.map((sc) => {
            return {
              ...sc,
              shots: sc.shots.map((sh) => {
                if (sh?.id === shot?.id) {
                  return { ...sh, selectedImage: image.url };
                }
                return sh;
              }),
            };
          });
          setScenes(newScenes);
          closeGalleryModal(); // Close the modal after selection
        } else {
          toast.error("Something went wrong with updating the prompt");
        }
      } catch (error) {
        console.log({ error });
        toast.error("Something went wrong");
      } finally {
        setLoadingImageUrl(null);
      }
    };

    // Get the angle for the selectedImage
    const selectedImageAngle = findSelectedImageAngle(shot);
    const colorClass = getColorForAngle(selectedImageAngle);

    const handleChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTempShotDescription(e.target.value);
    };

    const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
    const openGalleryModal = () => {
      if (!isBeyondFreeLimit) {
        setIsGalleryModalOpen(true);
      }
    };

    const account = useSelector((state: any) => state.app?.account);

    const { openModal } = useBaseModalContext();

    const handleDownloadImageButtonClick = (projectTitle: string, sceneNumber: number, shotNumber: number) => {
      if (story.isTemplate) {
        toast.info("You cannot download images in a template project");
        return;
      }

      if (!isFree || isAdmin) {
        const paidUrl = shot?.images.find((image: any) => image.url === shot.selectedImage)?.paidUrl;
        if (paidUrl !== undefined && paidUrl !== "") {
          sendEventToGoogleTagManager("jpg_download_success", {
            outcome: "success",
          }, userId, availableCredits);
          const fileName = `${projectTitle}_scene_${sceneNumber}_shot_${shotNumber}.jpeg`;
          downloadImage(paidUrl, fileName);
        } else {
          toast.error("Something went wrong while trying to download the image!");
        }
      } else {
        console.log("isFree: ", isFree, "isAdmin: ", isAdmin);
        openModal({ mode: "SUBSCRIBE", data: {}, trigger: "DOWNLOAD_IMAGE_BUTTON" });
      }
    };

    const sceneIndex = scenes.findIndex((scene: any) => scene.shots.some((shotInScene: any) => shotInScene?.id === shot?.id)) + 1;
    const shotIndex = shot?.index + 1;
    const projectTitle = story.label;
    const { setSteps, setIsOpen, currentStep, setCurrentStep } = useTour();
    const { setTourRunning, tourRunning, setTourStartClicked, tourStartClicked } = useTourContext();

    useEffect(() => {
      if (
        setSteps &&
        localStorage.getItem("preview-intro") !== "viewed"
      ) {
        const timer = setTimeout(() => {
          setSteps([
            {
              selector: '#preview-screen',
              content: (
                <div>
                  <p>In preview mode, you can see an overview over your Story</p>
                </div>
              ),
              position: 'center',
              stepInteraction: false,
              styles: {
                close: (base) => ({
                  ...base,
                  display: 'none',
                }),
              },
            },
            {
              selector: '#export-button',
              content: (
                <div>
                  <p>Click export to download your storyboard as a PDF</p>
                </div>
              ),
              position: 'left',
              stepInteraction: false,
              styles: {
                close: (base) => ({
                  ...base,
                  display: 'none',
                }),
              },
            },
            {
              selector: '#switch-mode-toggle',
              content: (
                <div>
                  <p>Go back to the <strong>BUILDER</strong> by clicking on this toggle</p>
                </div>
              ),
              position: 'left',
              stepInteraction: true,
              styles: {
                close: (base) => ({
                  ...base,
                  display: 'none',
                }),
              },
            },
          ]);
          setCurrentStep(0);
          setTourRunning(true);
          setIsOpen(true);
          localStorage.setItem("preview-intro", "viewed");
        }, 4000); // 4-second delay

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
      }
    }, []);

    const isEligibleForTrial = useTrialEligibility(account);

    const handleCardClick = () => {
      if (isBeyondFreeLimit) {
        if (isEligibleForTrial) {
          toast.info("Start your free trial for stories with more than 6 images.");
        } else {
          toast.info("Subscribe to create stories with more than 6 images.");
        }
      }
    };

    return (
      <div className="relative">
        <div
          className={`flex flex-col min-h-full shadow-lg max-w-sm mx-auto bg-neutral-800 p-6 rounded-lg hover:shadow-xl transition-shadow duration-300 ease-in-out ${isBeyondFreeLimit ? 'opacity-50' : ''}`}
          onClick={isBeyondFreeLimit ? handleCardClick : undefined}
        >
          <div className="absolute -top-3 -left-3 bg-neutral-300 rounded-full w-8 h-8 flex items-center justify-center">
            <p className="text-black text-sm">{index + 1}</p>
          </div>
          <div className="min-h-[200px] min-w-[150px]">
            <img
              className="object-cover w-full rounded-sm cursor-pointer"
              src={shot?.selectedImage ? `${shot?.selectedImage}` : require("../../../assets/logos/placeholder_result.webp")}
              alt="style"
              onClick={openGalleryModal}
            />
          </div>
          {/* New Scene/Shot Index and Camera Angle */}
          <div className="mt-4 flex flex-row justify-between items-center">
            <p className="text-neutral-200 text-xs">Scene {sceneIndex}, Shot {shotIndex}</p>
            <div className="flex items-center">
              <div className={`${colorClass} rounded-full px-3 py-1`}>
                <p className="text-neutral-100 text-xs">{selectedImageAngle}</p>
              </div>
              {/* Three Dot Menu for Download */}
              <Menu as="div" className={`ml-3 relative ${isBeyondFreeLimit ? 'pointer-events-none' : ''}`}>
                <Menu.Button className="flex items-center text-gray-400 hover:text-gray-50 focus:outline-none">
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-neutral-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active ? 'bg-neutral-100 text-neutral-800' : 'text-neutral-700-400'
                              } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                            onClick={() => handleDownloadImageButtonClick(projectTitle, sceneIndex, shotIndex)}
                          >
                            Download
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <hr className="my-2 border-neutral-700" />
          {!isBeyondFreeLimit && !isEditMenuOpen ? (
            <div className="mt-4 flex-1">
              <p
                className="text-neutral-200 text-sm leading-relaxed cursor-text"
                onClick={() => setIsEditMenuOpen(true)}>
                {shot?.shotDescription}
              </p>
            </div>
          ) : (
            <div className="mt-4 flex-1">
              <p className="text-neutral-200 text-sm leading-relaxed">
                {shot?.shotDescription}
              </p>
            </div>
          )}
          {isEditMenuOpen && (
            <>
              <div className="flex-1">
                <textarea
                  value={tempShotDescription}
                  onChange={handleChange}
                  rows={3}
                  className="p-2 w-full bg-neutral-900 text-neutral-200 border-2 border-neutral-700 rounded-md focus:outline-none focus:border-sky-500 transition duration-300 ease-in-out"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              {/* Action Buttons */}
              <div className="flex justify-end items-center mt-4 space-x-2">
                {/* Cancel (X) Button */}
                <button
                  className="w-[24px] h-[24px] flex text-white items-center justify-center rounded bg-neutral-800 hover:bg-neutral-700"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTempShotDescription(shot.shotDescription); // Reset description
                    setIsEditMenuOpen(false);
                  }}
                >
                  <XMarkIcon className="w-[16px] h-[16px]" />
                </button>
                {/* Save (Check) Button */}
                <button
                  className="w-[24px] h-[24px] flex text-white items-center justify-center rounded bg-neutral-800 hover:bg-neutral-700"
                  onClick={(e) => {
                    e.stopPropagation();
                    saveDescription();
                  }}
                >
                  <SaveIcon className="w-[16px] h-[16px]" />
                </button>
              </div>
            </>
          )}
          {isGalleryModalOpen && (
            <GalleryModal
              closeModal={closeGalleryModal}
              images={[]}
              setImage={setImageRequest}
              shot={shot}
            />
          )}

        </div>
        {isBeyondFreeLimit && (
          <div className="absolute top-1 right-2 z-20">
            <StarIcon className="h-7 w-7 text-yellow-500" />
          </div>
        )}
      </div>
    );
  }

  const allShots = scenes?.map(scene => scene.shots).flat();

  return (
    <div id="preview-screen" className="h-screen flex flex-col items-center">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-10">
          {/* Overlay */}
          <div className="w-full h-full absolute top-0 left-0 bg-neutral-800 opacity-80 backdrop-blur"></div>

          {/* Loading message and spinner container */}
          <div className="text-center z-20">
            <CustomLoadingSpinner size={80} />

            {/* Ensure the text is visible on all backgrounds */}
            <p className="text-neutral-100 mt-4 text-lg">Getting your storyboard ready...</p>
          </div>
        </div>
      )}
      <div className="h-[90vh] w-full overflow-auto pt-20">
        <div className="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 xl:max-w-[1500px] md:max-w-[1000px] sm:max-w-[600px] mx-4 pb-32 pt-6">
          {
            allShots?.map((shot, index) => (
              <PreviewCard key={shot?.id} shot={shot} index={index} />
            ))
          }
        </div>
      </div>
    </div>
  );
}