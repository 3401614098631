import React, { Fragment, useContext, useMemo, useState } from "react";
import {
  AcademicCapIcon,
  ChevronDoubleLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon,
  FilmIcon,
  HomeIcon,
  LightBulbIcon,
  StarIcon
} from "@heroicons/react/24/solid";
import { Menu, Popover, Transition } from "@headlessui/react";
import Notifications from "../notifications/Notifications";
import logo from "../../../assets/logos/logo-story-boards-ai-text.svg";
import { useTourContext } from "../../../core/utils/TourContext";
import { toast } from "react-toastify";
import { classNames } from "../../../core/utils/classnames";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import packageJson from "../../../../package.json";
import { useLocation, useNavigate } from "react-router";
import DiscordIcon from "../../../assets/logos/discord-white-icon.webp";
import BaseModal, { TModalMode } from "../modals/base-modal/BaseModal";
import { useTour } from "@reactour/tour";
import { appSteps } from "../tour/TourSteps";
import { useAppContext } from "../../../core/utils/AppContext";
import { useBaseModalContext } from "../modals/base-modal/BaseModalContext";
import UniversalTippy from "../universal-tippy/UniversalTippy";
import useUserPlan from "../../../core/utils/creditCost";
import { useTrialEligibility } from "../../../core/utils/useTrialEligibility";

export default function Navbar() {


  const { user } = useAppContext(); // Get user data from context

  // Memoize the required user data
  const { userId, availableCredits, account } = useMemo(() => ({
    userId: user?.id,
    availableCredits: user?.stats?.availableCredits,
    account: user
  }), [user]);

  const navigate = useNavigate();

  const { printRef, setTourStartClicked } = useTourContext();
  const [showPopover, setShowPopover] = useState(true);
  const [modalMode, setModalMode] = useState<TModalMode>(undefined);
  const isModalOpen = modalMode !== undefined;
  const { setSteps, setIsOpen, currentStep, setCurrentStep } = useTour();
  const { setMode, setIsOpen: seIsModalOpen, setYoutubeId } = useBaseModalContext();

  interface CreditInfoTooltipContentProps {
    availableCredits: number | string;
    availableInpaintCredits: number | string;
    availableImg2ImgCredits: number | string;
    availableWizardCredits: number | string;
  }

  const { isFree, isPlan, costs } = useUserPlan();
  const isEligibleForTrial = useTrialEligibility(account);

  const CreditInfoTooltipContent: React.FC<CreditInfoTooltipContentProps> = ({
    availableCredits,
    availableInpaintCredits,
    availableImg2ImgCredits,
    availableWizardCredits
  }) => (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">Your Credits:</h3>
      <div className="flex">
        <div className="flex-1">
          <h4 className="text-sm font-medium text-gray-600 mb-2">Costs</h4>
          <ul className="space-y-1 text-sm">
            <li className="flex justify-between">
              <span className="text-gray-600">Image: </span>
              <span className="font-semibold text-gray-800">{costs.image}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-600">InPaint:  </span>
              <span className="font-semibold text-gray-800">&nbsp;{costs.inpaint}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-600">Img2Img:  </span>
              <span className="font-semibold text-gray-800">&nbsp;{costs.img2Img}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-600">Wizard:  &nbsp;</span>
              <span className="font-semibold text-gray-800">&nbsp;{costs.wizard}</span>
            </li>
          </ul>
        </div>
        <div className="w-px bg-gray-300 mx-4"></div>
        <div className="flex-1">
          <h4 className="text-sm font-medium text-gray-600 mb-2">Available</h4>
          <ul className="space-y-1 text-sm">
            <li className="flex justify-between">
              <span className="text-gray-600">Image:  </span>
              <span className="font-semibold text-gray-800">{availableCredits}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-600">InPaint:  </span>
              <span className="font-semibold text-gray-800">{availableInpaintCredits}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-600">Img2Img:  </span>
              <span className="font-semibold text-gray-800">{availableImg2ImgCredits}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-600">Wizard:  </span>
              <span className="font-semibold text-gray-800">{availableWizardCredits}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const closeModal = () => {
    setModalMode(undefined);
  };

  const handleStartTour = () => {
    console.log("starting tour manually");
    if (setSteps) {
      setSteps(appSteps);
    }
    setCurrentStep(0);
    setShowPopover(false);
    setTourStartClicked(true);
  };

  const signOut = async () => {
    try {
      window.open(`${process.env.REACT_APP_API}/core/auth/logout`, "_self");
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const callsToAction = [
    { name: "Watch demo", href: "#", icon: ChevronDownIcon },
    { name: "Contact sales", href: "#", icon: ChevronDoubleLeftIcon }
  ];

  const location = useLocation();

  const isRootUrl = () => {
    return location.pathname === "/";
  };

  const isBoardScreenUrl = () => {
    return window.location.pathname.includes("board");
  };

  const userNavigation = [
    //{ name: "Theme", href: "#" },
    { name: "Account", action: () => navigate("/account") },
    { name: "Sign out", action: () => signOut() }
  ];

  const appContext = useAppContext();

  interface Breadcrumb {
    name: string;
    path: string;
    current: boolean;
  }

  const Breadcrumbs: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const story = appContext.currentStory

    // Extract the segments of the URL path
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const view = pathSegments[0]; // The first segment determines the view
    const projectId = pathSegments[1];

    let breadcrumbs: Breadcrumb[] = [
      { name: "Home", path: "/", current: view === "" || view === undefined },
    ];

    switch (view) {
      case "account":
        breadcrumbs.push({ name: "Account", path: "/account", current: true });
        break;
      case "stories":
        if (story) {
          breadcrumbs.push({ name: story.label, path: `/stories/${projectId}/board`, current: pathSegments[2] === "board" });
          if (pathSegments[2] === "export") {
            breadcrumbs.push({ name: "Export", path: `/stories/${projectId}/export`, current: true });
          }
        }
        break;
      default:
        break;
    }

    return (
      <div className="flex items-center space-x-2 sm:text-md">
        {breadcrumbs.map((crumb, index) => (
          <React.Fragment key={index}>
            {index > 0 && <ChevronRightIcon className="w-5 h-5 text-neutral-200" />}
            <button
              onClick={() => navigate(crumb.path)}
              className={`inline-flex items-center text-neutral-200 ${!crumb.current ? "hover:text-neutral-400" : ""}`}
              disabled={crumb.current}
            >
              {index === 0 && <HomeIcon className="w-5 h-5 mr-2" />}
              {crumb.name}
            </button>
          </React.Fragment>
        ))}
      </div>
    );
  };

  function menuItems() {
    return <div className="lg:flex lg:flex-1 items-center justify-end">
      <div className="flex justify-end mr-4 gap-x-2">
        {isBoardScreenUrl() && (
          <>
            <BaseModal
              isOpen={isModalOpen}
              onClose={closeModal}
              mode={modalMode as TModalMode} />
          </>
        )}
        <Notifications />
        <Popover className="relative flex justify-center lg:justify-start items-center">
          <Popover.Button id="help-button"
            className="hidden sm:block flex items-center hover:text-gray-300 gap-x-2 lg:gap-x-8 text-sm font-semibold leading-6 text-gray-200 focus:outline-none "
            onClick={() => setShowPopover(true)}>
            Help
          </Popover.Button>
          {showPopover && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >

              <Popover.Panel
                className="absolute lg:right-8 top-full mt-3 max-w-md w-screen overflow-hidden rounded-xl bg-neutral-100 bg-opacity-80 backdrop-blur shadow-lg ring-1 ring-gray-900/5 z-70">
                <div className="p-4">
                  {(isBoardScreenUrl()) && (
                    <div
                      className="hidden group relative lg:flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-neutral-200">
                      <div
                        className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <LightBulbIcon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href="#" onClick={(e) => {
                          e.preventDefault();
                          sendEventToGoogleTagManager("tour_manual_start", {
                            event_category: "App Tour",
                            event_action: "click",
                            event_label: "Start Tour Button"
                          }, userId, availableCredits);


                          handleStartTour();
                        }} className="block font-semibold text-gray-900">
                          Tour
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">Let Us show You around</p>
                      </div>
                    </div>
                  )}
                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-neutral-200"
                    onClick={() => {
                      window.open("https://storyboardsai.notion.site/Welcome-to-the-Story-Boards-ai-Wiki-8da4e570fddb41df829af2faf155980d?pvs=4", "_blank");
                      sendEventToGoogleTagManager('wiki_button_clicked', {
                        event_category: 'External Links',
                        event_action: 'click',
                        event_label: 'Wiki Button'
                      }, userId, availableCredits);
                    }}>
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <AcademicCapIcon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="flex-auto">
                      <a href="#" className="block font-semibold text-gray-900">
                        Wiki
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600">The Quick and Dirty on how to use our App</p>
                    </div>
                  </div>

                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-neutral-200"
                    onClick={() => {
                      window.open("https://discord.gg/KbUPUjjXn4", "_blank");
                      sendEventToGoogleTagManager('discord_button_clicked', {
                        event_category: 'External Links',
                        event_action: 'click',
                        event_label: 'Discord Button'
                      }, userId, availableCredits);

                    }}>
                    <div
                      className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <img src={DiscordIcon} alt="Discord"
                        className="h-6 w-6 filter invert group-hover:text-indigo-600" />
                    </div>
                    <div className="flex-auto">
                      <a href="#" className="block font-semibold text-gray-900">
                        Discord
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600">The best and easiest way to stay informed and get help.</p>
                    </div>
                  </div>
                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-neutral-200"
                    onClick={() => {
                      setYoutubeId("ek9_XUhe7DE")
                      setMode("VIDEO");
                      seIsModalOpen(true);
                      sendEventToGoogleTagManager('intro_video_clicked', {
                        event_category: 'External Links',
                        event_action: 'click',
                        event_label: 'Discord Button'
                      }, userId, availableCredits);

                    }}>
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <FilmIcon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="flex-auto">
                      <a href="#" className="block font-semibold text-gray-900">
                        Intro Video
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600">A quick introduction on how to use our app.</p>
                    </div>
                  </div>
                </div>
                {/*<div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-neutral-100">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-neutral-200"
                  >
                    <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    {item.name}
                  </a>
                ))}
                </div>*/}
              </Popover.Panel>
            </Transition>
          )}
        </Popover>
      </div>
      <div className="flex">
        <NavbarCreditsDisplay />
        {account?.subscription?.id !== "tier-indie" && (
          <button
            id="upgrade-button"
            type="button"
            className="flex items-center justify-center text-sm font-semibold text-white hover:bg-neutral-800 shadow-sm px-4 py-2 rounded-md"
            onClick={() => {
              sendEventToGoogleTagManager("upgrade_button_clicked", {
                action: isEligibleForTrial ? "free_trial" : "subscribe"
              }, userId, availableCredits);
              navigate(`/account`);
            }}
          >
            {isEligibleForTrial ? "Free Trial" : "Subscribe"}
            <StarIcon className="w-5 h-5 ml-2 text-yellow-400" />
          </button>
        )}
      </div>
      <div className="hidden md:flex ">
        <Menu as="div" className=" ">
          <Menu.Button className="px-4 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            {account?.profileUrl ? <img
              className="h-[24px] w-[24px] rounded-full bg-gray-50"
              src={account?.profileUrl}
              alt="" /> : <div
                className={"h-[24px] w-[24px] rounded-full bg-sky-500 text-sm text-neutral-100 font-semibold flex items-center justify-center"}>{account?.firstname?.substring(0, 1)}</div>}
            <span className="hidden lg:flex lg:items-center">
              <span
                className="ml-2 text-sm font-semibold leading-6 text-neutral-100"
                aria-hidden="true"
              >
                {account?.firstname} {account?.lastname}
              </span>
            </span>

          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className="absolute right-20 z-40 mt-2.5 w-[275px] rounded-md bg-neutral-100 bg-opacity-80 backdrop-blur py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">

              <div
                className={"w-full flex flex-col gap-y-2 py-4 justify-center items-center border-b border-b-neutral-300 "}>

                {account?.profileUrl ? <img
                  className="h-[32px] w-[32px] rounded-full bg-gray-50"
                  src={account?.profileUrl}
                  alt="" /> : <div
                    className={"h-[32px] w-[32px] rounded-full bg-sky-500 text-sm text-gray-600 font-semibold flex items-center justify-center"}>{account?.firstname?.substring(0, 1)}</div>}
                <div className={"flex flex-col"}>
                  <span
                    className="text-sm text-gray-600 text-center"
                    aria-hidden="true"
                  >
                    {account?.firstname} {account?.lastname}
                  </span>
                  <span
                    className="text-xs text-gray-600 text-center"
                    aria-hidden="true"
                  >
                    {account?.email}
                  </span>
                </div>
              </div>
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-neutral-200 text-gray-600" : "",
                        "w-full flex justify-between items-center block px-3 py-2 text-sm leading-6 text-neutral-600"
                      )}
                      onClick={item.action}
                    >
                      <p>{item.name}</p>
                      <ChevronRightIcon className={"w-[12px] h-[12px] text-neutral-600"} />
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
        <Notifications />
      </div>
    </div>;
  }

  const NavbarCreditsDisplay = () => {
    const { availableCredits, availableInpaintCredits, availableImg2ImgCredits, availableWizardCredits } = useMemo(() => ({
      availableCredits: user?.stats?.availableCredits,
      availableInpaintCredits: user?.stats?.availableInpaintCredits,
      availableImg2ImgCredits: user?.stats?.availableImg2ImgCredits,
      availableWizardCredits: user?.stats?.availableWizardCredits,
    }), [user]);

    const { aiCreditsSpentSinceReload, inpaintCreditsSpentSinceReload, img2ImgCreditsSpentSinceReload } = useAppContext();

    const adjustedCredits = availableCredits !== undefined
      ? availableCredits === -1 ? "∞" : availableCredits - aiCreditsSpentSinceReload
      : "0";

    const adjustedInpaintCredits = availableInpaintCredits !== undefined
      ? availableCredits === -1 ? "∞" : availableInpaintCredits - inpaintCreditsSpentSinceReload
      : "0";

    const adjustedImg2ImgCredits = availableImg2ImgCredits !== undefined
      ? availableCredits === -1 ? "∞" : availableImg2ImgCredits - img2ImgCreditsSpentSinceReload
      : "0";

    const adjustedWizardCredits = availableWizardCredits !== undefined
      ? availableCredits === -1 ? "∞" : availableWizardCredits
      : "0";

    return (
      <UniversalTippy content={
        <CreditInfoTooltipContent
          availableCredits={adjustedCredits}
          availableInpaintCredits={adjustedInpaintCredits}
          availableImg2ImgCredits={adjustedImg2ImgCredits}
          availableWizardCredits={adjustedWizardCredits}
        />
      }>
        <span className="flex items-center justify-center px-2 py-2 text-xs font-semibold leading-none text-white bg-neutral-700/60 border border-neutral-500 rounded-md">
          Credits: {adjustedCredits}
        </span>
      </UniversalTippy>
    );
  };

  return (
    <header className="bg-neutral-900 sticky top-0 z-50">
      <nav className="flex h-20 items-center p-4 lg:p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1 items-center justify-start">
          <div className="flex items-center gap-7 w-full">
            <a href="/" className="-m-1.5 p-1.5">
              <img src={logo} alt="story-boards.ai logo" className="w-[95px] h-[95px] ml-3" />
            </a>
            {/* This div remains unchanged in large view, but is responsive for mobile */}
            <div
              className="hidden lg:flex lg:items-center lg:space-x-4 lg:justify-center rounded-md h-9 py-2 px-3.5 text-md font-semibold text-gray-300 shadow-sm ring-1 ring-inset ring-gray-500 cursor-default">
              Open Beta
            </div>
            {/* Version indicator hidden on mobile, visible on web */}
            <span className="hidden lg:flex text-sm text-gray-400 lg:space-x-4">
              v{packageJson?.version ?? ""}
            </span>
            {/* Mobile view adjustments: Show Open Beta and version in a more compact form */}
            <div className="flex lg:hidden items-center space-x-2">
              <div
                className="text-xs font-semibold text-gray-300 py-1 px-2 rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                Open Beta
              </div>
              <span className="text-xs text-gray-400">
                v{packageJson?.version ?? ""}
              </span>
            </div>
          </div>
        </div>
        {/* Breadcrumbs for medium and larger screens */}
        <div className="hidden md:flex flex-1 pl-10 items-center md:justify-center">
          <Breadcrumbs />
        </div>
        {/* Menu items for larger screens */}
        <div className="hidden lg:flex lg:flex-1 items-center justify-end">
          <div className="flex justify-end mr-4 gap-x-2">
            {menuItems()}
          </div>
        </div>
      </nav>
      {/* Breadcrumbs for small screens */}
      <div className="md:hidden flex flex-1 mt-4 pl-3 sm:pl-10 items-center md:justify-center">
        <Breadcrumbs />
      </div>

      <div id="mobile-warning"
        className="block sm:hidden max-w-[400px] rounded-md bg-yellow-50 p-4 mt-4 w-[80%] mx-auto">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm sm:text-md font-medium text-yellow-800">Mobile view is experimental.</h3>
          </div>
        </div>
      </div>

      <div id="tablet-warning"
        className="hidden sm:block lg:hidden max-w-[400px] rounded-md bg-yellow-50 p-4 mt-4 w-[80%] mx-auto">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-xs sm:text-md font-small text-yellow-800">Tablet view is experimental.</h3>
          </div>
        </div>
      </div>

      {/* Menu items for extra small screens */}
      <div className="lg:hidden w-full bg-neutral-900 py-2 mt-2 flex justify-center items-center">
        {menuItems()}
      </div>

    </header>
  );


}
