import React from 'react';
import Character from '../../../character/Character';
import { TCharacter } from '../../../character/Character';
import { useAppContext } from '../../../../../core/utils/AppContext';

interface CastUIProps {
  removeCharacterFromState: (params: { id: string }) => void;
  handleCreateCharacterClick: () => void;
  switchMode: (newMode: string, params: { character: TCharacter }) => void;
  updateCharacterInState?: (character: TCharacter) => void;
  UImode: 'light' | 'dark';
  CastUiMode: 'UPDATE' | 'AI_FOUND';
}

const CastUI: React.FC<CastUIProps> = ({
  removeCharacterFromState,
  handleCreateCharacterClick,
  switchMode,
  updateCharacterInState,
  UImode,
  CastUiMode
}) => {
  const bgColor = UImode === 'light' ? 'bg-transparent' : 'bg-neutral-900';
  const textColor = UImode === 'light' ? 'text-neutral-800' : 'text-neutral-200';
  const borderColor = UImode === 'light' ? 'border-neutral-500' : 'border-neutral-400';
  const hoverBgColor = UImode === 'light' ? 'hover:bg-neutral-400' : 'hover:bg-neutral-600';
  const { charactersList } = useAppContext();

  const handleClick = (character: TCharacter) => {
    if (CastUiMode === "UPDATE") {
      switchMode("UPDATE", { character: { ...character } });
    } else {
      switchMode("AI_FOUND", { character: { ...character } });
    }
  };

  return (
    <div id="CastUI" className={`w-full ${bgColor} ${textColor}`}>
      {UImode === "light" ? (
        <div id="light_mode" className="w-full h-full mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4 h-full overflow-auto">
            {charactersList?.map((character: TCharacter) => (
              <div
                key={character?.id}
                onClick={() => handleClick(character)}
                className="cursor-pointer w-full aspect-square"
              >
                <Character
                  key={`${character?.id}-${character?.image}`}
                  character={character}
                  removeCharacterFromState={removeCharacterFromState}
                  mode={UImode}
                  updateCharacterInState={updateCharacterInState}
                />
              </div>
            ))}
            <div
              className="cursor-pointer min-w-24 aspect-square"
              onClick={handleCreateCharacterClick}
            >
              <div className={`bg-transparent rounded-lg border-2 border-dashed ${borderColor} ${hoverBgColor} flex justify-center items-center w-full h-full`}>
                <span className={`text-4xl ${textColor}`}>+</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="dark_mode" className="w-full flex flex-wrap justify-center gap-4 p-4 sm:p-8 overflow-auto z-auto">
          {charactersList?.map((character: TCharacter) => (
            <div
              key={character?.id}
              onClick={() => handleClick(character)}
              className="cursor-pointer w-full sm:w-1/2 md:w-1/4 lg:w-1/6 flex justify-center"
            >
              <Character
                character={character}
                removeCharacterFromState={removeCharacterFromState}
                mode={UImode}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CastUI;